import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Breadcrumb, FactionPagePrivate, FactionPagePublic, Footer, Header, ServerData } from '../../components';
import { useGetUser } from '../../store/hooks/UserHook';

const FactionProfilePage: React.FC = () => {
  const user = useGetUser();
  const { id } = useParams();
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  const breadCrumb = [
    { name: "Facção", url: "/faction" },
    { name: "Perfil", url: `/faction/profile/${id}`},
  ]

  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''}/>
        <div className='faction-page'>
          <Breadcrumb items={breadCrumb} />
          {user.appKey ?
            <FactionPagePrivate id={id} />
            :
            <FactionPagePublic id={id} />
          }
        </div>  
      <Footer />
    </>
  )
}

export default FactionProfilePage