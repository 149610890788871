import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Breadcrumb, Footer, Header, ServerData, SurvivorPage as SurvivorPageComponente } from '../../components';

const SurvivorProfilePage: React.FC = () => {
  const { xuid } = useParams();
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  const breadCrumb = [
    { name: "Sobrevivente", url: "/survivor" },
    { name: "Perfil", url: `/survivor/profile/${xuid}` },
  ]

  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''}/>
        <div className='survivor-page'>
          <Breadcrumb items={breadCrumb} />
          <SurvivorPageComponente xuid={xuid} />
        </div>
      <Footer />
    </>
  )
}

export default SurvivorProfilePage