import React, { useEffect, useState } from "react";
import api from '../../services/Api';

export const Forum: React.FC = () => {
  const [posts, setPosts] = useState([])

  useEffect(() => {    
    const getData = async () => {
      try {
        const response = await api.get('posts')
        setPosts(response.data)
      }
      catch (err) {
        console.error(err)
      }
    }
    getData()
  }, [])

  const listItems = posts.map((data: any, i: any) =>
      <div className="col-lg-3 col-md-6 col-12" key={`${i}`}>
        <div className="single-table wow fadeInUp" data-wow-delay=".2s">
          <div className="table-head">
            <h4 className="title">Post</h4>
            <span>Criado em {data.created} - Votos: {data.votes}</span>
            <h5>{data.title}</h5>
            <div className="button">
              <a href={`${data.link}`} className="btn">
                Leia mais
              </a>
            </div>
          </div>
        </div>
    </div>
  )

  return (
    <section id="comunidade" className="pricing-table section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h3 className="wow zoomIn" data-wow-delay=".2s">
                Comunidade
              </h3>
              <h2 className="wow fadeInUp" data-wow-delay=".4s">
                Dead Rising Brasil
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Fique por dentro de tudo o que esta rolando em nossa <strong><a href="http://forum.deadrisingbrasil.com/">comunidade</a></strong>.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {listItems}
        </div>
        <div className="forum-footer">
          <div className="button wow fadeInUp" data-wow-delay=".6s">
            <a 
              href="https://forum.deadrisingbrasil.com" 
              className="btn">
              Ir para o Forum
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
