import React, { useEffect } from "react"
import { isMobile } from 'react-device-detect'

export const RainEffect: React.FC = () => {

  useEffect(() => {
    const droplets: number = isMobile ? 30 : 80;
    const container: HTMLElement | null = document.getElementById('rain');

    if (container) {
      for (let r = 0; r < droplets; r++) {
        const svg: SVGSVGElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('class', 'rain__drop');
        svg.setAttribute('key', `rain__drop_${r}`);
        svg.setAttribute('preserveAspectRatio', 'xMinYMin');
        svg.setAttribute('viewBox', '0 0 5 50');
        svg.setAttribute('style', `--x: ${Math.floor(Math.random() * 100)}; --y: ${Math.floor(Math.random() * 100)}; --o: ${Math.random() * 0.2}; --a: ${Math.random() + 0.5}; --d: ${Math.random() * 2 - 1}; --s: ${Math.random()}`);

        const path: SVGPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute('stroke', 'none');
        path.setAttribute('d', 'M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z');

        svg.appendChild(path);
        container.appendChild(svg);
      }
    }
  }, [])

  return (
    <div id="rain"></div>
  )
}