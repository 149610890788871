import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import api from "../../services/Api";
import {
  setModerModalIsOpen,
  setWhitelistModalIsOpen,
} from "../../store/ducks/AppDuck";
import { useGetApp } from '../../store/hooks/AppHook';
import { useGetServer } from '../../store/hooks/ServerHook';
import { useGetStatus } from '../../store/hooks/StatusHook';
import { useGetUser } from '../../store/hooks/UserHook';
import { Coin } from "../coins/coin";
import { CoinBuy } from "../coins/coinBuy";
import Moder from "../features/moder";
import { PlayerStatus } from "../features/playerStatus";
import { QueueJumpBuy } from "../features/queueJumpBuy";
import SyncData from "../features/syncData";
import Whitelist from "../features/whitelist";

export const Dashboard: React.FC = () => {
  const user = useGetUser()
  const status = useGetStatus()
  const app = useGetApp()
  const server = useGetServer()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  //Whitelist State
  const [whiteListStatus, setWhiteListStatus] = useState(<></>)
  const [whiteListModal, setWhiteListModal] = useState(<></>)
  //Moders State
  const [moderStatus, setModerStatus] = useState(false)
  const [moderModal, setModerModal] = useState(<></>)
  const [reloadCoins, setReloadCoins] = useState(false)

  const goToPage = (url: string) => {
    navigate(url)
  }

  const setOpenModerModal = () => {
    dispatch(setModerModalIsOpen(true))
  }

  useEffect(() => {
    const config = {
      headers: {
        gamertag: `${user.gamertag}`
      }
    }

    const getModerStatus = async () => {
      try{
        const responseModer = await api.get('moders/list', config)
        if (Object.keys(responseModer.data).length > 0) {
          if (responseModer.data[0].gamertag === user.gamertag) {
            setModerModal(<Moder />)
            setModerStatus(true)
          }
        } 
      } catch (err) {
        toast.error('Ops! Algo deu errado, tente novamente.', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    }

    const showReasonBan = (reason: string) => {
      toast.info(<><strong>Motivo:</strong> <br /> {reason}</>, {
        position: "top-left",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }

    const getWhiteListStatus = async () => {
      const setOpenWhitelistModal = () => {
        dispatch(setWhitelistModalIsOpen(true))
      }

      try {
        const responseWhitelist = await api.get('whitelist/list', config)
        const responseBanlist = await api.get('banlist/list', config)
  
        if (Object.keys(responseWhitelist.data).length > 0) {
          if (responseWhitelist.data[0].gamertag === user.gamertag) {
            setWhiteListStatus(
              <div className={`block whitelist registered col-lg-2 col-md-4 col-12 wow fadeInUp`} data-wow-delay=".6s">
                <i className="lni lni-checkmark-circle"></i>
                <strong>WHITELIST</strong>
                <div className="status">
                  Você já está na whitelist da temporada {status.config.seasonNumber}.
                </div>
              </div>
            )
          }
        } else if (Object.keys(responseBanlist.data).length > 0) {
          if (responseBanlist.data[0].gamertag === user.gamertag) {
            const banDurationToTime: Record<string, string> = {
              1800 : '30 minutos',
              3600 : '1 hora',
              10800 : '3 horas',
              18000 : '5 horas',
              86400 : '1 dia',
              15774912 : 'definitivo (até o fim da temporada)',
            };

            if (responseBanlist.data[0].type === 'ban') {
              setWhiteListStatus(
                <div className={`block whitelist ban col-lg-3 col-md-5 col-12 wow fadeInUp`} data-wow-delay=".6s" onClick={() => showReasonBan(responseBanlist.data[0].reason)}>
                  <i className="lni lni-ban"></i>
                  <strong>WHITELIST</strong>
                  <div className="status">
                    Você está banido por <strong>{banDurationToTime[responseBanlist.data[0].ban_duration]}</strong>. <br /> Clique para visualizar o motivo.
                  </div>
                </div>
              )
            }

            if (responseBanlist.data[0].type === 'sus') {
              setWhiteListStatus(
                <div className={`block whitelist sus col-lg-3 col-md-5 col-12 wow fadeInUp`} data-wow-delay=".6s" onClick={() => showReasonBan(responseBanlist.data[0].reason)}>
                  <i className="lni lni-ban"></i>
                  <strong>WHITELIST</strong>
                  <div className="status">
                    Você está suspenso por <strong>{banDurationToTime[responseBanlist.data[0].ban_duration]}</strong>. <br /> Clique para visualizar o motivo.
                  </div>
                </div>
              )
            }
          }
        } else if (
          Object.keys(responseWhitelist.data).length === 0 &&
          Object.keys(responseBanlist.data).length === 0
        ) {
          setWhiteListModal(<Whitelist />)
          setWhiteListStatus(
            <div className={`block whitelist none col-lg-2 col-md-4 col-12 wow fadeInUp`} data-wow-delay=".6s" onClick={() => setOpenWhitelistModal()}>
              <i className="lni lni-add-files"></i>
              <strong>WHITELIST</strong>
              <div className="status">
                Cadastre-se na whitelist da temporada {status.config.seasonNumber}.
              </div>
            </div>
          )
        }
      } catch (err) {
        toast.error('Ops! Algo deu errado, tente novamente.', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    }

    getModerStatus().then(() => {
      getWhiteListStatus().then(() => {
        setIsLoading(false)
      })
    })
  }, [app.whitelist.modalIsOpen])

  return (
    <>
      {moderModal}
      {whiteListModal}

      {
        isMobile ?
          <div className="cover-avatar" style={{backgroundImage: `url("${user.avatar}")`}}>
            <div className="gradient"></div>
          </div>
        :
          <></>
      }

      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-6 col-sm-12">
            <div className="section-title left">
              <h3 className="wow fadeInUp" data-wow-delay=".6s">Olá, {user.gamertag}!</h3>
              <h2 className="wow fadeInUp" data-wow-delay=".9s">Dashboard</h2>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12" style={{marginBottom: '20px'}}>
            <Coin reload={reloadCoins}/>
          </div>
        </div>

        <PlayerStatus />

        <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={isMobile ? ['servidor'] : ['servidor', 'shop', 'features', 'votacoes', 'xbox-live', 'moder']} className="features-expand">
          <AccordionItem uuid="servidor">
            <AccordionItemHeading>
              <AccordionItemButton>
                <h5 className="wow fadeInUp" data-wow-delay=".2s">
                  Servidor
                  <AccordionItemState>
                    {({ expanded }) => (expanded ? <i className="lni lni-minus"></i> : <i className="lni lni-plus"></i>)}
                  </AccordionItemState>
                </h5>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="blocks row">
                <div className="block status col-lg-3 col-md-4 col-12 wow fadeInUp" data-wow-delay=".2s" style={{backgroundColor: server.status.color}}>
                  <i className="lni lni-information"></i>
                  <strong>SERVIDOR</strong>
                  <ul>
                    <li className="wow fadeInUp">{server.status.message}</li>
                    <li className="wow fadeInUp"><strong>Versão</strong>: {server.version}</li>
                    <li className="wow fadeInUp"><strong>Ultima Atualização</strong>: {server.lastUpdate}</li>
                  </ul>
                </div>
                <div className="block invoices col-lg-2 col-md-4 col-12 wow fadeInUp" data-wow-delay=".2s" onClick={() => goToPage('/dashboard/invoices')}>
                  <i className="lni lni-empty-file"></i>
                  <strong>Faturas</strong>
                  <div className="status">
                    Gerencie suas finanças com facilidade.
                  </div>
                </div>
                {isLoading ?
                  <div className="block skeleton col-lg-2 col-md-4 col-12 wow fadeInUp" data-wow-delay=".2s">
                    <div className="line h17 w40 m10"></div>
                    <div className="line"></div>
                    <div className="line h8 w50"></div>
                  </div>
                  :
                  <>
                    {whiteListStatus}
                  </>
                }
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="shop">
            <AccordionItemHeading>
              <AccordionItemButton>
                <h5 className="wow fadeInUp" data-wow-delay=".2s">
                  Shop
                  <AccordionItemState>
                    {({ expanded }) => (expanded ? <i className="lni lni-minus"></i> : <i className="lni lni-plus"></i>)}
                  </AccordionItemState>
                </h5>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="blocks row">
                <CoinBuy reloadCoins={setReloadCoins}/>
                <QueueJumpBuy reloadCoins={setReloadCoins}/>
                <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" onClick={() => goToPage('/dashboard/base-alert')}>
                  <i className="lni lni-control-panel"></i>
                  <strong>Shop</strong>
                  Base Alert
                  <span>Assine o Base Alert + Base Security com DRB$ e receba alertas sobre sua base.</span>
                </div>
                <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" onClick={() => goToPage('/dashboard/airdrop')}>
                  <i className="lni lni-plane"></i>
                  <strong>Shop</strong>
                  Airdrop
                  <span>Solicite suprimento aéreo e resgate no ponto de extração.</span>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="features">
            <AccordionItemHeading>
              <AccordionItemButton>
                <h5 className="wow fadeInUp" data-wow-delay=".2s">
                  Features
                  <AccordionItemState>
                    {({ expanded }) => (expanded ? <i className="lni lni-minus"></i> : <i className="lni lni-plus"></i>)}
                  </AccordionItemState>
                </h5>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="blocks row">
                <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" onClick={() => goToPage('/dashboard/my-bank')}>
                  <i className="lni lni-credit-cards"></i>
                  <strong>Feature</strong>
                  My Bank
                  <span>Transfira / deposite DRB$ ou veja seu histórico de transações.</span>
                </div>

                <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" onClick={() => goToPage('/dashboard/bounty')}>
                  <i className="lni lni-target-customer"></i>
                  <strong>Feature</strong>
                  Recompensas
                  <span>Cadastre a recompensa e coloque o sobrevivente como alvo.</span>
                </div>

                <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" onClick={() => goToPage('/dashboard/heat-map')}>
                  <i className="lni lni-map"></i>
                  <strong>Feature</strong>
                  Mapa de Calor
                  <span>Veja quais áreas do mapa tem o maior movimento de sobreviventes</span>
                </div>

                <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" onClick={() => goToPage('/dashboard/killfeed')}>
                  <i className="lni lni-comments"></i>
                  <strong>Feature</strong>
                  Kill Feed
                  <span>Descubra todas as eliminações do servidor</span>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="votacoes">
            <AccordionItemHeading>
              <AccordionItemButton>
                <h5 className="wow fadeInUp" data-wow-delay=".2s">
                  Votações
                  <AccordionItemState>
                    {({ expanded }) => (expanded ? <i className="lni lni-minus"></i> : <i className="lni lni-plus"></i>)}
                  </AccordionItemState>
                </h5>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="blocks row">
                <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" onClick={() => goToPage('/dashboard/start-point')}>
                  <i className="lni lni-thumbs-up"></i>
                  <strong>Votações</strong>
                  Start Point
                  <span>Vote no próximo Start Point e defina os locais de respawn para a próxima estação</span>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="xbox-live">
            <AccordionItemHeading>
              <AccordionItemButton>
                <h5 className="wow fadeInUp" data-wow-delay=".2s">
                  Xbox Live
                  <AccordionItemState>
                    {({ expanded }) => (expanded ? <i className="lni lni-minus"></i> : <i className="lni lni-plus"></i>)}
                  </AccordionItemState>
                </h5>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="blocks margin-to-footer row">
                <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" onClick={() => goToPage('/dashboard/capturas')}>
                  <i className="lni lni-image"></i>
                  <strong>Xbox Live</strong>
                  Capturas
                  <span>Veja suas capturas de telas e gameclips</span>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          {moderStatus ? 
            <AccordionItem uuid="moder">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h5 className="wow fadeInUp" data-wow-delay=".2s">
                    Moderação
                    <AccordionItemState>
                      {({ expanded }) => (expanded ? <i className="lni lni-minus"></i> : <i className="lni lni-plus"></i>)}
                    </AccordionItemState>
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="blocks margin-to-footer row">
                  <div className="block moder col-lg-2 col-md-4 col-12 wow fadeInUp" data-wow-delay=".6s" onClick={() => setOpenModerModal()}>
                    <i className="lni lni-user"></i>
                    <strong>Moderação</strong>
                    Banir sobrevivente
                  </div>
                  <SyncData />
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            :
            <></>
          }
        </Accordion>

        <div className="clear-footer"></div>
      </div>
    </>
  );
};
