import React, { useState } from "react"
import PureModal from "react-pure-modal"
import "react-pure-modal/dist/react-pure-modal.min.css"
import { useDispatch } from "react-redux"
import { toast } from 'react-toastify'
import api from '../../services/Api'
import { setWhitelistModalIsOpen } from "../../store/ducks/AppDuck"
import { useGetApp } from "../../store/hooks/AppHook"
import { useGetUser } from '../../store/hooks/UserHook'

export const Whitelist: React.FC = () => {
  const app = useGetApp()
  const dispatch = useDispatch()
  const user = useGetUser()
  const [rules, setRules] = useState(false)
  const [loader, setLoader] = useState(<>Quero me registrar</>)
  const [disableButton, setDisableButton] = useState(true)

  const checkTerms = () => {
    if(rules) {
      setRules(false)
      setDisableButton(true)
    } else {
      setRules(true)
      setDisableButton(false)
    }
  }

  const config = {
    headers: {
      gamertag: `${user.gamertag}`,
      xuid: `${user.xuid}`,
      email: `${user.email}`
    }
  }

  const addToWhitelist = async (event: any) => {
    event.preventDefault()

    try {
      setDisableButton(true)
      setLoader(<span className="loader"></span>)
      
      await api.post('whitelist/create', {}, config)
      dispatch(setWhitelistModalIsOpen(false))
      toast.success(
        <div>
          <strong>{user.gamertag}</strong>, você foi adicionado a whitelist com sucesso.
        </div>
        , {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        onClose: () => {
          toast.warning(
            <div>
              <strong>{user.gamertag}</strong>, o seu acesso ao servidor acontecerá nos próximos 5 minutos. <br />
              Caso não acontecer, abra um ticket em nosso forum em <strong>Soluções de Problemas</strong>.
            </div>
            , {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          })
        }
      })
    }
    catch (err) {
      dispatch(setWhitelistModalIsOpen(false))
      toast.error(
        <div>
          Não foi possível adicionar a whitelis!<br />
          <strong>Por favor, tente novamente.</strong>
        </div>
        , {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }

  return (
    <PureModal
      header="Whitelist"
      footer={
        <div  className={`button ${disableButton ? 'disabled' : ''}`}>
          <a
            href="/"
            className={`btn ${disableButton ? 'disabled' : ''}`}
            onClick={(event) => rules ? addToWhitelist(event) : event.stopPropagation()}
          >
            {loader}
          </a>
        </div>
      }
      scrollable={false}
      className={"dialog"}
      isOpen={app.whitelist.modalIsOpen}
      onClose={() => dispatch(setWhitelistModalIsOpen(false))}
    >
      <>
        Olá, <strong>{user.gamertag}!</strong> <br />
        Para entrar em nossa whitelist, você precisa estar de acordo com algumas regras:
        <ul>
          <li>
              O servidor adota a abordagem do <strong>RAID Estratégico</strong>.
              <ul>
                  <li>Isso implica que <strong>invadir bases não é permitido</strong>, seja por falhas na construção, glitches do jogo, entre outros.</li>
                  <li>O confronto para defender ou conquistar bases é totalmente válido, desde que os responsáveis estejam online.</li>
                  <li>O <strong>RAID Estratégico</strong> consiste em analisar e estudar as bases, conhecer os horários em que os responsáveis estão online, e até mesmo suas rotinas, para planejar o <strong>RAID</strong>.</li>
                  <li>No entanto, nosso servidor apresenta um evento específico dedicado às RAID, conhecido como The Purge! Este evento tem uma duração de 4 horas e ocorre de forma imprevisível entre 15 a 25 dias após a conclusão do último evento. Durante essa experiência intensa, os danos à construção são desencadeados, permitindo que as bases sejam alvos de RAID. Prepare-se para a ação!</li>
              </ul>
          </li>
          <li>
              O servidor é dividido em temporadas, e a cada temporada ocorre um <strong>wipe</strong>.
              <ul>
                  <li>Todos os dados no servidor serão excluídos, incluindo: <strong>personagens, bases, itens, etc</strong>.</li>
                  <li>A lista de <strong>Whitelist também será reiniciada</strong>, sendo necessário que cada jogador se cadastre novamente.</li>
              </ul>
          </li>
          <li>
              Em caso de banimento por <strong>violação das regras</strong>, a penalidade pode variar de <strong>30 minutos a banimento por toda a temporada</strong>.
              <ul>
                  <li>Após o banimento, um e-mail será enviado notificando o motivo. Em caso de discordância, é possível abrir um ticket em nosso <strong>Fórum</strong> apresentando sua defesa.</li>
                  <li>O sistema de banimento é automático, e ao término do período, um e-mail informará que você pode voltar a jogar.</li>
                  <li>O sistema é progressivo, com o tempo de banimento aumentando conforme as infrações, podendo chegar a banimento por toda a temporada.</li>
              </ul>
          </li>
          <li>Sua Gamertag será <strong>liberada</strong> alguns minutos após o cadastro.
              <ul>
                  <li>Se a liberação não ocorrer em <strong>até 30 minutos</strong>, entre em contato conosco através do nosso <strong>Fórum ou Discord</strong>.</li>
              </ul>
          </li>
          <li>Respeite outros jogadores.</li>
          <li>Divirta-se!!!</li>
        </ul>
        <div className="check-rules">
          <input type={"checkbox"} checked={rules} onChange={() => checkTerms()} />
          <label onClick={() => checkTerms()}>Eu, <strong>{user.gamertag}</strong>, li e aceito as regas acima</label>
        </div>
      </>
    </PureModal>
  );
};

export default Whitelist;
