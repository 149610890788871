import * as AlertDialog from '@radix-ui/react-alert-dialog';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Api from '../../services/Api';
import { useGetUser } from '../../store/hooks/UserHook';
import { SelectSource } from '../coins/selectSource';
import { NoData } from '../structure/noData';
import { Coin } from './coin';
import { Deposit } from './deposit';

export const MyBank: React.FC = () => {
  const user = useGetUser();
  const [isLoading, setIsLoading] = useState(true);
  const [isSender, setIsSender] = useState(false);
  const [history, setHistory] = useState([]);
  const [options, setOptions] = useState(Object);
  const [loadListGamertag, setLoadListGamertag] = useState("Carregando Gamertags");
  const [drbValue, setDrbValue] = useState('0.00');
  const [isTransfer, setIsTransfer] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [bankTypeAction, setBankTypeAction] = useState('transfer');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const perPage = isMobile ? 5 : 10;
  const { control, register, handleSubmit, formState: { errors }, setValue, getValues, trigger } = useForm<FormData>();
  const [sourceSelected, setSourceSelected] = useState('coins');

  type FormData = {
    gamertag: String;
    value: String;
  };

  const handleDrbValue = (e: any) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      if (parseFloat(inputValue) < 0) {
        setDrbValue('0.00');
      } else {
        setDrbValue(inputValue);
      }
    }
  };

  const transferValue = async (data: any) => {
    setIsSender(true);
    try {
      const config = {
        headers: {
          'sender-gamertag': user.gamertag,
          'receiver-gamertag': data.gamertag,
          amount: data.value,
          source: sourceSelected,
        },
      };

      const response = await Api.post('coins/transfer', {}, config);
      setIsTransfer(true);
      setIsSender(false);

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {
            setIsTransfer(false);
          },
        });
      }

      if (!response.data.success) {
        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {
            setIsTransfer(false);
          },
        });
      }
    } catch (err) {
      setIsSender(false);
      setIsTransfer(false);
      toast.error(
        <div>OPS! Não foi possível transferir o valor.</div>,
        {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  const confirmTransfer = (data: any) => {
    transferValue(data);
    setIsDialogOpen(false);
  };

  const handleValidation = async () => {
    const isValid = await trigger();
    if (isValid) {
      setIsDialogOpen(true);
    }
  };

  useEffect(() => {
    const getGamertags = async () => {
      try {
        const config = {
          headers: {
            'clean_data': true,
          },
        };

        const response = await Api.get('whitelist/list', config);
        const updatedArr = response.data
          .map((data: any) => ({ value: data.gamertag, label: data.gamertag }))
          .filter((data: any) => data.value !== user.gamertag);

        setOptions(updatedArr);
        setLoadListGamertag("Selecione a Gamertag");
      } catch (err) {
        toast.error(
          <div>OPS! Não foi possível recuperar as Gamertags.</div>,
          {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    };

    const getHistory = async () => {
      try {
        const config = {
          headers: {
            gamertag: `${user.gamertag}`,
          },
        };

        const response = await Api.get(`coins/history?page=${currentPage}&per_page=${perPage}`, config);
        setIsLoading(false);
        if (response.data.success) {
          setHistory(response.data.history);
          setTotalPages(response.data.totalPages);
        }
      } catch (err) {
        toast.error('Ops! Não foi possivel obter seu histórico.', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    };

    getHistory();
    getGamertags();
  }, [isTransfer, currentPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const Description = (data: any) => {
    let desc = <></>;

    switch (data.data.transaction_type) {
      case 'pay':
        desc = (<>Você pagou {data.data.gamertag_receiver}. <br /> <i>{data.data.description}</i></>);
        break;
      case 'receive':
        desc = (<>Você recebeu de {data.data.gamertag_sender}. <br /> <i>{data.data.description}</i></>);
        break;
      case 'lost':
        desc = (<>Você morreu com saldo. <br /> <i>Perdeu o valor da carteira</i></>);
        break;
      case 'kill':
        desc = (<>Você matou {data.data.gamertag_sender}. <br /> <i>Recebeu o valor da carteira</i></>);
        break;
      case 'death':
        desc = (<>Morreu para {data.data.gamertag_receiver}. <br /> <i>Perdeu o valor da carteira</i></>);
        break;
      case 'deposit-status':
        desc = (<>Você depositou. <br /> <i>{data.data.description}</i></>);
        break;
      case 'buy':
        desc = (<>Pagamento realizado. <br /> <i>{data.data.description}</i></>);
        break;
      case 'buy-coin':
        desc = (<>Você comprou DRB$.</>);
        break;
      case 'reversal':
        desc = (<>Reversão de transação. <br /> <i>{data.data.description}</i></>);
        break;
      case 'receive-daily':
        desc = (<>Transferência do sistema. <br /> <i>{data.data.description}</i></>);
        break;
    }

    return desc;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Transferências e Depósitos</h2>
          <p>Realize transferências para outros sobreviventes, faça seus depósitos e acompanhe o extrato de todas as transações realizadas e recebidas.</p>

          <div className="col-lg-3 col-md-5 col-sm-12 offset-lg-9 offset-md-7">
            <Coin reload={isTransfer} />
          </div>

          <ul className='menu-tab'>
            <li
              className={bankTypeAction === 'transfer' ? 'active' : ''}
              onClick={() => {
                if (bankTypeAction !== 'transfer') {
                  setBankTypeAction('transfer');
                }
              }}>
              Transferir
            </li>
            <li
              className={bankTypeAction === 'deposit' ? 'active' : ''}
              onClick={() => {
                if (bankTypeAction !== 'deposit') {
                  setBankTypeAction('deposit');
                }
              }}>
              Depositar
            </li>
          </ul>

          <form style={{ display: bankTypeAction === 'transfer' ? 'block' : 'none' }}>
            <div className='transfer row'>
              <ul className="error">
                {errors.gamertag && <li><i className="lni lni-warning"></i> O campo gamertag é obrigatório.</li>}
                {errors.value && <li><i className="lni lni-warning"></i> {errors.value.message}</li>}
              </ul>

              <SelectSource setSourceSelected={setSourceSelected} sourceSelected={sourceSelected} />

              <div className='receiver col-lg-8 col-md-6 col-12'>
                <Select
                  placeholder={loadListGamertag}
                  options={options}
                  {...register('gamertag', { required: true })}
                  noOptionsMessage={() => "Nenhuma Gamertag foi encontrada"}
                  onChange={(event: any) => {
                    setValue('gamertag', event?.value ?? '');
                  }}
                />
              </div>
              <div className='value col-lg-2 col-md-3 col-12'>
                <Controller
                  name="value"
                  control={control}
                  defaultValue={drbValue}
                  rules={{
                    required: 'O campo valor é obrigatório.',
                    validate: value => parseFloat(String(value)) > 0.00 || 'O valor deve ser maior que zero.'
                  }}
                  render={({ field }) => (
                    <>
                      <div className='label'>DRB$</div>
                      <input
                        type={'number'}
                        value={drbValue}
                        onChange={(e) => {
                          handleDrbValue(e);
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <div className='pay col-lg-2 col-md-3 col-12'>
                {isSender ?
                  <button className='btn btn-primary' disabled>
                    <div className="spinner">
                      <span className="loader"></span>
                    </div>
                  </button>
                  :
                  <>
                    <button type="button" className='btn btn-primary' onClick={handleValidation}>Transferir</button>
                    <AlertDialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                      <AlertDialog.Portal>
                        <AlertDialog.Overlay className="alert-dialog-overlay" />
                        <AlertDialog.Content className="alert-dialog-content">
                          <AlertDialog.Title className="alert-dialog-title">Você tem certeza disso?</AlertDialog.Title>
                          <AlertDialog.Description className="alert-dialog-description">
                           Esta ação irá transferir o valor de <strong>DRB$ {getValues('value')}</strong> para <strong>{getValues('gamertag')}</strong>. Deseja continuar?
                          </AlertDialog.Description>
                          <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
                            <AlertDialog.Cancel asChild>
                              <button className="alert-cancel-button">Cancelar</button>
                            </AlertDialog.Cancel>
                            <AlertDialog.Action asChild>
                              <button className="alert-action-button success" onClick={handleSubmit(confirmTransfer)}>Sim, transferir</button>
                            </AlertDialog.Action>
                          </div>
                        </AlertDialog.Content>
                      </AlertDialog.Portal>
                    </AlertDialog.Root>
                  </>
                }
              </div>
            </div>
          </form>

          <Deposit bankTypeAction={bankTypeAction} setIsTransfer={setIsTransfer} />

          <div className='history'>
            <h5>Histórico <i className="lni lni-graph"></i></h5>
            {isLoading ?
              <ul className='skeleton'>
                <li className="wow">
                  <div className='line'></div>
                  <div className='line'></div>
                  <div className='line'></div>
                </li>
                <li className="wow">
                  <div className='line'></div>
                  <div className='line'></div>
                  <div className='line'></div>
                </li>
                <li className="wow">
                  <div className='line'></div>
                  <div className='line'></div>
                  <div className='line'></div>
                </li>
              </ul>
              :
              (
                Object.keys(history).length > 0 ?
                  <ul>
                    {history.map((item: any, idx: number) => {
                      return (
                        <li key={`item-${idx}`} className="wow fadeInUp" data-wow-delay="0.5s">
                          <div className='date'>
                            <span>{item.date}</span>
                            <span>{item.hour}</span>
                          </div>
                          <div className={`person ${item.transaction_type}`}>
                            <Description data={item} />
                          </div>
                          <div className={`value ${item.transaction_type}`}>{item.total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</div>
                        </li>
                      )
                    })}
                  </ul>
                  :
                  <NoData 
                    description='ainda não foram encontrados registros de transações em sua conta'
                  />
              )
            }
          </div>
          {Object.keys(history).length > 0 ?
            <div className='pagination'>
              <button className='btn btn-primary' onClick={() => {
                setIsLoading(true);
                handlePageChange(currentPage - 1);
              }}
                disabled={currentPage === 1}>Anterior</button>
              <span>Página {currentPage} de {totalPages}</span>
              <button className='btn btn-primary' onClick={() => {
                setIsLoading(true);
                handlePageChange(currentPage + 1);
              }}
                disabled={currentPage === totalPages}>Próxima</button>
            </div>
            : <></>
          }
        </div>
      </div>
    </div>
  );
};