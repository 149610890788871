import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusState } from '../../interfaces'

const initialState: StatusState = {
  event: 'none',
  trader: {
    active: false,
    message: '',
    time: ''
  },
  mec: {
    active: false,
    message: '',
    time: ''
  },
  night: {
    type: '',
    message: '',
  },
  season: {
    season: '',
    title: '',
    tip: '',
    weather: '',
    temp: '',
  },
  config: {
    seasonEnd: '',
    seasonNumber: ''
  }
}

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    // Set Event
    setEvent(state: StatusState, action: PayloadAction<string>) {
      state.event = action.payload
    },

    // Set Trader
    setTraderActive(state: StatusState, action: PayloadAction<boolean>) {
      state.trader.active = action.payload
    },
    setTraderMessage(state: StatusState, action: PayloadAction<string>) {
      state.trader.message = action.payload
    },
    setTraderTime(state: StatusState, action: PayloadAction<string>) {
      state.trader.time = action.payload
    },

    // Set Mec
    setMecActive(state: StatusState, action: PayloadAction<boolean>) {
      state.mec.active = action.payload
    },
    setMecMessage(state: StatusState, action: PayloadAction<string>) {
      state.mec.message = action.payload
    },
    setMecTime(state: StatusState, action: PayloadAction<string>) {
      state.mec.time = action.payload
    },

    // Set Night
    setNightType(state: StatusState, action: PayloadAction<string>) {
      state.night.type = action.payload
    },
    setNightMessage(state: StatusState, action: PayloadAction<string>) {
      state.night.message = action.payload
    },

    // Set Season
    setSeason(state: StatusState, action: PayloadAction<string>) {
      state.season.season = action.payload
    },
    setSeasonTitle(state: StatusState, action: PayloadAction<string>) {
      state.season.title = action.payload
    },
    setSeasonTip(state: StatusState, action: PayloadAction<string>) {
      state.season.tip = action.payload
    },
    setSeasonWeather(state: StatusState, action: PayloadAction<string>) {
      state.season.weather = action.payload
    },
    setSeasonTemp(state: StatusState, action: PayloadAction<string>) {
      state.season.temp = action.payload
    },
    setSeasonEnd(state: StatusState, action: PayloadAction<string>) {
      state.config.seasonEnd = action.payload
    },
    setSeasonNumber(state: StatusState, action: PayloadAction<string>) {
      state.config.seasonNumber = action.payload
    }
  },
})

export const {
  setEvent,
  setTraderActive,
  setTraderMessage,
  setTraderTime,
  setMecActive,
  setMecMessage,
  setMecTime,
  setNightType,
  setNightMessage,
  setSeason,
  setSeasonTitle,
  setSeasonTip,
  setSeasonWeather,
  setSeasonTemp,
  setSeasonEnd,
  setSeasonNumber
} = statusSlice.actions

export default statusSlice.reducer
