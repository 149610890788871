import React, { useEffect, useState } from "react"
import { toast } from 'react-toastify'
import { useGetData } from '../../store/hooks/DataHook'
import { useGetUser } from '../../store/hooks/UserHook'
import UserSection from './userSection'

export interface MenuMobileStatus {
  buttonStatus: Function
  menuStatus: Function
}

export const Login: React.FC<MenuMobileStatus> = ({ buttonStatus, menuStatus }) => {
  const user = useGetUser()
  const data = useGetData()
  const [loader, setLoader] = useState(<>Login com Xbox Live</>)

  const handleLogin = (event: any) => {
    event.preventDefault()
    setLoader(<span className="loader"></span>)
    window.location.href = `https://xbl.io/app/auth/${process.env.REACT_APP_ENV === 'dev' ? 'ceeb9f1a-fb97-ea70-3063-9fa100397c70	' : 'e9e49e8a-3078-4c9b-80d8-b7544e9b8be5'}`
  }

  useEffect(() => {
    if (!data.user && user.code) {
      toast.error(
        <div>
          Não foi possível realizar o login!<br />
          <strong>Por favor, tente novamente.</strong>
        </div>
        , {
        position: "top-left",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }    
  }, [user, data])

  if (data.user && user.gamertag) {
    return(
      <UserSection buttonStatus={buttonStatus} menuStatus={menuStatus} />
    )
  } else {
    return(
      <div className="button add-list-button">
        <a 
          href="/" 
          onClick={(event) => handleLogin(event)}
          className="btn">
          {loader}
        </a>
      </div>
    )
  }
}