import React from 'react';
import { NoData } from '../../structure/noData';

interface FactionAlliedModuleParams {
  data: any[]
  cancelAlliance?: boolean
}

export const FactionAlliedModule: React.FC<FactionAlliedModuleParams> = ({ data, cancelAlliance }) => {  
  return (
    <div className="col-12">
      <div className="card">
        <div className="header">
          <h4 className="title"><i className="lni lni-shield"></i> Facções Aliadas</h4>
        </div>
        <div className='description'>
          <p>Aqui estão todas as Facções que compartilham objetivos e lutam lado a lado. Unidas pela aliança, elas formam um bloco de poder estratégico.</p>
        </div>
        <div className="body">
          {Array.isArray(data) && data.length > 0 &&
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Facções</th>
                    <th style={{textAlign: 'right'}}>Data da aliança</th>
                    {cancelAlliance && <th style={{textAlign: 'right'}}>Ações</th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((faction, index) => (
                    <tr key={index}>
                      <td><img src={faction.logo_image} className='profile-image' alt="Faction" /><a href={`/faction/profile/${faction.id_allied}`} title={faction.allied_name}>{faction.allied_name}</a></td>
                      <td style={{textAlign: 'right'}}>{faction.allied_date}</td>
                      {cancelAlliance && <td style={{textAlign: 'right'}}><button>Cancelar Aliança</button></td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }

          {Array.isArray(data) && data.length === 0 && (
            <NoData
              showGamerTag={false}
              description='Esta facção ainda não possui alianças'
            />
          )}
        </div>
      </div>
    </div>
  )
}