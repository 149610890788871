import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/Api";
import { useGetUser } from "../../store/hooks/UserHook";

interface ICoin {
  reload?: boolean
}

export const Coin: React.FC<ICoin> = ({reload}: ICoin) => {
  const user = useGetUser()
  const [wallet, setWallet] = useState(0.00)
  const [bank, setBank] = useState(0.00)
  const [isLoading, setIsLoading] = useState(false)

  const getWallet = async () => {
    setIsLoading(true)
    const config = {
      headers: {
        gamertag: `${user.gamertag}`
      }
    }

    try {
      const response = await api.get('coins/wallet', config)
      setIsLoading(false)
      if (Object.keys(response.data).length > 0) {
        setWallet(response.data[0].coin)
      } else {
        setWallet(0.00)
      }        
    } catch (error) {
      setWallet(0.00)
      toast.error('Ops! Não foi possivel obter o valor de sua carteira.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  const getBank = async () => {
    setIsLoading(true)
    const config = {
      headers: {
        gamertag: `${user.gamertag}`
      }
    }

    try {
      const response = await api.get('coins/bank', config)
      setIsLoading(false)
      if (Object.keys(response.data).length > 0) {
        setBank(response.data[0].coin)
      } else {
        setBank(0.00)
      }        
    } catch (error) {
      setBank(0.00)
      toast.error('Ops! Não foi possivel obter o valor depositado.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  useEffect(() => {
    getWallet()
    getBank()
  }, [reload]);

  return (
    <div className="coin-card wow fadeInUp">
      <div className="label">
        <i className="lni lni-credit-cards icon"></i> 
        My Bank 
        <i className={`lni lni-reload reload ${reload || isLoading ? 'loading' : ''}`} 
          onClick={() => {
            getWallet()
            getBank()
          }}>
        </i>
      </div>
      <div className="balance">
        <div className="value">
          <span className="title">Carteira</span>
          <strong>
            <span>DRB$</span>
            {wallet.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </strong>
        </div>
        <div className="value">
          <span className="title">Banco</span>
          <strong>
            <span>DRB$</span>
            {bank.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </strong>
        </div>
      </div>
    </div>
  );
};
