import { useState } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import PureModal from "react-pure-modal"
import { toast } from "react-toastify"
import Api from "../../services/Api"

export const SyncData: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const syncData = async (event: any, type: string) => {
    event.preventDefault()
    setDisabled(true)

    try {
      const sync = await Api.post(`sync/${type}`, {}, {})
      if (sync.data.success) {
        toast.success(sync.data.message, {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      } else {
        toast.error(sync.data.message, {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
      setDisabled(false)
    } catch (err) {
      setDisabled(false)
      toast.error('Algo deu errado, entre em contato com a administração!', {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }
  
  const openModal = (event: any) => {
    event.preventDefault()
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <>
      <div className="block moder col-lg-2 col-md-4 col-12 wow fadeInUp" data-wow-delay=".6s" onClick={(event) => openModal(event)}>
        <i className="lni lni-user"></i>
        <strong>Moderação</strong>
        Sync Data
      </div>

      <PureModal
        header="Moderação"
        footer={<></>}
        scrollable={false}
        className={"dialog moder"}
        isOpen={modalIsOpen}
        onClose={() => closeModal()}
      >
        <>
          Para sincronizar dados específicos, escolha uma das opções abaixo:
          <Accordion allowZeroExpanded allowMultipleExpanded className="sync-expand">
            <AccordionItem uuid="sync-player">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h5>
                    Servidor
                    <AccordionItemState>
                      {({ expanded }) => (expanded ? <i className="lni lni-minus"></i> : <i className="lni lni-plus"></i>)}
                    </AccordionItemState>
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul>
                  <li><button disabled={disabled} className='btn btn-primary' onClick={(event) => syncData(event, 'server/whitelist')}>Whitelist</button></li>
                  <li><button disabled={disabled} className='btn btn-primary' onClick={(event) => syncData(event, 'server/banlist')}>Banlist</button></li>
                  <li><button disabled={disabled} className='btn btn-primary' onClick={(event) => syncData(event, 'server/queue')}>Fura Fila</button></li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="sync-bank">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h5>
                    Player
                    <AccordionItemState>
                      {({ expanded }) => (expanded ? <i className="lni lni-minus"></i> : <i className="lni lni-plus"></i>)}
                    </AccordionItemState>
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul>
                  <li><button disabled={disabled} className='btn btn-primary' onClick={(event) => syncData(event, 'player/base-alert')}>Base Alert</button></li>
                  <li><button disabled={disabled} className='btn btn-primary' onClick={(event) => syncData(event, 'player/bount')}>Recompensas</button></li>
                  <li><button disabled={disabled} className='btn btn-primary' onClick={(event) => syncData(event, 'player/kill-feed')}>Kill Feed</button></li>
                  <li><button disabled={disabled} className='btn btn-primary' onClick={(event) => syncData(event, 'player/payment')}>Pagamento</button></li>
                  <li><button disabled={disabled} className='btn btn-primary' onClick={(event) => syncData(event, 'player/queue')}>Fura Fila</button></li>
                  <li><button disabled={disabled} className='btn btn-primary' onClick={(event) => syncData(event, 'player/trust')}>Nível de Confiança</button></li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </>
      </PureModal>
    </>
  )
}

export default SyncData