import { CategoryScale, Chart as ChartJs, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js'
import React, { useEffect, useState } from "react"
import { Line } from 'react-chartjs-2'
import PureModal from "react-pure-modal"
import "react-pure-modal/dist/react-pure-modal.min.css"
import { useDispatch } from "react-redux"
import { toast } from 'react-toastify'
import api from '../../services/Api'
import { setStatisticModalIsOpen } from "../../store/ducks/AppDuck"
import { useGetApp } from '../../store/hooks/AppHook'
import { useGetServer } from '../../store/hooks/ServerHook'

export const PlayerStatistic: React.FC = () => {
  const dispatch = useDispatch()
  const serverData = useGetServer()
  const app = useGetApp()
  const [online, setOnline] = useState(Array<Number>)
  const [time, setTime] = useState(Array<String>)
  const [loading, setLoading] = useState(true)

  ChartJs.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip
  )

  const data = {
    labels: time,
    datasets: [{
      data: online,
      backgroundColor: '#7b4397',
      pointBackgroundColor: '#7b4397',
      borderColor: '#7b4397',
      tension: 0.5
    }]
  }

  const options = {
    plugins: {
      tooltip: {
        enable: true,
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.parsed.y) {
              label = context.parsed.y + " Online"
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        min: 0,
        max: serverData.playerMax
      }
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`statistic`)
        setOnline(response.data.online)
        setTime(response.data.time)
        setLoading(false)
      }
      catch (err) {
        dispatch(setStatisticModalIsOpen(false))
        toast.error(
          <div>
            Não foi possível obter os dados!<br />
            <strong>Por favor, tente novamente.</strong>
          </div>
          , {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    }

    if(app.statistic.modalIsOpen) {
      getData()
    }
  }, [app.statistic.modalIsOpen, dispatch])
  
  return (
    <PureModal
      header="Histórico de Players"
      scrollable={false}
      className={"dialog statistic"}
      isOpen={app.statistic.modalIsOpen}
      onClose={() => dispatch(setStatisticModalIsOpen(false))}
    >
      <>
      <p>Desvende os ritmos pulsantes de sobrevivência de Chernarus através do nosso intrigante gráfico de jogadores online do nosso servidor! Assim como as estrelas cintilam no céu noturno, 
        os dados capturados revelam os momentos de intensa atividade e os momentos de calmaria daqueles que ousam enfrentar o desconhecido.</p> <p>Este gráfico é mais do que uma representação 
        visual: é um portal para entender a dinâmica deste mundo implacável. Seja você um lobo solitário, um caçador furtivo ou um herói relutante, explore os segredos da população de 
        sobreviventes, ajuste suas estratégias e deixe a sua marca nesta narrativa em constante mutação.</p>
      <p>O gráfico releva a média de players online nas últimas 10 horas.</p>
      {loading ? 
          <div className="content spinner">
            <span className="loader"></span>
        </div> 
        : <Line data={data} options={options} />}
      </>
    </PureModal>
  )
}