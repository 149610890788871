import React, { ChangeEvent, useEffect, useState } from 'react'
import PureModal from "react-pure-modal"
import "react-pure-modal/dist/react-pure-modal.min.css"
import { useDispatch } from "react-redux"
import Select from 'react-select'
import { toast } from 'react-toastify'
import api from '../../services/Api'
import { setModerModalIsOpen } from '../../store/ducks/AppDuck'
import { useGetApp } from '../../store/hooks/AppHook'
import { useGetUser } from '../../store/hooks/UserHook'

export const Moder: React.FC = () => {
  const dispatch = useDispatch()
  const app = useGetApp()
  const user = useGetUser()
  const [options, setOptions] = useState(Object)
  const [optionsBanTime, setOptionsBanTime] = useState(Object)
  const [optionSelected, setOptionSelected] = useState(Object)
  const [optionTimeSelected, setOptionTimeSelected] = useState(Object)
  const [reason, setReason] = useState("")
  const [disableButton, setDisableButton] = useState(true)
  const [loadListGamertag, setLoadListGamertag] = useState("Carregando Gamertags")
  const [loadBanTime, setLoadBanTime] = useState("Selecione uma Gamertag")
  const [isLoading, setIsLoading] = useState(<>Banir</>)
  const maxLength = 255;

  useEffect(() => {
    const getGamertags = async () => {
      try {
        const arr: any[] = []
        const response = await api.get('whitelist/list')
        response.data.map((data: any) => {
          return arr.push({value: {
            gamertag: data.gamertag,
            xuid: data.xuid,
            email: data.email
          }, label: data.gamertag})
        })

        setOptions(arr)
      } catch (err) {
        toast.error(
          <div>
            Não foi possível recuperar as Gamertags<br />
            <strong>Por favor, tente novamente.</strong>
          </div>
          , {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    }

    if(app.moder.modalIsOpen) {
      getGamertags().then(() => {
        setLoadListGamertag("Selecione a Gamertag")
      })
    }
  }, [app.moder.modalIsOpen])

  const getTimeban = async (data: any) => {
    setOptionsBanTime(null)
    setLoadBanTime("Carregando tempo de banimento")
    try {
      const config = {
        headers: {
          gamertag: `${data.value}`
        }
      }
      const arr: any[] = []
      const response = await api.get('banlist/history', config)
      response.data.map((data: any) => {
        return arr.push(data)
      })

      setOptionsBanTime(arr)
      setLoadBanTime("Selecione o tempo de banimento")
    } catch (err) {
      toast.error(
        <div>
          Não foi possível recuperar o tempo de banimento<br />
          <strong>Por favor, tente novamente.</strong>
        </div>
        , {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }
  
  const enalbeButton = () => {
    const isOptionSelected = Object.keys(optionSelected).length > 0
    const isOptionTimeSelected = Object.keys(optionTimeSelected).length > 0
    const isReasonFilled = reason.trim() !== ''
    const isReasonValid = reason.length > 20

    if (isOptionSelected && isOptionTimeSelected && isReasonFilled && isReasonValid) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }

  const reasonFilled = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setReason(value.slice(0, maxLength)); 
    enalbeButton()
  }

  const closeModal = () => {
    dispatch(setModerModalIsOpen(false))
    setOptionSelected([])
    setOptionTimeSelected([])
    setReason('')
    enalbeButton()
  }

  const banUser = async (event: any) => {
    event.preventDefault()
    setDisableButton(true)
    setIsLoading(<span className="loader"></span>)

    try {
      const config = {
        headers: {
          gamertag: `${optionSelected.value.gamertag}`,
          xuid: `${optionSelected.value.xuid}`,
          email: `${optionSelected.value.email}`,
          type: 'ban',
          ban_duration: `${optionTimeSelected.value}`,
          reason: `${reason}`
        }
      }

      console.log(config)

      await api.delete('whitelist/delete', config)
      await api.post('banlist/create', {}, config)
      dispatch(setModerModalIsOpen(false))
      setIsLoading(<>Banir</>)
      toast.success(
        <div>O sobrevivente {optionSelected.label} foi banido com sucesso por {optionTimeSelected.label}.</div>
        , {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    } catch (err) {
      toast.error(
        <div>
          Não foi possível banir o sobrevivente {optionSelected.label}<br />
          <strong>Por favor, tente novamente.</strong>
        </div>
        , {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }

  return (
    <PureModal
      header="Moderação"
      footer={<></>}
      scrollable={false}
      className={"dialog moder"}
      isOpen={app.moder.modalIsOpen}
      onClose={() => closeModal()}
    >
      <>
        <strong>Banir Sobrevivente</strong>
        <p>Selecione a GamerTag a ser banida.</p>
        <Select
          placeholder={loadListGamertag}
          options={options}
          noOptionsMessage={() => "Nenhuma Gamertag foi encontrada"}
          onChange={(event) => {
            setOptionSelected(event)
            getTimeban(event)
          }}
          onInputChange={() => enalbeButton()}
        />

        <p>Selecione o tempo que a GamerTag deverá ficar banida.</p>
        <div className='alert-warning'>
          <i className="lni lni-warning"></i>
          O tempo de banimento é progressivo, cada Gamertag terá tempos diferentes de acordo com as punições aplicadas anteriormente.
        </div>
        <Select
          placeholder={loadBanTime}
          options={optionsBanTime}
          noOptionsMessage={() => "Tempo de banimento inexisetente"}
          onChange={(event) => setOptionTimeSelected(event)}
          onInputChange={() => enalbeButton()}
          isDisabled={Object.keys(optionSelected).length === 0}
        />

        <p>Descreva o motivo do banimento.</p>
        <textarea
          value={reason}
          onChange={reasonFilled}
          placeholder=""
        />
        <div className='count-character'>Caracteres restantes: {maxLength - reason.length}</div>

        <button className="btn-banir" onClick={(event) => banUser(event)} disabled={disableButton}>{isLoading}</button>
      </>
    </PureModal>
  )
}

export default Moder