import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Breadcrumb, Footer, Header, RemoveAccount as RemoveAccountComponent, ServerData } from '../components'
import { useGetUser } from '../store/hooks/UserHook'

const RemoveAccount: React.FC = () => {
  const user = useGetUser()
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  useEffect(() => {
    if(!user.appKey) navigate('/index')
  })

  const breadCrumb = [
    { name: "Dashboard", url: "/dashboard" },
    { name: "Remover Conta", url: "/dashboard/remove-account" },
  ]

  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''}/>
        <div className='remove-account'>
          <Breadcrumb items={breadCrumb} />
          <RemoveAccountComponent />
        </div>
      <Footer />
    </>
  )
}

export default RemoveAccount