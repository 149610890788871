import * as AlertDialog from '@radix-ui/react-alert-dialog';
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import api from "../../services/Api";
import { useGetUser } from '../../store/hooks/UserHook';
import { BaseLogs } from "./baseLogs";
import { BaseSecurity } from "./baseSecurity";

export const BaseAlert: React.FC = () => {
  const user = useGetUser()
  const [isLoading, setIsLoading] = useState(true)
  const [hasBase, setHasBase] = useState(false)
  const [baseData, setBaseData]  = useState(Object)
  const [coordinates, setCoordinates] = useState({x: 0, y: 0})
  const [baseSecurity, setBaseSecurity] = useState(<BaseSecurity coordinates={{}} />)

  const FormRegisterBase = () => {
    const {register, handleSubmit, formState: { errors }} = useForm<FormData>()
    const onSubmit = handleSubmit(data => saveBase(data))

    type FormData = {
      x: Number;
      y: Number;
    }

    const saveBase = async (data: any) => {
      setIsLoading(true)
      const configSaveBase = {
        headers: {
          gamertag: `${user.gamertag}`,
          x: data.x,
          y: data.y
        }
      }
      try {
        await api.post('base-alert/create', {}, configSaveBase)
        setIsLoading(false)
        setHasBase(true)
        setCoordinates({x: data.x, y: data.y})
        setBaseSecurity(<BaseSecurity coordinates={data} />)
      } catch (err) {
        toast.error('Ops! Algo deu errado, tente novamente.', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    }

    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Cadastro de Base</h2>
              <p>Cadastre as coordenadas de sua base e receba logs quando detectarmos outros sobreviventes próximos a sua base!</p>

              <form onSubmit={onSubmit}>
                <ul className="error">
                  {errors.x && <li><i className="lni lni-warning"></i> Coordenada X é obrigatória ou precisar ser um número.</li>}
                  {errors.y && <li><i className="lni lni-warning"></i> Coordenada Y é obrigatória ou precisar ser um número.</li>}
                </ul>
                <input type={'text'} placeholder="Coordenada X" 
                  {...register('x', { 
                    required: true, 
                    valueAsNumber: true,
                    validate: (value) => {
                      const numValue = Number(value);
                      return !isNaN(numValue) && numValue > 0;
                    },
                  })} 
                />
                <input type={'text'} placeholder="Coordenada Y" 
                  {...register('y', { 
                    required: true, 
                    valueAsNumber: true,
                    validate: (value) => {
                      const numValue = Number(value);
                      return !isNaN(numValue) && numValue > 0;
                    },
                  })} 
                />
                <input type="submit" value={"Salvar"} />
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }

  const DeleteBase = () => {
    const deleteBase = async () => {
      setIsLoading(true)
      const configSaveBase = {
        headers: {
          gamertag: `${user.gamertag}`,
        }
      }
      try {
        await api.delete('base-alert/delete', configSaveBase)
        setIsLoading(false)
        setHasBase(false)
      } catch (err) {
        console.error('error')
      }
    }

    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Sua Base</h2>
              <p>Você ja tem uma base cadastrada! Caso você precise alterar as coordenadas de sua base, você pode deletar a atual e cadastrar uma nova.</p>
              <div className="delete-base">
                <div className="info">
                  {coordinates.x !== 0 && coordinates.y !== 0 ? 
                    <>
                      Sua base está cadastrada nas coordenadas <strong>{coordinates.x} / {coordinates.y}</strong> 
                      <br /> <a href={`https://dayz.xam.nu/#location=${coordinates.x};${coordinates.y};7`} target={"_blank"} rel="noreferrer">Visualizar</a>
                    </>
                    : 
                    <>
                      Sua base está cadastrada nas coordenadas <strong>{baseData[0].x} / {baseData[0].y}</strong> 
                      <br /> <a href={`https://dayz.xam.nu/#location=${baseData[0].x};${baseData[0].y};7`} target={"_blank"} rel="noreferrer">Visualizar</a>
                    </> 
                  }
                </div>
                <AlertDialog.Root>
                  <AlertDialog.Trigger asChild>
                    <button className="btn" type="button">Deletar</button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Portal>
                    <AlertDialog.Overlay className="alert-dialog-overlay" />
                    <AlertDialog.Content className="alert-dialog-content">
                      <AlertDialog.Title className="alert-dialog-title">Você tem certeza disso?</AlertDialog.Title>
                      <AlertDialog.Description className="alert-dialog-description">
                        Esta ação irá remover o Base Alert + Base Security e todos os logs referente. Deseja continuar?
                      </AlertDialog.Description>
                      <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
                        <AlertDialog.Cancel asChild>
                          <button className="alert-cancel-button">Cancelar</button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Action asChild>
                          <button className="alert-action-button" onClick={() => deleteBase()}>Sim, remover</button>
                        </AlertDialog.Action>
                      </div>
                    </AlertDialog.Content>
                  </AlertDialog.Portal>
                </AlertDialog.Root>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const configGetData = {
          headers: {
            gamertag: `${user.gamertag}`
          }
        }
        const response = await api.get('base-alert/has-base', configGetData)
        if(Object.keys(baseData).length === 0 && isLoading) {
          setIsLoading(false)
          if(response.data[0].gamertag === user.gamertag) {
            setBaseSecurity(<BaseSecurity coordinates={response.data[0]} />)
            setBaseData(response.data)
            setHasBase(true)
          }
        }
      } catch (err) {
        setBaseData({})
        setIsLoading(false)
      }
    }
    
    getData()
  }, [hasBase])

  return (
    <>
    {isLoading ?
        <div className='wow fadeInRight'>
          <div className="spinner">
            <span className="loader"></span>
          </div>
        </div>
        :
        <>
          {hasBase ? <DeleteBase /> : <FormRegisterBase />}
          {hasBase ? baseSecurity : <></>}
          {hasBase ? <BaseLogs /> : <></>}
        </>
      }
    </>
  );
};
