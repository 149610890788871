import React from 'react'
import { NoInternet as NoInternetIcon } from '../components/icons/no-internert.svg'

const NoInternet: React.FC = () => {
  return (
    <div className='interfaces-page wow fadeInUp'>
      <div className="container">
        <div className='icon'>
          <NoInternetIcon />
        </div>
        <p className='text'><span className='wow fadeInUp'>Você está offline. Por favor, verifique sua conexão.</span></p>
      </div>
    </div>
  )
}

export default NoInternet