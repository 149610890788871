import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState } from '../../interfaces'

const initialState: DataState = {
  status: false,
  user: false
}

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setStatus(state: DataState, action: PayloadAction<boolean>) {
      state.status = action.payload
    },
    setUser(state: DataState, action: PayloadAction<boolean>) {
      state.user = action.payload
    }
  },
})

export const {
  setStatus,
  setUser
} = dataSlice.actions

export default dataSlice.reducer
