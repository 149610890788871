import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from 'react-select'
import Api from "../../services/Api"
import { useGetUser } from "../../store/hooks/UserHook"
import { FactionCreateModule } from "./ modules/create"

interface FactionSearchProps {
  showText: boolean
}

export const FactionSearch: React.FC<FactionSearchProps> = ({ showText }) => {
  const user = useGetUser()
  const [loadListGamertag, setLoadListGamertag] = useState("Carregando facções...")
  const [options, setOptions] = useState(Object)
  const [showCreateButton, setShowCreateButton] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const config = {
        headers: {
          gamertag: user.gamertag
        }
      }

      try {
        const response = await Api.get('faction/search', config)
        const {faction, showCreateButton} = response.data
        setShowCreateButton(showCreateButton)

        if (faction) {
          setOptions(faction)
          setLoadListGamertag("Selecione uma facção")
        }
      } catch (err) {
        setOptions({})
        setLoadListGamertag("Selecione uma facção")
      }
    }

    getData()
  }, [user.gamertag])

  return (
    <>
      {showText ?
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Facção</h2>
              <p>Bem-vindo ao coração das alianças e rivalidades! Explore as facções que moldam o destino do nosso servidor. 
                Cada facção conta uma história única de coragem, estratégia e sobrevivência. Selecione uma das facções e mergulhe em seu mundo – descubra seus segredos, 
                conheça seus membros e entenda suas motivações. O próximo capítulo da sua aventura começa aqui.</p>
              <Select
                placeholder={loadListGamertag}
                options={options}
                noOptionsMessage={() => "Nenhuma facção foi encontrada"}
                onChange={(event: any) => {
                  navigate(`/faction/profile/${event.value}`)
                }}
              />

              {showCreateButton &&
                <FactionCreateModule />
              }
            </div>
          </div>
        </div>
        :
        <Select
          className="select-faction"
          placeholder={loadListGamertag}
          options={options}
          noOptionsMessage={() => "Nenhuma facção foi encontrada"}
          onChange={(event: any) => {
            navigate(`/faction/profile/${event.value}`)
          }}
        />
      }
    </>
  )
}