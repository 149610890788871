export function Member() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" x="0" y="0" viewBox="0 0 492 492" enableBackground="new 0 0 512 512" xmlSpace="preserve">
    <g>
      <path fill="#c8cacf" fillRule="evenodd" d="m231.247 289.71 22.867-26.543 132.117 132.117c6.822 6.822 6.822 17.883 0 24.705-6.822 6.822-17.883 6.822-24.705 0z" clipRule="evenodd" opacity="1" data-original="#c8cacf"></path>
      <path fill="#566371" fillRule="evenodd" d="M333.263 409.181a4.119 4.119 0 0 1-2.914-1.207l-24.705-24.705a4.12 4.12 0 0 1 0-5.828l38.038-38.038a4.122 4.122 0 0 1 5.828 0l24.705 24.705a4.12 4.12 0 0 1 0 5.828l-38.038 38.038a4.123 4.123 0 0 1-2.914 1.207zm-18.876-28.827 18.876 18.876 32.21-32.21-18.876-18.877z" clipRule="evenodd" opacity="1" data-original="#566371"></path>
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#bab269" d="M414.305 233.097v16.267h-145.74l-13.755 14.497-14.096 14.86-.008.008-19.263 20.306H71.654v-59.269c0-23.541 4.748-45.975 13.336-66.394 9.979-23.732 25.149-44.743 44.087-61.619 30.288-26.983 70.217-43.381 113.973-43.381 4.578 0 9.114.18 13.601.533a169.94 169.94 0 0 1 65.884 19.041c42.606 22.415 74.655 62.268 86.7 110.012a171.524 171.524 0 0 1 5.07 35.139z" opacity="1" data-original="#bab269"></path>
        <path fill="#6a6b32" d="m409.236 197.959-46.271-11.501a26.994 26.994 0 0 0-12.915-.028l-17.4 4.245a27.023 27.023 0 0 1-18.111-1.901c-9.788-4.709-15.322-14.425-15.322-24.391a26.64 26.64 0 0 1 4.892-15.399c11.527-16.385 16.694-38.971 18.428-61.036 42.604 22.415 74.653 62.267 86.699 110.011zM168.441 224.393a22.794 22.794 0 0 0-2.55 4.485 23.028 23.028 0 0 0-1.484 4.943l-.425 2.331c-2.684 14.778-18.564 23.073-32.225 16.836l-18.485-8.439a23.025 23.025 0 0 0-20.053.443l-21.565 11.504v-16.731c0-23.541 4.748-45.975 13.336-66.394l9.343-5.348c9.786-5.409 22.042-3.009 29.064 5.69l2.875 3.563a23.021 23.021 0 0 0 13.498 8.135l14.469 2.834c16.529 3.236 24.107 22.53 14.202 36.148zM255.528 99.897c-.021.038-.039.082-.057.116l-7.146 15.061a14.981 14.981 0 0 1-16.306 8.292l-9.083-1.713a14.99 14.99 0 0 0-15.901 7.509l-4.559 8.292c-2.878 5.234-7.991 7.769-13.091 7.769-5.886 0-11.749-3.374-14.106-9.858a14.973 14.973 0 0 0-14.072-9.858h-17.205c-7.859 0-14.304-6.051-14.927-13.753 30.288-26.983 70.217-43.381 113.973-43.381 4.578 0 9.114.18 13.601.533a37.936 37.936 0 0 1-1.121 30.991zM210.348 172.983l44.599-21.491 11.475 13.962c9.089 11.058 1.222 27.707-13.092 27.707a16.945 16.945 0 0 0-12.707 5.735l-12.032 13.637c-11.113 12.595-31.808 3.087-29.492-13.549l1.82-13.072a16.95 16.95 0 0 1 9.429-12.929zM218.129 271.085l26.747-28.767a24.865 24.865 0 0 1 18.207-7.932l149.835.001c9.755 0 17.662 7.908 17.662 17.662 0 9.755-7.908 17.662-17.662 17.662H278.371a24.85 24.85 0 0 0-18.204 7.93l-26.758 28.777a24.834 24.834 0 0 1-18.19 7.923l-135.617.001c-9.755 0-17.662-7.908-17.662-17.662 0-9.755 7.908-17.662 17.662-17.662l120.321-.001a24.863 24.863 0 0 0 18.206-7.932z" opacity="1" data-original="#6a6b32"></path>
      </g>
    </g>
  </svg>
  )
}