import React, { useState } from "react"
import { useGetUser } from "../../store/hooks/UserHook"

export const GetWhitelist: React.FC = () => {
  const [loader, setLoader] = useState(<>Entrar para Whitelist</>)
  const [disabled, setDisabled] = useState(false)
  const user = useGetUser()

  const handleLogin = (event: any) => {
    event.preventDefault()
    setLoader(<span className="loader"></span>)
    setDisabled(true)
    window.location.href = "https://xbl.io/app/auth/e9e49e8a-3078-4c9b-80d8-b7544e9b8be5"
  }

  if(!user.gamertag) {
    return (
      <section className="section call-action">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
              <div className="cta-content">
                <h2 className="wow fadeInUp" data-wow-delay=".2s">
                  Entrar para whitelist
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  Para fazer parte do servidor, você precisa estar na <strong>whitelist</strong>! <br />
                  Faça login com a sua conta da <strong>Xbox Live</strong> e entre para a nossa whitelist agora mesmo.
                </p>
                <div className="button wow fadeInUp" data-wow-delay=".6s">
                  <a 
                    href="/" 
                    onClick={(event) => handleLogin(event)}
                    className={`btn ${disabled ? 'disabled': ''}`}>
                    {loader}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return (<></>)
  }
}
