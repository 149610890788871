import React from "react";

interface BreadcrumbItem {
  name: string;
  url: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const lastSegment = url.pathname.split('/').pop();

  return (
    <div className="container">
      <ul className="breadcrumb">
        <li><a href="/index"><i className="lni lni-home"></i></a></li>
        {items.map((item, index) => (
          <li key={index}>
            {lastSegment === item.url.split('/').pop() ? (
              <>{item.name}</>
            ) : (
              <a href={item.url}>{item.name}</a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
