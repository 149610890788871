import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { toast } from 'react-toastify';
import Api from '../../services/Api';
import { FactionAlliedModule } from './ modules/allied';
import { FactionMessagesModule } from './ modules/messages';
import { FactionPlayersModule } from './ modules/players';
import { FactionProfileModule } from './ modules/profile';
import { FactionRivalModule } from './ modules/rival';
import { FactionSearch } from './factionSearch';

interface FactionPagePublicParams {
  id: string | undefined
}

export const FactionPagePublic: React.FC<FactionPagePublicParams> = ({ id }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [factionAllied, setAlliedFaction] = useState<any>([])
  const [faction, setFaction] = useState<any>([])
  const [factionPlayers, setFactionPlayers] = useState<any>([])
  const [factionRival, setAlliedRival] = useState<any>([])

  useEffect(() => {      
    setIsLoading(true)
    getData();
  }, [id])

  const getData = async () => {
    try {
      const config = {
        headers: {
          id_faction: `${id}`,
        }
      }

      const response = await Api.get('faction/profile/public', config)
      const { faction, success } = response.data

      if(success) {
        setAlliedFaction(faction.allied_factions)
        setFaction(faction.infos)
        setFactionPlayers(faction.faction_players)
        setAlliedRival(faction.rival_factions)
      } else {
        navigate('/404')
      }

      setIsLoading(false)
    } catch (err) {
      toast.error('Ops! Não foi possivel obter os dados da facção.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }
  
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {isLoading ? 
            <div className="spinner page">
              <span className="loader"></span>
            </div>
            :
            <>
              <h2>{faction.name}</h2>
              <p>Aqui, você encontrará todos os detalhes que fazem desta facção uma força a ser reconhecida no servidor. Conheça seus líderes, entenda suas motivações 
                e veja como eles moldam o destino daqueles que cruzam seu caminho. Descubra alianças estratégicas, rivalidades intensas e os feitos que marcaram a história 
                dessa facção. Prepare-se para se aprofundar no mundo dos que lutam juntos por poder, honra e sobrevivência..</p>

              <FactionSearch showText={false}/>

              <Helmet key="profileHelmet">
                <meta property="og:title" content={`Dead Rising Brasil - ${faction.name}`} />
                <meta property="og:description" content={faction.description} />
                <meta property="og:image" content={`https://deadrisingbrasil.com${faction.image.cover.url}`} />
                <meta property="og:url" content={`https://deadrisingbrasil.com/survivor/profile/${id}`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Dead Rising Brasil - ${faction.name}`} />
                <meta name="twitter:description" content={faction.description} />
                <meta name="twitter:image" content={`https://deadrisingbrasil.com${faction.image.cover.url}`} />
              </Helmet>

              <div className="share-page wow fadeInUp">
                <ul>
                  <li>
                    <FacebookShareButton url={`https://deadrisingbrasil.com/survivor/profile/${id}`} >
                      <FacebookIcon size={32} borderRadius={10} />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <TelegramShareButton url={`https://deadrisingbrasil.com/survivor/profile/${id}`} >
                      <TelegramIcon size={32} borderRadius={10} />
                    </TelegramShareButton>
                  </li>
                  <li>
                    <TwitterShareButton url={`https://deadrisingbrasil.com/survivor/profile/${id}`} >
                      <TwitterIcon size={32} borderRadius={10} />
                    </TwitterShareButton>
                  </li>
                  <li>
                    <WhatsappShareButton url={`https://deadrisingbrasil.com/survivor/profile/${id}`} >
                      <WhatsappIcon size={32} borderRadius={10} />
                    </WhatsappShareButton>
                  </li>
                </ul>
              </div>

              <div className="row block">
                <div className='col-lg-5 col-md-12 col-sm-12 wow fadeInUp'>
                  <FactionProfileModule 
                    idFaction={id}
                    data={faction}
                    validators={{
                      belongsTheFaction: false,
                      belongsOtherFaction: false,
                      belongsRequestTheFaction: false,
                      belongsRequestOtherFaction: false,
                      role: ''
                    }}
                    setReloadGetData={() => {}}
                  />
                  <FactionMessagesModule factionId={id} isPrivate={false}/>
                </div>

                <div className='col-lg-7 col-md-12 col-sm-12 wow fadeInUp'>
                  <FactionPlayersModule data={factionPlayers}/>
                  <FactionAlliedModule data={factionAllied}/>
                  <FactionRivalModule data={factionRival}/>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )
}