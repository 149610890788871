import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

export const InstallApp: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);

  // Verifique se o usuário já cancelou o prompt durante a sessão
  const isCancelledInstallApp = sessionStorage.getItem('cancelInstallApp') === 'true';

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsInstalled(isStandalone);

    const handleBeforeInstallPrompt = (event: any) => {
      if (isStandalone || isCancelledInstallApp) {
        return; // Não mostra o prompt se o app já estiver instalado ou o usuário já cancelou
      }
      event.preventDefault(); // Impede o prompt automático
      setDeferredPrompt(event);
      setShowInstallButton(true); // Mostra o botão de instalação
    };

    const handleAppInstalled = () => {
      setIsInstalled(true); // Marca que o app foi instalado
      setShowInstallButton(false); // Esconde o botão de instalação
    };

    // Adiciona os eventos de beforeinstallprompt e appinstalled
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, [isCancelledInstallApp]);

  const handleInstallClick = (event: any) => {
    event.preventDefault();
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          toast.success('Perfeito, você instalou nosso aplicativo com sucesso.', {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        } else {
          sessionStorage.setItem('cancelInstallApp', 'true');
          toast.warning('Sem problemas, você pode instalar o aplicativo em outro momento.', {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        }
        setDeferredPrompt(null); // Reseta o deferredPrompt
        setShowInstallButton(false); // Esconde o botão de instalação
      });
    }
  };

  const closeAlert = () => {
    sessionStorage.setItem('cancelInstallApp', 'true');
    setShowInstallButton(false); // Esconde o botão de instalação
  }

  return (
    <>
      {!isInstalled && showInstallButton && isMobile && !isCancelledInstallApp && (
        <div className="install-app wow fadeInUpBig">
          <i className="lni lni-close close-alert" onClick={closeAlert}></i>
          <div className='content'>
            <div className='icon'>
              <img src='/assets/images/logo/192-pwa.png' alt='Ícone do aplicativo' />
            </div>
            <div className='text'>
              <strong>Instale nosso site como aplicativo</strong>
              <p>Agora você pode instalar nosso site como app no seu celular, tornando o acesso aos nossos recursos ainda mais fácil.</p>
              <button onClick={handleInstallClick} className='btn btn-primary'>Instalar Aplicativo</button>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
}