import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ServerDuck from './ducks/ServerDuck'
import DataDuck from './ducks/DataDuck'
import StatusDuck from './ducks/StatusDuck'
import UserDuck from './ducks/UserDuck'
import AppDuck from './ducks/AppDuck'

const reducers = combineReducers({
  data: DataDuck,
  server: ServerDuck,
  status: StatusDuck,
  user: UserDuck,
  app: AppDuck,
})

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    blacklist: [],
  },
  reducers
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootStateType = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatchType = typeof store.dispatch
