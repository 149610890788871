export function NoData() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="200" height="200" x="0" y="0" viewBox="0 0 64 64" enableBackground="new 0 0 512 512" xmlSpace="preserve">
      <g>
        <g data-name="Blank Page">
          <path fill="#cd99e7" d="M14.49 9.5a5 5 0 0 0-5 5v35a5 5 0 0 0 5 5h35a5 5 0 0 0 5-5v-35a5 5 0 0 0-5-5zm.87 4.19a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 1 1-1.5-1.5zm6.5 0a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 1 1-1.5-1.5zm6.5 0a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 1 1-1.5-1.5zM32 23.22A11.77 11.77 0 1 1 20.24 35 11.76 11.76 0 0 1 32 23.22z" opacity="1" data-original="#cd99e7"></path>
          <g fill="#dd5891">
            <path d="M32 23.22A11.77 11.77 0 1 0 43.76 35 11.76 11.76 0 0 0 32 23.22zm-3.09 7.18a1.5 1.5 0 0 1 1.06.44l2 2 2-2a1.5 1.5 0 0 1 1.06-.44 1.5 1.5 0 0 1 1.12 2.6l-2 2 2 2a1.51 1.51 0 0 1 0 2.12 1.49 1.49 0 0 1-2.12 0l-2-2-2 2a1.49 1.49 0 0 1-2.12 0 1.51 1.51 0 0 1 0-2.12l2-2-2-2a1.51 1.51 0 0 1 0-2.12 1.52 1.52 0 0 1 1-.48zM15.36 13.69a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5zM21.86 13.69a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5zM28.36 13.69a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5z" fill="#7b4397" opacity="1" data-original="#7b4397"></path>
          </g>
          <path fill="#cd99e7" d="M28.91 30.4a1.5 1.5 0 0 0-1.06 2.6l2 2-2 2a1.51 1.51 0 0 0 0 2.12 1.49 1.49 0 0 0 2.12 0l2-2 2 2a1.49 1.49 0 0 0 2.12 0 1.51 1.51 0 0 0 0-2.12l-2-2 2-2a1.51 1.51 0 0 0 0-2.12 1.52 1.52 0 0 0-1.06-.44 1.5 1.5 0 0 0-1.06.44l-2 2-2-2a1.5 1.5 0 0 0-1.06-.48z" opacity="1" data-original="#cd99e7"></path>
        </g>
      </g>
    </svg>
  )
}