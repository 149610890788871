import React, { useCallback } from "react";
import { useCountUp } from 'react-countup';
import { useDispatch } from "react-redux";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import type { Engine } from "tsparticles-engine";
import { setStatisticModalIsOpen } from "../../store/ducks/AppDuck";
import { useGetData } from '../../store/hooks/DataHook';
import { useGetServer } from '../../store/hooks/ServerHook';
import { useGetStatus } from '../../store/hooks/StatusHook';
import { PlayerStatistic, Purge, RainEffect, Status } from '../index';
import { SkeletonLoading } from "../structure/skeletonLoading";

export const Hero: React.FC = () => {
  const dispatch = useDispatch()
  const serverData = useGetServer()
  const serverStatus = useGetStatus()
  const data = useGetData()

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine)
  }, [])

  const PlayersOnline = () => {
    useCountUp({ ref: 'counter-players-online', end: serverData.playerOnline });
    return <span id="counter-players-online"></span>
  }

  const PlayersMaxOnline = () => {
    useCountUp({ ref: 'counter-players-max-online', end: serverData.playerMax });
    return <span id="counter-players-max-online"></span>
  }

  const openStatisticModa = (event: any) => {
    event.preventDefault()
    dispatch(setStatisticModalIsOpen(true))
  }

  return (
    <>
      <section id="home" className="hero-area">
        <RainEffect />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="hero-content">
                <h1 className="wow fadeInLeft" data-wow-delay=".6s">
                  {serverStatus.config.seasonNumber ?
                    <>
                      <span className="welcome">Seja bem vindo!</span>
                      Temporada <i className="wow tada" data-wow-delay="2s">{serverStatus.config.seasonNumber}</i>
                    </>
                    :
                    <>
                      <SkeletonLoading 
                        count={1}
                        height={10}
                        width={100}
                        baseColor="#351641"
                        customHighlightBackground="linear-gradient(90deg, rgba(71,51,79,0) 0%, rgba(60,23,73,1) 20%, rgba(60,23,73,1) 80%, rgba(71,51,79,0) 100%)"
                      />
                      <SkeletonLoading 
                        count={1}
                        height={60}
                        width={320}
                        baseColor="#351641"
                        customHighlightBackground="linear-gradient(90deg, rgba(71,51,79,0) 0%, rgba(60,23,73,1) 20%, rgba(60,23,73,1) 80%, rgba(71,51,79,0) 100%)"
                      />
                    </>
                  }
                </h1>
                <p className="wow fadeInLeft" data-wow-delay=".8s">
                  {serverData.status.message ? 
                    <>
                      <span 
                      className="server-status" 
                      style={{
                        backgroundColor: serverData.status.color, 
                        boxShadow: `0px 0px 10px ${serverData.status.color}`}}>
                      </span>
                      {serverData.status.message}
                    </>
                    :
                    <SkeletonLoading 
                      count={1}
                      height={15}
                      width={300}
                      baseColor="#351641"
                      customHighlightBackground="linear-gradient(90deg, rgba(71,51,79,0) 0%, rgba(60,23,73,1) 20%, rgba(60,23,73,1) 80%, rgba(71,51,79,0) 100%)"
                    />
                  }
                </p>

                <Status />
                
                <div className="player-online wow fadeInLeft" data-wow-delay="1s">
                  {data.status ? 
                    <>
                      <span>Players Online: </span>
                      <h2>
                        <PlayersOnline /> / <PlayersMaxOnline />
                      </h2>
                    </> 
                    : 
                    <SkeletonLoading 
                      count={1}
                      height={60}
                      width={200}
                      baseColor="#351641"
                      customHighlightBackground="linear-gradient(90deg, rgba(71,51,79,0) 0%, rgba(60,23,73,1) 20%, rgba(60,23,73,1) 80%, rgba(71,51,79,0) 100%)"
                    />
                  }
                  {data.status ? <a href="/#" onClick={(event) => openStatisticModa(event)} className="link-player-statistic">Veja o histórico <i className="lni lni-bar-chart"></i></a> : <></>}
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="hero-image wow fadeInRight" data-wow-delay=".6s">
                <div className="gradient"></div>
                <Particles className="fadeInLeft" data-wow-delay=".6s" id="particles" init={particlesInit} options={{
                  fullScreen: { enable: false },
                  fpsLimit: 40,
                  particles: {
                    number: {
                      value: 70,
                      density: {
                        enable: true,
                        area: 900
                      }
                    },
                    color: {
                      value: ["#fdcf58", "#757676", "#f27d0c", "#800909", "#f07f13"]
                    },
                    opacity: {
                      value: {
                        min: 0.1,
                        max: 0.5
                      }
                    },
                    size: {
                      value: {
                        min: 1,
                        max: 3
                      }
                    },
                    move: {
                      enable: true,
                      speed: 1,
                      random: true
                    }
                  },
                  interactivity: {
                    detectsOn: "parent",
                    events: {
                      onClick: {
                        enable: false,
                      },
                      resize: true
                    }
                  },
                  detectRetina: true,
                }} />
                <div className="gradient"></div>
                <div className="image">
                  <div className="animate"></div>
                </div>

                <Purge />
              </div>
            </div>
          </div>
        </div>
      </section>

      <PlayerStatistic />
    </>
  );
};
