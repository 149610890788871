import { differenceInDays, differenceInHours, differenceInMinutes, isToday, parseISO } from 'date-fns';
import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import { useCountUp } from 'react-countup';
import { isMobile } from 'react-device-detect';
import api from "../../services/Api";
import { useGetUser } from "../../store/hooks/UserHook";

export const PlayerStatus: React.FC = () => {
  const user = useGetUser()
  const [isLoading, setIsLoading] = useState(true)
  const [playerData, setPlayerData] = useState(Object)
  const [hasStatus, setHasStatus] = useState(false)
  const [expandText, setExpandText] = useState('Ver mais')
  const [countAnimationFinished, setCountAnimationFinished] = useState(false)
  
  useEffect(() => {
    const config = {
      headers: {
        gamertag: `${user.gamertag}`
      }
    }

    const getPlayerStatus = async () => {
        try {
          const playerStatus = await api.get('player/status', config)
          if (Object.keys(playerStatus.data).length > 0 && isLoading) {
            if(playerStatus.data[0].gamertag === user.gamertag) {
              setHasStatus(true)
            }
            setPlayerData(playerStatus.data[0])
          }
        } catch (err) {
          setHasStatus(false)
        }
    }

    getPlayerStatus().finally(() => setIsLoading(false))
  }, []);

  let getLastSeeFirstEntry = { time: 0, label: '' }
  let getFirstSeeFirstEntry = { time: 0, label: '' }
  let CountValues = (props: { label: string, end: number }) => <></>

  if (hasStatus) {
    const formatDateDifference = (date: string) => {
      const currentDate = new Date();
      const targetDate = parseISO(date);
      const diffInMinutes = differenceInMinutes(currentDate, targetDate);
      const diffInHours = differenceInHours(currentDate, targetDate);
      const diffInDays = differenceInDays(currentDate, targetDate);
    
      if (isToday(targetDate)) {
        if (diffInMinutes < 60) {
          return [{
            time: diffInMinutes,
            label: `${diffInMinutes === 1 ? 'minuto' : 'minutos'} atrás`
          }];
        } else if (diffInHours < 24) {
          return [{
            time: diffInHours,
            label: `${diffInHours === 1 ? 'hora' : 'horas'} atrás`
          }];
        }
      } else if (diffInDays === 0) {
        return [{
          time: 0,
          label: 'ontem'
        }];
      } else if (diffInDays === 1) {
        return [{
          time: 1,
          label: 'dia atrás'
        }];
      } else if (diffInDays > 1) {
        return [{
          time: diffInDays,
          label: `${diffInDays === 1 ? 'dia' : 'dias'} atrás`
        }];
      }
    }

    const getLastSee = formatDateDifference(playerData.last_seen)
    getLastSeeFirstEntry = getLastSee?.[0] ?? { time: 0, label: '' }

    const getFirstSee = formatDateDifference(playerData.first_seen)
    getFirstSeeFirstEntry = getFirstSee?.[0] ?? { time: 0, label: '' }

    CountValues = (props: { label: string, end: number }) => {
      useCountUp({ ref: props.label, start: countAnimationFinished ? props.end : 0, end: props.end, onEnd: () => setCountAnimationFinished(true) });
      return <div className="status-value" id={`${props.label}`}></div>
    }
  }

  const setHandleExpandText = () => {
    if (expandText === 'Ver mais') {
      setExpandText('Ver menos')
    } else {
      setExpandText('Ver mais')
    }
  }

  const HandleExpandText = () => {
    return <span>{expandText}</span>
  }


  if (!hasStatus && isLoading) {
    return (
      <>
        <div className="blocks player-status row">
          <div className="block skeleton col-lg-2 col-md-4 col-5 wow fadeInUp" data-wow-delay=".8s">
            <div className="line h17 w40 m10"></div>
            <div className="line"></div>
            <div className="line h8 w50"></div>
          </div>

          <div className="block skeleton col-lg-2 col-md-4 col-5 wow fadeInUp" data-wow-delay=".8s">
            <div className="line h17 w40 m10"></div>
            <div className="line"></div>
            <div className="line h8 w50"></div>
          </div>

          <div className="block skeleton col-lg-2 col-md-4 col-5 wow fadeInUp" data-wow-delay=".8s">
            <div className="line h17 w40 m10"></div>
            <div className="line"></div>
            <div className="line h8 w50"></div>
          </div>

          <div className="block skeleton col-lg-2 col-md-4 col-5 wow fadeInUp" data-wow-delay=".8s">
            <div className="line h17 w40 m10"></div>
            <div className="line"></div>
            <div className="line h8 w50"></div>
          </div>

          {isMobile ? <></> : 
            <div className="block skeleton col-lg-2 col-md-4 col-5 wow fadeInUp" data-wow-delay=".8s">
              <div className="line h17 w40 m10"></div>
              <div className="line"></div>
              <div className="line h8 w50"></div>
            </div>
          }
        </div>
      </>
    )
  }

  return (
    <>
    {hasStatus && !isLoading ? 
      <div className="blocks player-status row">
        {/* SERVIDOR */}
        {/* Tempor de Jogo */}
        <div className="block col-lg-2 col-md-4 col-5 wow fadeInUp">
          <span className="status-title">Tempo de jogo</span>
          <CountValues label="counter-playtime" end={playerData.playtime} />
          <span className="status-label">minuto(s)</span>
        </div>

        {/* Conexões */}
        <div className="block no-label col-lg-2 col-md-4 col-5 wow fadeInUp">
          <span className="status-title">Conexões</span>
          <CountValues label="counter-connections" end={playerData.connections} />
        </div>

        {/* Primeira Conexão */}
        <div className="block col-lg-2 col-md-4 col-5 wow fadeInUp">
          <span className="status-title">Primeira conexão</span>
          <CountValues label="counter-first-connection" end={getFirstSeeFirstEntry.time} />
          <span className="status-label">{getFirstSeeFirstEntry.label}</span>
        </div>

        {/* Ultima conexão */}
        <div className="block col-lg-2 col-md-4 col-5 wow fadeInUp">
          <span className="status-title">Última conexão</span>
          <CountValues label="counter-last-connection" end={getLastSeeFirstEntry.time} />
          <span className="status-label">{getLastSeeFirstEntry.label}</span>
        </div>

        {isMobile ? <></> : 
          //  MORTES
          // Kills
          <div className="block col-lg-2 col-md-4 col-5 wow fadeInUp">
            <span className="status-title">Game Info</span>
            <CountValues label="counter-kills" end={playerData.kills} />
            <span className="status-label">Kills</span>
          </div>
        }

        <Accordion allowZeroExpanded className="accordion">
          <AccordionItem>
            <AccordionItemPanel>
            {!isMobile ? <></> : 
                //  MORTES
                // Kills
                <div className="block col-lg-2 col-md-4 col-5">
                  <span className="status-title">Game Info</span>
                  <CountValues label="counter-kills" end={playerData.kills} />
                  <span className="status-label">Kills</span>
                </div>
              }

              {/* Mortes */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="counter-deaths" end={playerData.deaths} />
                <span className="status-label">Mortes</span>
              </div>

              {/* Mortes por explosão */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="deaths_by_explosion" end={playerData.deaths_by_explosion} />
                <span className="status-label">Mortes por explosão</span>
              </div>

              {/* Mortes por queda */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="deaths_by_falldamage" end={playerData.deaths_by_falldamage} />
                <span className="status-label">Mortes por queda</span>
              </div>

              {/* Mortes por fogo */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="deaths_by_fire" end={playerData.deaths_by_fire} />
                <span className="status-label">Mortes por fogo</span>
              </div>

              {/* Mortes por player */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="deaths_by_player" end={playerData.deaths_by_player} />
                <span className="status-label">Mortes por player</span>
              </div>

              {/* Mortes por suicidio */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="deaths_by_suicide" end={playerData.deaths_by_suicide} />
                <span className="status-label">Mortes por suicídio</span>
              </div>

              {/* Mortes por veiculo */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="deaths_by_vechile" end={playerData.deaths_by_vechile} />
                <span className="status-label">Mortes por veículo</span>
              </div>

              {/* Mortes por zumbi */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="deaths_by_zombie" end={playerData.deaths_by_zombie} />
                <span className="status-label">Mortes por zumbi</span>
              </div>

              {/* CONSTRUÇÕES */}
              {/* Construções */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="builds" end={playerData.builds} />
                <span className="status-label">Construções</span>
              </div>

              {/* Pacotes */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="packs" end={playerData.packs} />
                <span className="status-label">Pacotes</span>
              </div>

              {/* Instalações */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="placements" end={playerData.placements} />
                <span className="status-label">Instalações</span>
              </div>

              {/* Reparações */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="repairs" end={playerData.repairs} />
                <span className="status-label">Reparações</span>
              </div>

              {/* Montagem */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="mounts" end={playerData.mounts} />
                <span className="status-label">Montagem</span>
              </div>

              {/* Desmontagem */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="unmounts" end={playerData.unmounts} />
                <span className="status-label">Desmontagem</span>
              </div>

              {/* Hits */}
              {/* Hits */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="hits" end={playerData.hits} />
                <span className="status-label">Hits</span>
              </div>

              {/* Hits por animal */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="hits_by_animal" end={playerData.hits_by_animal} />
                <span className="status-label">Hits por animal</span>
              </div>

              {/* Hits por arame farpado */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="hits_by_barbedwire" end={playerData.hits_by_barbedwire} />
                <span className="status-label">Hits por areme farpado</span>
              </div>

              {/* Hits por explosão */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="hits_by_explosion" end={playerData.hits_by_explosion} />
                <span className="status-label">Hits por explosão</span>
              </div>

              {/* Hits por queda */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="hits_by_falldamage" end={playerData.hits_by_falldamage} />
                <span className="status-label">Hits por queda</span>
              </div>

              {/* Hits por cerca */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="hits_by_fence" end={playerData.hits_by_fence} />
                <span className="status-label">Hits por cerca</span>
              </div>

              {/* Hits por player */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="hits_by_player" end={playerData.hits_by_player} />
                <span className="status-label">Hits por player</span>
              </div>

              {/* Hits por veiculo */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="hits_by_vechile" end={playerData.hits_by_vechile} />
                <span className="status-label">Hits por veículo</span>
              </div>

              {/* Hits por zumbi */}
              <div className="block col-lg-2 col-md-4 col-5">
                <span className="status-title">Game Info</span>
                <CountValues label="hits_by_zombie" end={playerData.hits_by_zombie} />
                <span className="status-label">Hits por zumbi</span>
              </div>
            </AccordionItemPanel>

            <AccordionItemHeading onClick={() => setHandleExpandText()}>
              <AccordionItemButton>
                <HandleExpandText />
                <AccordionItemState>
                  {({ expanded }) => (expanded ? <i className="lni lni-chevron-up"></i> : <i className="lni lni-chevron-down"></i>)}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
          </AccordionItem>
        </Accordion>
      </div>
     : 
      <div className='alert-warning'>
        <i className="lni lni-warning"></i>
        <strong>{user.gamertag}</strong>, ainda não possuímos informações sobre sua gameplay, jogue e volte aqui para ver suas estatísticas!
      </div>
    }
  </>
  );
};
