import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

export const FaqSection: React.FC = () => {
  return (
    <section id="faq" className="features section">
      <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">
                  FAQ
                </h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  Perguntas Frequentes
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  Respondemos todas as suas perguntas para que você tenha a melhor experiência em nosso servidor.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 wow fadeInUp">
              <Accordion allowZeroExpanded className="accordion">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>Fiz o meu registro na whitelist, já posso jogar?</span>
                      <AccordionItemState>
                        {({ expanded }) => (expanded ? <i className="lni lni-chevron-up"></i> : <i className="lni lni-chevron-down"></i>)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Sim, todo o processo é automatizado! Assim que você aceitar as regras e se registrar na whitelist, a autorização em nosso servidor ocorrerá em um prazo de 2 a 5 minutos. 
                    Se o procedimento levar mais de 30 minutos, por favor, abra um ticket em nosso fórum.</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>

            <div className="col-lg-12 col-md-12 col-12 wow fadeInUp">
              <Accordion allowZeroExpanded className="accordion">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>Porque não estou mais na whitelist quando mudou de temporada?</span>
                      <AccordionItemState>
                        {({ expanded }) => (expanded ? <i className="lni lni-chevron-up"></i> : <i className="lni lni-chevron-down"></i>)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Ao finalizar uma temporada, todo o progresso no jogo é reiniciado, o que inclui a whitelist e a banlist do servidor. 
                    Para o início de cada nova temporada, é necessário que cada jogador realize o registro novamente na whitelist.</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>

            <div className="col-lg-12 col-md-12 col-12 wow fadeInUp">
              <Accordion allowZeroExpanded className="accordion">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>Minha base sofreu RAID, e agora?</span>
                      <AccordionItemState>
                        {({ expanded }) => (expanded ? <i className="lni lni-chevron-up"></i> : <i className="lni lni-chevron-down"></i>)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Se a sua base foi alvo de um ataque enquanto você estava desconectado do servidor e fora do horário/dia do <strong>The Purge</strong>, por favor, abra um ticket em nosso fórum para solicitar uma análise. 
                      Certifique-se de utilizar o seguinte template no seu ticket: <a href="https://forum.deadrisingbrasil.com/d/14-report-de-raid-como-reportar-uma-raid-em-sua-base">[Report de Raid] Como reportar um ataque à sua base.</a></p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>

            <div className="col-lg-12 col-md-12 col-12 wow fadeInUp">
              <Accordion allowZeroExpanded className="accordion">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>É permitido PVP no servidor?</span>
                      <AccordionItemState>
                        {({ expanded }) => (expanded ? <i className="lni lni-chevron-up"></i> : <i className="lni lni-chevron-down"></i>)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>SIM! No servidor, todas as estratégias são permitidas para assegurar sua sobrevivência: desde conflitos para capturar ou proteger territórios e 
                      bases até ações para garantir sua segurança ou adquirir saque. Qualquer forma de combate jogador-contra-jogador (PvP) é aceitável.</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
      </div>
    </section>
  )
}