import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface CountProps {
  count: number;
  height?: number;
  width?: number;
  baseColor?: string;
  customHighlightBackground?: string;
  style?: React.CSSProperties;
}

export const SkeletonLoading: React.FC<CountProps> = ({ 
  count = 1, 
  height, 
  width,
  baseColor, 
  customHighlightBackground,
  style
}: CountProps)  => {
  return (
    <Skeleton 
      style={style}
      count={count}
      height={height}
      width={width}
      customHighlightBackground={customHighlightBackground}
      baseColor={baseColor}
      className="skeleton-loading"
    />
  );
};
