import * as AlertDialog from '@radix-ui/react-alert-dialog'
import React, { useEffect, useState } from "react"
import Countdown from 'react-countdown'
import Select from 'react-select'
import Slider from "react-slick"
import { toast } from "react-toastify"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import Api from '../../services/Api'
import { useGetUser } from "../../store/hooks/UserHook"
import { Coin } from "../coins/coin"
import { SelectSource } from "../coins/selectSource"
import { NoData } from '../structure/noData'

export const Airdrop: React.FC = () => {
  const user = useGetUser()
  const [isHideReserved, setIsHideReserved] = useState<IsReservedState>({})
  const [isReserved, setIsReserved] = useState<IsReservedState>({})
  const [isSelected, setIsSelected] = useState(false)
  const [isLoadingAirdop, setIsLoadingAirdrop] = useState(false)
  const [airdrop, setAirdrop] = useState<any>([])
  const [typeSelected, setTypeSelected] = useState('')
  const [pageLoading, setPageLoading] = useState(true)
  const [windowStatus, setWindowStatus] = useState(false)
  const [windowDate, setWindowDate] = useState('')
  const [sourceSelected, setSourceSelected] = useState('coins')
  const [isTransfer, setIsTransfer] = useState(false)

  type IsReservedState = { [key: string]: boolean }

  const getAirdrop = async (type: string) => {
    setIsLoadingAirdrop(true)
    try {
      const config = {
        headers: {
          type: type,
        }
      }

      const response = await Api.get('airdrop/options', config)
      setAirdrop(response.data)
      setIsLoadingAirdrop(false)

    } catch (error) {
      setIsLoadingAirdrop(false)
      toast.error(
        <div>OPS! Não foi possível recuperar os Airdrop.</div>
        , {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }

  const getAirdropReserved = async (id: number) => {
    const newIsHideReserved = { ...isReserved }
    newIsHideReserved[id] = true
    setIsReserved(newIsHideReserved)

    try {
      const config = {
        headers: {
          gamertag: user.gamertag,
          email: user.email,
          type: typeSelected,
          id: id,
          source: sourceSelected
        }
      }

      const response = await Api.post('airdrop/buy', {}, config)
      if(response.data.success) {
        const newIsReserved = { ...isHideReserved }
        newIsReserved[id] = true
        setIsHideReserved(newIsReserved)
        setIsTransfer(true)

        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => setIsTransfer(false)
        })
      } else {
        newIsHideReserved[id] = false
        setIsReserved(newIsHideReserved)

        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    } catch (error) {
      newIsHideReserved[id] = false
      setIsReserved(newIsHideReserved)

      toast.error(
        <div>OPS! Não foi possível reservar o Airdrop.</div>
        , {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }

  useEffect(() => {
    const getAirdropConfig = async () => {
      try {
        const response = await Api.get('airdrop/check-window', {})
        setWindowStatus(response.data.success)
        setWindowDate(response.data.date)
        setPageLoading(false)

      } catch (error) {
        setPageLoading(false)
        toast.error('Algo deu errado, entre em contato com a administração!', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    }

    getAirdropConfig()
  }, [])

  const options = [
    { value: 'weapon', label: 'Armas (DRB$ 40,00)' },
    { value: 'build', label: 'Construção (DRB$ 60,00)' }
  ]

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    pauseOnHover: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Suprimento Aéreo</h2>
          <p>Solicite suprimento aéreo e resgaste no ponto de extração. As entregas são realizadas em janelas específicas.</p>

          {pageLoading ? 
            <div className="spinner page">
              <span className="loader"></span>
            </div>
            :
            <>
              {windowStatus ? 
                <Countdown 
                  date={windowDate}
                  autoStart={true}
                  renderer={({ hours, minutes, seconds, completed }: any) => {
                    if(completed) {
                      return (
                        <div className='alert-warning wow fadeInUp'>
                          <i className="lni lni-warning"></i>
                          <strong>{user.gamertag}</strong>, o intervalo para solicitação do airdrop está fechado! Aguarde a próxima janela.
                        </div>
                      )
                    } else {
                      const zeroHours = `0${hours}`
                      const zeroMinutes = `0${minutes}`
                      const zeroSeconds = `0${seconds}`

                      return (
                        <>
                          <Slider 
                            className="slider-window-request col-lg-3 col-md-5 col-sm-12 offset-lg-9 offset-md-7 wow fadeInUp"
                            {...settings}
                          >
                            <div className="window-request">
                              <div className="label">
                                <i className="lni lni-plane icon"></i>
                                Intervalo de solicitação
                              </div>
                              <div className="timer">
                                <p>O intervalo para solicitação do airdrop encerrará em:
                                <strong>{hours > 9 ? hours : zeroHours}:{minutes > 9 ? minutes : zeroMinutes}:{seconds > 9 ? seconds : zeroSeconds}</strong></p>
                              </div>
                            </div>

                            <Coin reload={isTransfer}/>
                          </Slider>

                          <SelectSource setSourceSelected={setSourceSelected} sourceSelected={sourceSelected}/>

                          <Select
                            placeholder={'Selecione o tipo de airdrop'}
                            options={options}
                            noOptionsMessage={() => "Nenhum Airdop foi encontrado"}
                            onChange={(event: any) => {
                              if(typeSelected !== event.value) {
                                getAirdrop(event.value)
                                setIsSelected(true)
                                setTypeSelected(event.value)
                              }
                            }}
                          />
                          {isSelected ? 
                            isLoadingAirdop ? 
                              <div className="spinner page">
                                <span className="loader"></span>
                              </div>
                              :
                              airdrop.success && Object.keys(airdrop.options).length > 0 ? (
                                <>
                                  <h5>Reserve a área de extração</h5>
                                  <p>Escolha a área de extração que mais te agrada e reserve a entrega do suprimento. Ao reiniciar o servidor, esteja estrategicamente 
                                    posicionado no local escolhido para recuperar o suprimento. No entanto, mantenha-se vigilante, pois outros sobreviventes podem já estar 
                                    cientes da sua requisição.</p>
                                  <p>Esteja atento à possibilidade de encontros inesperados durante essa missão, afinal, a competição pela sobrevivência 
                                    adiciona um toque de imprevisibilidade ao jogo.</p>
                                  <p>Para conhecer mais sobre cada Aidrop, <strong><a href="https://forum.deadrisingbrasil.com/d/117-airdrop" target="_blank" rel="noreferrer">clique aqui</a></strong> e leia o nosso post.</p>
                                  <div className="card-container">
                                    {airdrop.options.map((card: any, index: number) => (
                                      <div className="card wow fadeInUp" key={index} style={{display: isHideReserved[card.id] ? 'none' : 'initial'}}>
                                        <div className="cover" style={{backgroundImage: `url(${card.image_url})`}}></div>
                                        <div className="city-name">{card.city_name}</div>
                                        <p>Coordenada para extração: <br />
                                          <a href={`https://dayz.xam.nu/#location=${card.coordinates.replace(' / ', ';')};7`} target="_blank" rel="noreferrer" >
                                            <i className="lni lni-map-marker"></i> {card.coordinates}
                                          </a>
                                        </p>
                                        {isReserved[card.id] ? 
                                          <button className='btn btn-primary'>
                                            <div className="spinner">
                                              <span className="loader"></span>
                                            </div>
                                          </button>
                                          :
                                          <AlertDialog.Root>
                                            <AlertDialog.Trigger asChild>
                                              <button className='btn btn-primary'>Reservar</button>
                                            </AlertDialog.Trigger>
                                            <AlertDialog.Portal>
                                              <AlertDialog.Overlay className="alert-dialog-overlay" />
                                              <AlertDialog.Content className="alert-dialog-content">
                                                <AlertDialog.Title className="alert-dialog-title">Você tem certeza disso?</AlertDialog.Title>
                                                <AlertDialog.Description className="alert-dialog-description">
                                                  Esta ação irá reservar o airdrop de <strong>{card.city_name}</strong>. Deseja continuar?
                                                </AlertDialog.Description>
                                                <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
                                                  <AlertDialog.Cancel asChild>
                                                    <button className="alert-cancel-button">Cancelar</button>
                                                  </AlertDialog.Cancel>
                                                  <AlertDialog.Action asChild>
                                                    <button className="alert-action-button success" onClick={() => getAirdropReserved(card.id)}>Sim, reservar</button>
                                                  </AlertDialog.Action>
                                                </div>
                                              </AlertDialog.Content>
                                            </AlertDialog.Portal>
                                          </AlertDialog.Root>
                                        }
                                        
                                      </div>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <div className="card-container">
                                  <NoData 
                                    description='todos pontos de extração já foram reservados'
                                  />
                                </div>
                              )
                          : <></>}
                        </>
                      )
                    }
                  }}
                /> 
                :
                  <NoData 
                    description='o intervalo para solicitação do airdrop está fechado! Aguarde a próxima janela'
                  />
                }
            </>
          }
        </div>
      </div>
    </div>
  )
}