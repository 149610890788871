export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/worker.js')
        .then(() => {
          navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data?.type === 'OFFLINE') {
              window.dispatchEvent(new CustomEvent('offlineNotification'));
            }
          });
        })
        .catch((error) => {
          console.error('Erro ao registrar o Service Worker:', error);
        });
    });
  }
}