import React, { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import Api from "../../services/Api";
import { useGetUser } from '../../store/hooks/UserHook';
import { NoData } from "../structure/noData";

export const BaseLogs: React.FC = () => {
  const user = useGetUser()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [logsData, setLogsData] = useState(Object)

  useEffect(() => {
    const getData = async () => {
      const config = {
        headers: {
          gamertag: `${user.gamertag}`
        }
      }

      try {
        const response = await Api.get('base-alert/get-logs', config)
        if(Object.keys(logsData).length === 0 && isLoading) {
          setIsLoading(false)
          const items = response.data.logs.map((log: { 
              date_log: string; 
              owner_base: string; 
              isBaseSecutiry: boolean;
              gamertag: string; 
              distance: string; 
              x: string, 
              y: string, 
              z: string}, idx: number) => {
            const BuildLodText = () => {
              if(!!+log.isBaseSecutiry) {
                return (
                  <>
                    <strong>{log.owner_base}</strong>, o sobrevivente <strong>{log.gamertag}</strong> foi suspenso pelo <strong>Security BOT</strong>. O sobrevivente foi detectado à <strong>{log.distance}</strong> de sua base<br />
                  </>
                )
              } else {
                return (
                  <>
                    <strong>{log.owner_base}</strong>, o sobrevivente <strong>{log.gamertag}</strong> foi detectado à <strong>{log.distance}</strong> de sua base. <br />
                  </>
                )
              }
            }

            return {
              title: `Log ${idx + 1}`,
              cardTitle: 'Ver Localização',
              cardSubtitle: <>Data do Log: <span>{log.date_log}</span></>,
              cardDetailedText: <BuildLodText />,
              url: `https://dayz.xam.nu/#location=${log.x};${log.y};7`,
            };
          });
          setLogsData(items)
        }
      } catch (err) {
        setIsLoading(false)
        setIsError(true)
      }
    }
    
    getData()
  }, [])

  if (isLoading) {
    return (
      <>
        <div className="section-title">
          <h3>Sua Base</h3>
          <h2>Logs</h2>
          <p>Os logs são gerados conforme sobreviventes são detectados próximos a sua base, limitando a 30 logs!</p>
        </div>
        <div className='wow fadeInRight'>
          <div className="spinner">
            <span className="loader"></span>
          </div>
        </div>
      </>
    )
  }

  if(isError) {
    return (
      <div className='wow fadeInUp'>
        <section className="section">
          <div className='container'>
            <div className="row">
              <div className="section-title">
                <h3>Error</h3>
                <h2>OPS! :(</h2>
                <p>Tivemos problemas na hora de obter os logs, tente novamente mais tarde!</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Logs</h2>
          <p>Os logs são gerados conforme sobreviventes são detectados próximos a sua base, limitando a 30 logs! Os logs não são gerados em tempo real, garantindo a sua segurança!</p>
          <div className="log-base-alert">
          {Object.keys(logsData).length > 0 ?
              <Chrono 
                items={logsData} 
                mode="VERTICAL_ALTERNATING" 
                scrollable={{ scrollbar: false }} 
                hideControls={true}
                cardHeight={100}
                useReadMore={false}
                theme={{
                  primary: '#7b4397',
                  secondary: '#7b4397',
                  cardBgColor: '#FFF',
                  titleColor: '#7b4397',
                  titleColorActive: '#FFF',
                }}
              />
            : 
              <NoData 
                description='Ainda não detectamos sobreviventes próximo a sua base! Assim que detectarmos as infomações serão disponibilizadas'
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
