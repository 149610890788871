import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setUser } from "../../store/ducks/DataDuck"
import {
  setAppKey,
  setAvatar,
  setCode,
  setEmail,
  setGamertag,
  setXuid
} from "../../store/ducks/UserDuck"
import { useGetApp } from "../../store/hooks/AppHook"
import { useGetUser } from "../../store/hooks/UserHook"

export interface MenuMobileStatus {
  buttonStatus: Function
  menuStatus: Function
}

export const UserSection: React.FC<MenuMobileStatus> = ({ buttonStatus, menuStatus }) => {
  const dispatch = useDispatch()
  const user = useGetUser()
  const app = useGetApp()
  const [userSessionState, setUserSessionState] = useState(false)

  const logout = (event: any) => {
    event.preventDefault()
    dispatch(setUser(false))
    dispatch(setAvatar(""));
    dispatch(setGamertag(""))
    dispatch(setXuid(""))
    dispatch(setCode(""))
    dispatch(setEmail(""))
    dispatch(setAppKey(""))

    buttonStatus(false)
    menuStatus(false)
  }

  useEffect(() => {
    setUserSessionState(true);
  }, [dispatch, user.gamertag, app.whitelist.modalIsOpen, buttonStatus, menuStatus])

  if (!userSessionState) {
    return (
      <>
        <div className="user-section wow fadeInRight">
          <div className="content spinner">
            <span className="loader"></span>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="user-section">
          <div className="content">
            <div
              className="avatar"
              style={{
                backgroundImage: `url(${user.avatar})`,
              }}
            ></div>
            <span className="gamer-tag">
              <span className="label">Gamertag</span>
              {user.gamertag}
            </span>
          </div>
          <div className="menu">
            <ul>
              <li>
                <a href="/dashboard">
                  Dashboard
                </a>
              </li>
              <li>
                <a href={`/survivor/profile/${user.xuid}`}>
                  Meu perfil
                </a>
              </li>
              <li>
                <a href="https://forum.deadrisingbrasil.com">
                  Forum
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/dashboard/remove-account">
                  Remover conta
                </a>
              </li>
              <li>
                <a href="/" onClick={(event) => logout(event)}>
                  Sair
                </a>
              </li>
            </ul>
          </div>
        </div>
      </>
    )
  }
}

export default UserSection;
