import React, { useState } from "react";
import Countdown from 'react-countdown';
import { useGetStatus } from '../../store/hooks/StatusHook';

export const Season: React.FC = () => {
  const serverStatus = useGetStatus()
  const [isCompleted, setIsCompleted] = useState(false)

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      setIsCompleted(true)

      return (
        <div className="col-lg-12 col-md-12 col-12">
          <div
            className="single-achievement no-margin wow fadeInUp"
            data-wow-delay=".6s"
          >
            <h3>
              <span>
                A temporada chegou ao fim!
              </span>
            </h3>
            <p>O wipe acontecerá em breve.</p>
          </div>
        </div>
      )
    } else {
      return (
        <>
          <div className="col-lg-3 col-md-3 col-12">
            <div
              className="single-achievement wow fadeInUp"
              data-wow-delay=".6s"
            >
              <h3 className="counter">
                <span>
                  {days}
                </span>
              </h3>
              <p>Dias</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-12">
            <div
              className="single-achievement wow fadeInUp"
              data-wow-delay=".6s"
            >
              <h3 className="counter">
                <span>
                  {hours}
                </span>
              </h3>
              <p>horas</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-12">
            <div
              className="single-achievement wow fadeInUp"
              data-wow-delay=".6s"
            >
              <h3 className="counter">
                <span>
                  {minutes}
                </span>
              </h3>
              <p>Minutos</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-12">
            <div
              className="single-achievement wow fadeInUp"
              data-wow-delay=".6s"
            >
              <h3 className="counter">
                <span>
                  {seconds}
                </span>
              </h3>
              <p>Segundos</p>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <section id="temporada" className="our-achievement section">
      <div className="container">
        {!isCompleted &&
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
              <div className="title">
                <h2>Temporada</h2>
                <p>O fim da temporada acontencerá em:</p>
              </div>
            </div>
          </div>
        }
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
            <div className="row">
              {
                <Countdown 
                  date={`${serverStatus.config.seasonEnd}`}
                  autoStart={true}
                  renderer={renderer}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
