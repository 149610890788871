import React from 'react';
import { NoData } from '../../structure/noData';

interface FactionRivalModuleParams {
  data: any[]
  cancelRival?: boolean
}

export const FactionRivalModule: React.FC<FactionRivalModuleParams> = ({ data, cancelRival }) => {  
  return (
    <div className="col-12">
      <div className="card">
        <div className="header">
          <h4 className="title"><i className="lni lni-shield"></i> Facções Rivais</h4>
        </div>
        <div className='description'>
          <p>Essas facções estão em constante conflito, competindo por território e poder. A rivalidade entre elas define o equilíbrio de forças no campo de batalha.</p>
        </div>
        <div className="body">
          {Array.isArray(data) && data.length > 0 &&
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Facções</th>
                    {cancelRival && <th>Ações</th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((faction, index) => (
                    <tr key={index}>
                      <td><img src={faction.logo_image} className='profile-image' alt="Faction" /><a href={`/faction/profile/${faction.id_rival}`} title={faction.allied_name}>{faction.rival_name}</a></td>
                      {cancelRival && <td><button>Cancelar Rivalidade</button></td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }

          {Array.isArray(data) && data.length === 0 && (
            <NoData
              showGamerTag={false}
              description='Esta facção ainda não possui rivalidades'
            />  
          )}
        </div>
      </div>
    </div>
  )
}