import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from 'react-hook-form';
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import Select from 'react-select';
import { toast } from 'react-toastify';
import api from "../../services/Api";
import { useGetStatus } from '../../store/hooks/StatusHook';
import { useGetUser } from '../../store/hooks/UserHook';
import { Coin } from "../coins/coin";
import { SelectSource } from "../coins/selectSource";

export interface QueueJumpBuyProps {
  reloadCoins: Function
}

export const QueueJumpBuy: React.FC<QueueJumpBuyProps> = ({ reloadCoins }) => {
  type FormData = {
    selectOption: String;
  }
  const user = useGetUser()
  const status = useGetStatus()
  const {register, handleSubmit, formState: { errors }, setValue} = useForm<FormData>()
  const onSubmit = handleSubmit(() => setQueue())
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [queueTimeValue, setqueueTimeValue] = useState(String)
  const [reloadCard, setReloadCard] = useState(false)
  const [sourceSelected, setSourceSelected] = useState('coins')
  const [queueCardStatus, seQueueCardStatus] = useState(
    <div className="block feature not-clickble col-lg-2 col-md-4 col-12 wow fadeInUp" data-wow-delay=".6s">
      <i className="lni lni-timer"></i>
      <div className='load-cover'>
        <div className="spinner">
          <span className="loader"></span>
        </div>
      </div>
    </div>
  )

  const setQueue = async () => {
    setIsLoading(true)
    const enableFeature = {
      headers: {
        gamertag: `${user.gamertag}`,
        xuid: `${user.xuid}`,
        email: `${user.email}`,
        queue: queueTimeValue,
        source: sourceSelected
      }
    }

    try {
      const response = await api.post('queue/buy', {}, enableFeature)
      if(response.data.success) {
        reloadCoins(true)
        setReloadCard(true)
        closeModal()
        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      } else {
        setIsLoading(false)
        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    }
    catch (err) {
      setIsLoading(false)
      toast.error(
        <div>
          <strong>{user.gamertag}</strong>, algo deu errado, entre em contato com a administração!
        </div>
        , {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  useEffect(() => {
    const getQueueStatus = async () => {
      try {
        const headerConfig = {
          headers: {
            gamertag: `${user.gamertag}`,
          }
        }

        const responseQueueJump = await api.get('queue/list', headerConfig)
        if (Object.keys(responseQueueJump.data).length > 0) {
          if (responseQueueJump.data[0].gamertag === user.gamertag) {
            const queueDurationToTime: Record<string, string> = {
              3600 : '1 hora',
              7200 : '2 horas',
              10800 : '3 horas',
              14400 : '4 horas',
              18000 : '5 horas',
              86400 : '1 dia',
              172800 : '2 dias',
              259200 : '3 dias',
              604800 : '1 semana',
              1209600 : '2 semanas',
              2592000 : '1 mês',
              5184000 : '2 meses',
              7776000 : '3 meses',
              15774912 : 'definitivo (até o fim da temporada)',
            };

            seQueueCardStatus(
              <div className="block feature not-clickble col-lg-2 col-md-4 col-12 wow fadeInUp" data-wow-delay=".6s">
                <i className="lni lni-timer"></i>
                <strong>Shop</strong>
                Fura Fila
                <span>Você já tem um fura-fila válido por <strong>{queueDurationToTime[responseQueueJump.data[0].queue_duration]}.</strong></span>
              </div>
            )
          }
        }
        else {
          seQueueCardStatus(
            <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" data-wow-delay=".6s" onClick={(event) => openModal(event)}>
              <i className="lni lni-timer"></i>
              <strong>Shop</strong>
              Fura Fila
              <span>Assine com DRB$ tenha prioridades na fila de espera</span>
            </div>
          )
        }
      } catch (err) {
        toast.error('Ops! Algo deu errado ao tentar validar seu Fura-Fila.', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    }

    getQueueStatus()
  }, [reloadCard])

  const openModal = (event: any) => {
    event.preventDefault()
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
    setIsLoading(false)
  }

  const dropDownOptinos = [
    { value: '1hora', label: '1 hora (DRB$ 5,00)' },
    { value: '2horas', label: '2 horas (DRB$ 7,00)' },
    { value: '3horas', label: '3 horas (DRB$ 9,00)' },
    { value: '4horas', label: '4 horas (DRB$ 11,00)' },
    { value: '5horas', label: '5 horas (DRB$ 13,00)' },
    { value: '1dia', label: '1 dia (DRB$ 20,00)' },
    { value: '2dias', label: '2 dias (DRB$ 30,00)' },
    { value: '3dias', label: '3 dias (DRB$ 40,00)' },
    { value: '1semana', label: '1 semana (DRB$ 60,00)' },
    { value: '2semanas', label: '2 semanas (DRB$ 80,00)' },
    { value: '1mes', label: '1 mês (DRB$ 100,00)' },
    { value: '2meses', label: '2 meses (DRB$ 150,00)' },
    { value: '3meses', label: '3 meses (DRB$ 200,00)' },
    { value: 'Temp', label: `Toda temporada ${status.config.seasonNumber} (DRB$ 300,00)` },
  ]
  

  return (
    <>
      {queueCardStatus}

      <PureModal
        header="Pagamento"
        scrollable={false}
        className={"dialog pix"}
        isOpen={modalIsOpen}
        onClose={() => closeModal()}
      >
        <div className="form-buy-content">
          {isLoading ?
            <div className='load-cover'>
              <div className="spinner">
                <span className="loader"></span>
              </div>
            </div>
            :
            <></>}
          <p><strong>{user.gamertag}</strong>, ao se conectar ao servidor e se deparar com uma fila de espera, garantimos a você prioridade nessa fila enquanto vigorar o seu status de Fura-Fila!</p><br />

          <form onSubmit={onSubmit}>
            <ul className="error">
              {errors.selectOption && <li><i className="lni lni-warning"></i> O campo Tempo de Fura-Fila é obrigatório.</li>}
            </ul>
            
            <SelectSource setSourceSelected={setSourceSelected} sourceSelected={sourceSelected}/>

            <Select
              placeholder={'Selecione o tempo de Fura-Fila'}
              options={dropDownOptinos}
              {...register('selectOption', { required: true })}
              noOptionsMessage={() => "Nenhum Fura-Fila foi encontrado"}
              onChange={(event: any) => {
                setqueueTimeValue(event?.value ?? '1hora')
                setValue('selectOption', event?.value ?? '')
              }}
            />
            <input type="submit" value={"Comprar"} className={isLoading ? 'disabled' : ''} />
            {
              isLoading ?
              <div className='load-cover'>
                <div className="spinner">
                  <span className="loader"></span>
                </div>
              </div>
              :
              <></>
            }
          </form>

          {isMobile ? 
            <div className="col-lg-3 col-md-5 col-sm-12 offset-lg-9 offset-md-7">
              <Coin />
            </div>
            : 
            <></>
          }
          
        </div>
      </PureModal>
    </>
  );
};
