import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from 'react-hook-form';
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import api from "../../services/Api";
import { useGetUser } from '../../store/hooks/UserHook';
import { Coin } from "./coin";

export interface CoinBuyProps {
  reloadCoins: Function
}

export const CoinBuy: React.FC<CoinBuyProps> = ({ reloadCoins }) => {
  type FormData = {
    selectOption: String;
  }

  const navigate = useNavigate();
  const user = useGetUser()
  const {register, handleSubmit, formState: { errors }, setValue} = useForm<FormData>()
  const onSubmit = handleSubmit(data => createOrderPayment(data))
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [coinsQuantity, setCoinsQuantity] = useState(String)

  const createOrderPayment = async (data: any) => {
    setIsLoading(true)

    const configBaseAlertBuy = {
      headers: {
        gamertag: `${user.gamertag}`,
        email: `${user.email}`,
        amount: coinsQuantity,
      }
    }
    try {
      const response = await api.post('payment/buy', {}, configBaseAlertBuy)
      const { success, message, invoice } = response.data
      if(success) {
        navigate(`/dashboard/invoices?invoiceId=${invoice.id}`)
      } else {
        toast.error(`${message}`, {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    } catch (err) {
      setIsLoading(false)
      toast.error('Algo deu errado, entre em contato com a administração!', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }

  const openModal = (event: any) => {
    event.preventDefault()
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
    setIsLoading(false)
    setCoinsQuantity('')
  }

  const dropDownOptinos = [
    { value: '5',   label: 'DRB$ 5,00 (R$ 2,50)' },
    { value: '10',  label: 'DRB$ 10,00 (R$ 5,00)' },
    { value: '15',  label: 'DRB$ 15,00 (R$ 7,50)' },
    { value: '20',  label: 'DRB$ 20,00 (R$ 10,00)' },
    { value: '30',  label: 'DRB$ 30,00 (R$ 15,00)' },
    { value: '50',  label: 'DRB$ 50,00 (R$ 25,00)' },
    { value: '100', label: 'DRB$ 100,00 (R$ 50,00)' },
    { value: '200', label: 'DRB$ 200,00 (R$ 100,00)' },
    { value: '500', label: 'DRB$ 500,00 (R$ 250,00)' },
  ]

  return (
    <>
      <div className="block feature col-lg-2 col-md-4 col-12 wow fadeInUp" data-wow-delay=".2s" onClick={(event) => openModal(event)}>
        <i className="lni lni-coin"></i>
        <strong>Shop</strong>
        Comprar DRB$
        <span>Compre DRB$ e amplie seus horizontes</span>
      </div>

      <PureModal
        header="Pagamento"
        scrollable={false}
        className={"dialog pix"}
        isOpen={modalIsOpen}
        onClose={() => closeModal()}
      >
        <div className="form-buy-content">
          <p><strong>{user.gamertag}</strong>, que tal turbinar sua fortuna no nosso servidor com mais DRB$? Com elas, você pode desbloquear serviços exclusivos e muito mais!</p><br />
          <p>Pagar é super fácil: só aceitamos PIX. Escolha a quantidade de DRB$ que deseja, e gere sua fatura. Você terá 35 minutos para realizar o pagamento. 
            Se esse prazo for ultrapassado sem pagamento, a fatura será cancelada automaticamente.</p><br />

          <form onSubmit={onSubmit}>
            <ul className="error">
              {errors.selectOption && <li><i className="lni lni-warning"></i> A quantidade de DRB$ é obrigatório.</li>}
            </ul>
            <Select
              placeholder={'Selecione a quantidade de DRB$'}
              options={dropDownOptinos}
              {...register('selectOption', { required: true })}
              noOptionsMessage={() => "Valor em DRB$ inválido"}
              onChange={(event: any) => {
                setCoinsQuantity(event?.value ?? '5')
                setValue('selectOption', event?.value ?? '')
              }}
            />
            <input type="submit" value={"Comprar"} className={isLoading ? 'disabled' : ''} />
            {
              isLoading ?
              <div className='load-cover'>
                <div className="spinner">
                  <span className="loader"></span>
                </div>
              </div>
              :
              <></>
            }
          </form>

          {isMobile ? 
            <div className="col-lg-3 col-md-5 col-sm-12 offset-lg-9 offset-md-7">
              <Coin />
            </div>
            : 
            <></>
          }
        </div>
      </PureModal>
    </>
  );
};
