import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Api from '../../../services/Api';
import { NoData } from '../../structure/noData';

interface FactionMessagesModuleParams {
  factionId: string | undefined
  isPrivate: boolean
}

export const FactionMessagesModule: React.FC<FactionMessagesModuleParams> = ({ factionId, isPrivate }) => {
  const [messages, setMessages] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loadMessages(currentPage);
  }, [currentPage]);

  const loadMessages = async (page: number) => {
    setIsLoading(true);

    try {
      const config = {
        headers: {
          id_faction: factionId,
          is_private: isPrivate,
          page: page,
        },
      };

      const response = await Api.get(`faction/messages/list?page=${currentPage}&per_page=5`, config);
      const { messages, totalPages } = response.data;

      setMessages(messages);
      setTotalPages(totalPages);
    } catch (error) {
      toast.error('Ops! Não foi possivel obter as mensagens da facção.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }

    setIsLoading(false);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="header">
          <h4 className="title"><i className="lni lni-popup"></i> Posts</h4>
        </div>
        <div className="body no-bg">
          {isLoading ? (
            <div className="spinner page">
              <span className="loader"></span>
            </div>
          ) : (
            <>
              {Array.isArray(messages) && messages.length > 0 ? (
                <>
                  {messages.map((msg, index) => (
                    <div key={index} className="message">
                      <div className="message-header">
                        <span className="gamertag">{msg.gamertag}</span> 
                        <span className="date">{msg.date}</span>
                      </div>
                      <div className="message-body">
                        {msg.message}
                      </div>
                    </div>
                  ))}

                  <div className='pagination'>
                    <button className='btn btn-primary' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                      Anterior
                    </button>
                    <span>Página {currentPage} de {totalPages}</span>
                    <button className='btn btn-primary' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                      Próxima
                    </button>
                  </div>
                </>
              ) : (
                <NoData
                  showGamerTag={false}
                  description='Nenhuma mensagem foi encontrada para esta facção'
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};