import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Login } from '../components/icons/login.svg'
import { UserState } from '../interfaces'
import api from '../services/Api'
import { setUser } from '../store/ducks/DataDuck'
import {
  setAppKey,
  setAvatar,
  setCode,
  setEmail,
  setGamertag,
  setXuid
} from '../store/ducks/UserDuck'

const UserHandler: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handlerStatus = useRef(false)

  useEffect(() => {
    const getCode = (param: string) => {
      let search = window.location.search
      let params = new URLSearchParams(search)
      return params.get(param)
    }

    const deleteCode = (param: string) => {
      window.history.replaceState({}, document.title, "/")
    }

    const setUserSession = (response: UserState, code: any) => {
      dispatch(setAvatar(response.avatar))
      dispatch(setGamertag(response.gamertag))
      dispatch(setXuid(response.xuid))
      dispatch(setEmail(response.email))
      dispatch(setAppKey(response.appKey))
      dispatch(setCode(code))
      handlerStatus.current = true
      deleteCode('code')
    }

    const getData = async () => {
      try {
        const response = await api.get(`get-session?code=${getCode('code')}`)
        dispatch(setUser(response.data.data.user))
        setUserSession(response.data.user[0], getCode('code'))
        if (handlerStatus.current) {
          navigate('/dashboard')
        }
      }
      catch (err) {
        handlerStatus.current = true
      }
    }
    
    if(getCode('code')) {
      getData()
    }
    else {
      navigate('/')
    }
  }, [dispatch, navigate])

  return (
    <div className='interfaces-page wow fadeInUp'>
      <div className="container">
        <div className='icon'>
          <Login />
        </div>
        <p className='text'><span className='wow fadeInUp'>Realizando o login através da Xbox Live.</span></p>
      </div>
    </div>
  )
}

export default UserHandler