import React from "react"
import { useGetUser } from "../../store/hooks/UserHook"
import { NoData as NoDataIcon } from "../icons/no-data.svg"

interface NoDataProps {
  description: string
  showGamerTag?: boolean
}

export const NoData: React.FC<NoDataProps> = ({ description, showGamerTag = true }: NoDataProps) => {
  const user = useGetUser()
  
  return(
    <div className="no-data">
      <NoDataIcon />
      {
        showGamerTag ?
        <p><strong>{user.gamertag}</strong>, {description}.</p>
        :
        <p>{description}.</p>
      }
    </div>
  )
}