import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BaseAlertBuy, BaseAlert as BaseAlertComponent, Breadcrumb, Footer, Header, ServerData } from '../../components'
import Api from "../../services/Api"
import { useGetUser } from '../../store/hooks/UserHook'

const BaseAlert: React.FC = () => {
  const user = useGetUser()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [hasAccess, setHasAccess] = useState(false)
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  useEffect(() => {
    if (!user.appKey) {
      navigate('/index')
    } else {
      const getData = async () => {
        const config = {
          headers: {
            gamertag: `${user.gamertag}`
          }
        }
  
        try {
          const response = await Api.get('base-alert/get-access', config)
          if (Object.keys(response.data).length > 0) {
            if (response.data[0].gamertag === user.gamertag) {
              setHasAccess(true)
            }
          }
        } catch (err) {
          setIsLoading(false)
        }
      }

      getData().then(() => setIsLoading(false))
    }
  }, [user.appKey])

  const breadCrumb = [
    { name: "Dashboard", url: "/dashboard" },
    { name: "Base Alert", url: "/dashboard/base-alert" },
  ]

  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''}/>
      <div className='base-alert'>
        <Breadcrumb items={breadCrumb} />
        {isLoading ? 
          <div className='wow fadeInRight'>
            <div className="spinner">
              <span className="loader"></span>
            </div>
          </div> :
          <>
            {hasAccess ? <BaseAlertComponent /> : <BaseAlertBuy />}
          </>
        }
      </div>
      <Footer />
    </>
  )
}

export default BaseAlert