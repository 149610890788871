import ProgressBar from '@ramonak/react-progress-bar';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AlertDialog } from '../..';
import Api from '../../../services/Api';
import { useGetUser } from '../../../store/hooks/UserHook';

interface FactionProfileModuleParams {
  idFaction: string | undefined,
  data: any,
  validators: {
    belongsTheFaction: boolean,
    belongsOtherFaction: boolean,
    belongsRequestTheFaction: boolean,
    belongsRequestOtherFaction: boolean,
    role: string
  },
  setReloadGetData: Dispatch<SetStateAction<boolean>>
}

export const FactionProfileModule: React.FC<FactionProfileModuleParams> = ({ 
  idFaction, 
  data,
  validators,
  setReloadGetData
 }) => {
  const navigate = useNavigate()
  const user = useGetUser()
  const maxLength = 140;
  
  // States for Delete Faction actions
  const [deleteLoading, setDeleteLoading] = useState(false)
  const deleteFaction = async () => {
    setDeleteLoading(true)

    try {
      const config = {
        headers: {
          id_faction: idFaction,
          gamertag: user.gamertag
        }
      }

      const response = await Api.post('faction/delete', {}, config)
      const {success, message} = response.data

      if(success) {
        toast.success(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {
            navigate('/faction')
          }
        })
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
      setDeleteLoading(false)
    } catch (err) {
      setDeleteLoading(false)
      toast.error('Ops! Não foi possivel deletar a facção.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  // States for join Faction
  const [requestJoinFactionLoading, setRequestJoinFactionLoading] = useState(false)
  const requestJoinFaction = async () => {
    setRequestJoinFactionLoading(true)

    try {
      const config = {
        headers: {
          id_faction: idFaction,
          gamertag: user.gamertag
        }
      }

      const response = await Api.post('faction/player/request', {}, config)
      const {success, message} = response.data

      if(success) {
        setReloadGetData(true)
        toast.success(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }

      setRequestJoinFactionLoading(false)
    } catch (err) {
      setRequestJoinFactionLoading(false)
      toast.error('Ops! Não foi possivel solicitar a entrada a facção.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  // States for cancel the request the Faction
  const [requestCancelJoinFactionLoading, setRequestCancelJoinFactionLoading] = useState(false)
  const requestCancelJoinFaction = async () => {
    setRequestCancelJoinFactionLoading(true)

    try {
      const config = {
        headers: {
          id_faction: idFaction,
          gamertag: user.gamertag
        }
      }

      const response = await Api.post('faction/player/cancel', {}, config)
      const {success, message} = response.data

      if(success) {
        setReloadGetData(true)
        toast.success(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }

      setRequestCancelJoinFactionLoading(false)
    } catch (err) {
      setRequestCancelJoinFactionLoading(false)
      toast.error('Ops! Não foi cancelar a solicitação de entrada a facção.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  // States for exit the Faction
  const [requestExitFactionLoading, setRequestExitFactionLoading] = useState(false)
  const requestExitFaction = async () => {
    setRequestExitFactionLoading(true)

    try {
      const config = {
        headers: {
          id_faction: idFaction,
          gamertag: user.gamertag
        }
      }

      const response = await Api.post('faction/player/leave', {}, config)
      const {success, message} = response.data

      if(success) {
        setReloadGetData(true)
        toast.success(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }

      setRequestExitFactionLoading(false)
    } catch (err) {
      setRequestExitFactionLoading(false)
      toast.error('Ops! Não foi possivel sair da facção.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  // Save and update description
  const [requestDescriptionLoading, setRequestDescriptionLoading] = useState(false)
  const [description, setDescription] = useState(data.description)
  const descriptionFilled = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setDescription(value.slice(0, maxLength)); 
  }

  const saveDescription = async () => {
    setRequestDescriptionLoading(true);

    try {
      const config = {
        headers: {
          id_faction: idFaction,
          gamertag: user.gamertag,
          description: description
        }
      }

      const response = await Api.post('faction/edit/description', {}, config)
      const {success, message} = response.data

      if(success) {
        toast.success(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }

      setRequestDescriptionLoading(false)
    } catch (err) {
      setRequestDescriptionLoading(false)
      toast.error('Ops! Não foi possivel salvar descrição da facção.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  return (
    <div className='card'>
      <div className='cover-image' style={{backgroundImage: `url(${data.image.cover.url ?? ''})`}}></div>

      <div className='content'>
        <div className='logo-image' style={{backgroundImage: `url(${data.image.logo.url ?? ''})`}}></div>

        <div className='status'>
          <ul>
            <li>Sigla: <strong>{data.acronym}</strong></li>
            <li>Sobreviventes: <strong>{data.total_players} / 30</strong></li>
          </ul>
        </div>

        {/* INFOS */}
        <div className='infos'>
          <h3>[{data.acronym}] {data.name} </h3>
          <div className='prestige-level'>
            Nível de prestigio: <strong>{data.prestige.label}</strong>
            <ProgressBar
              height="10px"
              isLabelVisible={false}
              completed={data.prestige.level}
              bgColor={data.prestige.color}
              labelColor="#ffffff"
              maxCompleted={100}
              animateOnRender
            />
          </div>
          {validators.belongsTheFaction && validators.role === 'admin' ? 
            <>
              <strong>Sobre a Facção:</strong>
              <div className='count-character'>Caracteres restantes: {maxLength - description.length}</div>
                <textarea
                  value={description}
                  onChange={descriptionFilled}
                  placeholder={''}
                  className='description-input'
                />
                {requestDescriptionLoading ?
                    <button className="btn-save-description" disabled>
                      <span className="loader"></span>
                    </button>
                  :
                    <button className="btn-save-description" onClick={() => saveDescription()}>Salvar</button>
                }
            </> 
            : 
            description.length > 0 &&
            <>
              <strong>Sobre a Facção:</strong>
              <p>{description}</p>
            </>
          }
        </div>

        {/* FOOTER */}
        <div className='footer'>
          {validators.belongsTheFaction && validators.role === 'admin' && (
            <AlertDialog 
              title='Você tem certeza disso?'
              description='Esta ação irá deletar a facção e todos os dados relacionados a ela. Deseja continuar?'
              element={
                deleteLoading ?
                  <button className="btn btn-danger" disabled>
                    <span className="loader"></span>
                  </button>
                  :
                  <button className='btn btn-danger'>Deletar a Facção</button>
              }
              action={() => deleteFaction()}
              buttonAction='Sim, deletar'
              type='danger'
            />
          )}

          {validators.belongsTheFaction && validators.role !== 'admin' && (
            <AlertDialog 
              title='Você tem certeza disso?'
              description='Esta ação irá remover você para a facção. Deseja continuar?'
              element={
                requestExitFactionLoading ?
                  <button className="btn btn-danger" disabled>
                    <span className="loader"></span>
                  </button>
                  :
                  <button className='btn btn-danger'>Sair da Facção</button>
              }
              action={() => {requestExitFaction()}}
              buttonAction='Sim, sair'
              type='danger'
            />
          )}

          {!validators.belongsTheFaction &&  
           !validators.belongsOtherFaction &&
           !validators.belongsRequestTheFaction &&
           !validators.belongsRequestOtherFaction && (
            <AlertDialog 
              title='Você tem certeza disso?'
              description='Esta ação irá solicitar a entrada para a facção. Deseja continuar?'
              element={
                requestJoinFactionLoading ?
                  <button className="btn btn-primary" disabled>
                    <span className="loader"></span>
                  </button>
                  :
                  <button className='btn btn-primary'>Solicitar entrada para a Facção</button>
              }
              action={() => {requestJoinFaction()}}
              buttonAction='Sim, solicitar'
              type='success'
            />
          )}

          {!validators.belongsTheFaction && 
           !validators.belongsOtherFaction &&
           validators.belongsRequestTheFaction &&
           !validators.belongsRequestOtherFaction && (
            <AlertDialog 
              title='Você tem certeza disso?'
              description='Esta ação irá cancelar a solicitação de entrada para a facção. Deseja continuar?'
              element={
                requestCancelJoinFactionLoading ?
                  <button className="btn btn-primary" disabled>
                    <span className="loader"></span>
                  </button>
                  :
                  <button className='btn btn-primary'>Cancelar solicitação</button>
              }
              action={() => {requestCancelJoinFaction()}}
              buttonAction='Sim, cancelar'
              type='danger'
            />
          )}
        </div>
      </div>
    </div>
  )
}