import React from 'react'

interface ISelectSource {
  sourceSelected: string,
  setSourceSelected: Function
}

export const SelectSource: React.FC<ISelectSource> = ({sourceSelected, setSourceSelected}: ISelectSource) => {
  const handleSource = (event: any) => {
    setSourceSelected(event.target.value);
  }
  
  return (
    <div className='select-source col-12'>
      <p>Escolha a origem do débito:</p>
      <label className='radio'>
        <input
          type="radio"
          value="coins"
          checked={sourceSelected === 'coins'}
          onChange={handleSource}
          name='source'
        />
        <span className="label"></span>
        Carteira
      </label>
      <label className='radio'>
        <input
          type="radio"
          value="coins_bank"
          checked={sourceSelected === 'coins_bank'}
          onChange={handleSource}
          name='source'
        />
        <span className="label"></span>
        Banco
      </label>
    </div>
  )
}