import * as AlertDialog from '@radix-ui/react-alert-dialog';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Api from '../../services/Api';
import { useGetUser } from '../../store/hooks/UserHook';

interface IDeposit {
  bankTypeAction: string;
  setIsTransfer: Function;
}

export const Deposit: React.FC<IDeposit> = ({ bankTypeAction, setIsTransfer }: IDeposit) => {
  const user = useGetUser();
  const { control, handleSubmit, formState: { errors }, setValue, getValues, trigger } = useForm<FormData>();
  const [drbValue, setDrbValue] = useState('0.00');
  const [isDeposit, setDeposit] = useState(false);
  const [isActived, setActived] = useState(false);
  const [hasBank, setHasBank] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  type FormData = {
    value: String;
  };

  const handleDrbValue = (e: any) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      if (parseFloat(inputValue) < 0) {
        setDrbValue('0.00');
      } else {
        setDrbValue(inputValue);
      }
    }
  };

  const depositValue = async (data: any) => {
    setDeposit(true);

    try {
      const config = {
        headers: {
          gamertag: user.gamertag,
          amount: data.value
        }
      };

      const response = await Api.post('coins/deposit', {}, config);
      setIsTransfer(true);
      setDeposit(false);
      setIsDialogOpen(false); // Fechar o AlertDialog após o depósito

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {
            setIsTransfer(false);
          }
        });
      }

      if (!response.data.success) {
        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {
            setIsTransfer(false);
          }
        });
      }
    } catch (error) {
      setDeposit(false);
      setIsTransfer(false);
      toast.error('OPS! Não foi possível transferir o valor.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const activeBankAccount = async () => {
    setActived(true);
    try {
      const config = {
        headers: {
          gamertag: user.gamertag
        }
      };

      const response = await Api.post('coins/active-bank', {}, config);
      setActived(false);
      if (response.data.success) {
        setIsTransfer(true);
        setHasBank(true);
        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      if (!response.data.success) {
        setIsTransfer(false);
        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      setActived(false);
      setIsTransfer(false);
      toast.error('OPS! Não foi possível ativar sua conta bancária.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    const hasBankAccount = async () => {
      try {
        const config = {
          headers: {
            gamertag: user.gamertag
          }
        };

        const response = await Api.get('coins/has-bank', config);
        setHasBank(response.data.hasBank);
        setLoading(false);
      } catch (err) {
        setHasBank(false);
        setLoading(false);
        toast.error('OPS! Não foi possível verificar sua conta bancária.', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    };

    hasBankAccount();
  }, [hasBank]);

  return (
    <div className='deposit' style={{ display: bankTypeAction === 'deposit' ? 'block' : 'none' }}>
      {loading ? (
        <div className="spinner page">
          <span className="loader"></span>
        </div>
      ) : (
        <>
          {hasBank ? (
            <>
              <p>
                Garantir a segurança da sua fortuna é uma tarefa crucial, e depositar suas <strong>DRB$</strong> é o passo vital nessa jornada. Imagine só:
                após a sua partida para o pós-vida, todo o tesouro guardado em sua carteira estará sujeito a desaparecer junto com o loot. Ou, ainda pior, se um sobrevivente mais astuto decidir
                encerrar sua jornada, toda a riqueza em sua posse passará para as mãos ávidas do vencedor. Não deixe que isso aconteça! Assegure-se de depositar suas <strong>DRB$</strong> e
                mantenha sua fortuna a salvo, criando um legado que transcenda os desafios do pós-apocalipse.
              </p>
              <form onSubmit={handleSubmit((data) => setIsDialogOpen(true))}>
                <div className='transfer row'>
                  <ul className="error">
                    {errors.value && <li><i className="lni lni-warning"></i> {errors.value.message}</li>}
                  </ul>
                  <div className='value col-lg-2 col-md-3 col-12'>
                    <Controller
                      name="value"
                      control={control}
                      defaultValue={drbValue}
                      rules={{
                        required: 'O campo valor é obrigatório.',
                        validate: value => parseFloat(String(value)) > 0.00 || 'O valor deve ser maior que zero.'
                      }}
                      render={({ field }) => (
                        <>
                          <div className='label'>DRB$</div>
                          <input
                            type={'number'}
                            value={drbValue}
                            onChange={(e) => {
                              handleDrbValue(e);
                              field.onChange(e.target.value);
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div className='pay col-lg-2 col-md-3 col-12'>
                    {isDeposit ? (
                      <button className='btn btn-primary' disabled>
                        <div className="spinner">
                          <span className="loader"></span>
                        </div>
                      </button>
                    ) : (
                      <button className='btn btn-primary'>Depositar</button>
                    )}
                  </div>
                </div>
              </form>
            </>
          ) : (
            <div className='active-bank col-lg-12 col-md-12 col-12'>
              <p>Para desbloquear todo o potencial da sua conta bancária,
                basta investir uma única taxa de <strong>DRB$ 5,00</strong>. Ative-a agora e garanta que suas economias permaneçam resguardadas dos desafios do apocalipse.
                A segurança dos seus recursos está a apenas um passo de distância. Não perca a oportunidade de fortalecer sua posição financeira!</p>
                {isActived ? (
                  <button className='btn btn-primary' disabled>
                    <div className="spinner">
                      <span className="loader"></span>
                    </div>
                  </button>
                ) : (
                  <button className='btn btn-primary' onClick={activeBankAccount}>Ativar conta</button>
                )}
            </div>
          )}
        </>
      )}

      <AlertDialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <AlertDialog.Trigger asChild>
          <button type="button" style={{ display: 'none' }}>Open Dialog</button>
        </AlertDialog.Trigger>
        <AlertDialog.Portal>
          <AlertDialog.Overlay className="alert-dialog-overlay" />
          <AlertDialog.Content className="alert-dialog-content">
            <AlertDialog.Title className="alert-dialog-title">Você tem certeza disso?</AlertDialog.Title>
            <AlertDialog.Description>
              Esta ação irá depositar o valor de <strong>DRB$ {getValues('value')}</strong> para sua conta bancária. Deseja continuar?
            </AlertDialog.Description>
            <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
              <AlertDialog.Cancel asChild>
                <button className="alert-cancel-button">Cancelar</button>
              </AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <button className="alert-action-button success" onClick={handleSubmit(depositValue)}>Sim, depositar</button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );
};