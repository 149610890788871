import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, Footer, Header, ServerData } from '../../components';
import { RankingPlayTime } from '../../components/index';

const PlayTime: React.FC = () => {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  const breadCrumb = [
    { name: "Ranking", url: "/ranking" },
    { name: "Tempo de Jogo", url: "/ranking/playtime" },
  ]
  
  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''} />
        <div className='ranking'>
          <Breadcrumb items={breadCrumb} />
          <RankingPlayTime />
        </div>
      <Footer />
    </>
  )
}

export default PlayTime