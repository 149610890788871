import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, Footer, Header, RankingBuilds, ServerData } from '../../components';

const Builds: React.FC = () => {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  const breadCrumb = [
    { name: "Ranking", url: "/ranking" },
    { name: "Builds", url: "/ranking/builds" },
  ]

  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''} />
        <div className='ranking'>
          <Breadcrumb items={breadCrumb} />
          <RankingBuilds />
        </div>
      <Footer />
    </>
  )
}

export default Builds