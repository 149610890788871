import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../services/Api';
import { useGetUser } from '../../../store/hooks/UserHook';

export const FactionCreateModule: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const user = useGetUser()
  const navigate = useNavigate();
  const {register, handleSubmit, formState: { errors }} = useForm<FormData>()
  const onSubmit = handleSubmit(data => {createFaction(data)})

  type FormData = {
    name: string;
    acronym: string;
  }

  const createFaction = async (data: any) => {
    setIsLoading(true)

    const config = {
      headers: {
        gamertag: `${user.gamertag}`,
        name: data.name,
        acronym: data.acronym
      }
    }

    try {
      const response = await api.post('faction/create', {}, config)
      const {success, message, factionId} = response.data

      if(success) {
        navigate(`/faction/profile/${factionId}`)
      } else {
        setIsLoading(false)
        toast.error(message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    } catch (err) {
      toast.error('Ops! Algo deu errado, tente novamente.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }

  }

  const closeModal = () => {
    setModalIsOpen(false)
  }
  
  return (
    <>
      <div className="create-faction">
        <button className='btn btn-primary' onClick={() => setModalIsOpen(true)}>Criar uma facção</button>
      </div>

      <PureModal
        header="Criar Facção"
        scrollable={false}
        className={"dialog pix"}
        isOpen={modalIsOpen}
        onClose={() => closeModal()}
      >
        <>
          <p><strong>{user.gamertag}</strong>, você está a um passo de iniciar uma nova jornada épica, onde o destino da sua própria Facção 
          estará em suas mãos. Com estratégia, liderança e controle, sua facção poderá crescer e se tornar uma força imbatível. Prepare-se para dominar!</p>

          <form onSubmit={onSubmit}>
            <ul className="error">
              {errors.name && <li><i className="lni lni-warning"></i> O nome da Facção é obrigatório e com o máximo de 20 caracteres.</li>}
              {errors.acronym && <li><i className="lni lni-warning"></i> A sigla da Facção é obrigatória e com o máximo de 3 caracteres.</li>}
            </ul>
            
            <input type={'text'} placeholder="Nome da Facção"
              maxLength={20}
              {...register('name', {
                maxLength: 20,
                required: true, 
              })} 
            />
            <input type={'text'} placeholder="Sigla da Facção"
              maxLength={3}
              {...register('acronym', {
                maxLength: 3,
                required: true, 
              })} 
            />

            <input type="submit" value={"Criar"} className={isLoading ? 'disabled' : ''} />
            {
              isLoading ?
              <div className='load-cover'>
                <div className="spinner">
                  <span className="loader"></span>
                </div>
              </div>
              :
              <></>
            }
          </form>
        </>
      </PureModal>
    </>
  )
}