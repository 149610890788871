export function Tenant() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" x="0" y="0" viewBox="0 0 24 24" enableBackground="new 0 0 512 512" xmlSpace="preserve">
    <g>
      <path fill="#009688" d="m23.762 11.702-11.25-10.5a.747.747 0 0 0-1.023 0l-11.25 10.5A.746.746 0 0 0 0 12.25v5a.75.75 0 0 0 1.261.549L12 7.828 22.739 17.8a.753.753 0 0 0 .811.138.751.751 0 0 0 .45-.687v-5a.75.75 0 0 0-.238-.549z" opacity="1" data-original="#009688"></path>
      <path fill="#ffc107" d="M17.961 15.522a.746.746 0 0 0-.598-.503l-3.232-.494-1.452-3.094c-.246-.527-1.111-.527-1.357 0L9.87 14.525l-3.233.495a.749.749 0 0 0-.424 1.265l2.361 2.42-.56 3.425a.749.749 0 0 0 1.104.777L12 21.313l2.882 1.593a.744.744 0 0 0 .795-.043.75.75 0 0 0 .309-.734l-.56-3.425 2.361-2.42a.749.749 0 0 0 .174-.762z" opacity="1" data-original="#ffc107"></path>
      <path fill="#008376" d="M12 1a.748.748 0 0 0-.512.202l-11.25 10.5A.75.75 0 0 0 0 12.25v5a.75.75 0 0 0 1.26.55L12 7.828z" opacity="1" data-original="#008376"></path>
      <path fill="#dea806" d="M12 11.036c-.278 0-.556.132-.679.396l-1.452 3.094-3.232.494a.749.749 0 0 0-.424 1.265l2.361 2.42-.56 3.425a.749.749 0 0 0 1.104.777L12 21.313z" opacity="1" data-original="#dea806"></path>
    </g>
  </svg>
  )
}