import React from 'react'

const Error404: React.FC = () => {
  return (
    <div className='wow fadeInUp error-page'>
      <h1>404!</h1>
      <p>Não encontramos a página que esta tentando acessar</p>
      <div className="button wow fadeInUp"><a href="/" className="btn">Ir para o início</a></div>
    </div>
  )
}

export default Error404