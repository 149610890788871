import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Api from "../../services/Api";
import { NoData } from "../structure/noData";

export const HeatMap: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await Api.get('logs/heat-map')
        if(response.data.heatmap[0].image_url && isLoading) {
          setIsLoading(false)
          setImageUrl(response.data.heatmap[0].image_url)
        }
      } catch (err) {
        setIsLoading(false)
      }
    }
    getData()
  }, [imageUrl, isLoading])

  if (isLoading) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className='wow fadeInUp'>
              <section className="section">
                <div className='container'>
                  <div className="row">
                    <div className="section-title">
                      <h3>LOG</h3>
                      <h2>Mapa de Calor</h2>
                      <p>Mapa de Chernarus com zonas de calor das áreas com maiores movimentos de sobreviventes. <br /> <i>O mapa de calor é atualizado de hora em hora</i>.</p>
                    </div>
                    <div className='wow fadeInRight'>
                      <div className="spinner">
                        <span className="loader"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Mapa de Calor</h2>
          <p>Mapa de Chernarus com zonas de calor das áreas com maiores movimentos de sobreviventes. <i>O mapa de calor é atualizado de hora em hora</i>.</p>

          {imageUrl ?
            <div className="map">
              <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools">
                      <button onClick={() => zoomIn()}><i className="lni lni-circle-plus"></i></button>
                      <button onClick={() => zoomOut()}><i className="lni lni-circle-minus"></i></button>
                      <button onClick={() => resetTransform()}><i className="lni lni-close"></i></button>
                    </div>
                    <TransformComponent>
                      <LazyLoadImage
                        src={imageUrl}
                        effect={'black-and-white'}
                        placeholderSrc={'/assets/images/heat-map/cover.jpeg'}
                      />
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </div>
            : 
              <NoData 
                description='o Heat map ainda está vazio! Aguarde até a próxima atualização'
              />
            }
        </div>
      </div>
    </div>
  );
};
