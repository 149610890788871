import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GlobalState } from '../../interfaces'

const initialState: GlobalState = {
  whitelist: {
    modalIsOpen: false
  },
  moder: {
    modalIsOpen: false
  },
  statistic: {
    modalIsOpen: false
  }
}

const dataSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setWhitelistModalIsOpen(state: GlobalState, action: PayloadAction<boolean>) {
      state.whitelist.modalIsOpen = action.payload
    },
    setModerModalIsOpen(state: GlobalState, action: PayloadAction<boolean>) {
      state.moder.modalIsOpen = action.payload
    },
    setStatisticModalIsOpen(state: GlobalState, action: PayloadAction<boolean>) {
      state.statistic.modalIsOpen = action.payload
    }
  },
})

export const {
  setWhitelistModalIsOpen,
  setModerModalIsOpen,
  setStatisticModalIsOpen
} = dataSlice.actions

export default dataSlice.reducer
