import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../services/Api";
import { useGetUser } from '../../store/hooks/UserHook';

interface IBaseSecurity {
  coordinates: {[key: string]: any}
}

export const BaseSecurity: React.FC<IBaseSecurity> = ({coordinates}: IBaseSecurity) => {
  const user = useGetUser()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [whitelist, setWhitelist] = useState(String)
  const [showCreateButton, setShowCreateButton] = useState(true)
  const [savingButton, setSavingButton] = useState(false)

  useEffect(() => {
    const getData = async () => {
      const config = {
        headers: {
          gamertag: `${user.gamertag}`
        }
      }

      try {
        const response = await Api.get('safezone/list', config)
        if(Object.keys(response.data.whitelist).length > 0 && isLoading) {
          setShowCreateButton(false)
          setWhitelist(response.data.whitelist.filter((item: any) => item !== user.gamertag).join('\n'))
        } else if (Object.keys(response.data.whitelist).length === 0 && isLoading) {
          setShowCreateButton(true)
        }
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        setIsError(true)
      }
    }
    
    getData()
  }, [showCreateButton])

  const setNewWhitelistValue = (event: any) => {
    const value = event.target.value
    setWhitelist(value)
  }

  const saveWhitelist = async (event: any, showToast: boolean) => {
    setSavingButton(true)
    try {
      let list = whitelist.trim() === '' ? [user.gamertag] : [...whitelist.split('\n'), user.gamertag]
      const config = {
        headers: {
          gamertag: `${user.gamertag}`,
        }
      }

      const body = {
        body: {
          violations: ["builds", "placements", "mounts", "unmounts", "dismantles"],
          whitelist: list
        }
      }
      await Api.post('safezone/edit', body, config)
      setSavingButton(false)

      if(showToast) {
        toast.success('Whitelist atualizada com sucesso.', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    } catch (err) {
      setSavingButton(false)
      setIsLoading(false)
      setIsError(true)
      toast.error('Não foi possível atualizar a Whitelist, tente novamente mais tarde.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  const createSafeZone = async (event: any) => {
    setSavingButton(true)
    try {
      const config = {
        headers: {
          name: `${user.gamertag}`,
          x: Number(coordinates.x),
          z: Number(coordinates.y),
          radius: Number(20)
        }
      }
      const body = {
        body: {
          violations: ["builds", "placements", "mounts"]
        }
      }
      
      await Api.post('safezone/create', body, config)
      saveWhitelist(event, false).then(() => {
        setSavingButton(false)
        setShowCreateButton(false)
        toast.success('Security Base ativado com sucesso.', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      })
    } catch (err) {
      toast.error('Não foi possível ativar o Base Security, tente novamente mais tarde.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  if (isLoading) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Security</h2>
            <p>Sua base protegida por um <strong>BOT</strong>! Sobreviventes que estão fora da sua whitelist serão automaticamente suspensos caso <strong>construam</strong>, <strong>coloquem</strong> ou <strong>desmontem</strong> algo próximo a sua base.</p>
            <div className="base-security">
              <div className='wow fadeInRight'>
                <div className="spinner">
                  <span className="loader"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(isError) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>OPS! :(</h2>
            <p>Tivemos problemas na hora de obter os dados, tente novamente mais tarde!</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Security</h2>
          <p>Sua base protegida por um <strong>BOT</strong>! Sobreviventes que estão fora da sua whitelist serão automaticamente suspensos caso <strong>construam</strong>, <strong>coloquem</strong> ou <strong>desmontem</strong> algo próximo a sua base.</p>
          <div className="base-security">

            {showCreateButton ? 
              (
                savingButton ?
                  (
                    <button className="btn-create" disabled>
                      <span className="loader"></span>
                    </button>
                  )
                  :
                  (
                    <button className="btn-create" onClick={(event) => createSafeZone(event)}>Ativar</button>
                  )
              ) 
              : 
              (
                <>
                  <div className='alert-warning'>
                    <i className="lni lni-warning"></i>
                    Para o funcionamento correto, cadastre cada <strong>Gamertag</strong> em uma nova linha. Não é necessário cadastrar a sua <strong>Gamertag</strong>.
                  </div>
                  <textarea value={whitelist} onChange={(event) => setNewWhitelistValue(event)} placeholder="Cadastre Gamertags dos sobreviventes que possuem acesso a sua base." />
                  {savingButton ?
                    (
                      <button className="btn-save" disabled>
                        <span className="loader"></span>
                      </button>
                    )
                    :
                    (
                      <>
                        <button className="btn-save" onClick={(event) => saveWhitelist(event, true)}>Salvar</button>
                      </>
                    )
                  }
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};
