import React from 'react'
import {
  About,
  FaqSection,
  Footer,
  Forum,
  GetWhitelist,
  Header,
  Hero,
  Season,
  ServerData,
  SliderSection
} from '../components'

const Home: React.FC = () => {
  return (
    <div className='wow fadeInUp'>
      <ServerData />
      <Header headerStyle={false} menuActive={'home'}/>
        <Hero />
        <About />
        <Season />
        <FaqSection />
        <SliderSection />
        <Forum />
        <GetWhitelist />
      <Footer />
    </div>
  )
}

export default Home