import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataState, ServerState, StatusState } from '../../interfaces';
import api from '../../services/Api';
import { setStatus } from '../../store/ducks/DataDuck';
import * as ServerActions from '../../store/ducks/ServerDuck';
import * as StatusActions from '../../store/ducks/StatusDuck';

export const ServerData: React.FC = () => {
  const dispatch = useDispatch();
  
  const [toastShown, setToastShown] = useState<{ server: boolean; status: boolean }>({
    server: false,
    status: false,
  });

  const showToast = (message: string) => {
    toast.warning(message, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });
  };

  const setDataStatus = (response: DataState) => {
    dispatch(setStatus(response.status));
  };

  const updateStoreWithResponse = (response: any, actions: Record<string, any>) => {
    Object.entries(actions).forEach(([key, action]) => {
      if (response[key] !== undefined) {
        dispatch(action(response[key]));
      }
    });
  };

  const setServerSession = (response: ServerState) => {
    updateStoreWithResponse(
      {
        color: response.status.color,
        message: response.status.message,
        lastUpdate: response.lastUpdate,
        name: response.name,
        playerMax: response.playerMax,
        playerOnline: response.playerOnline,
        version: response.version,
      },
      {
        color: ServerActions.setColor,
        message: ServerActions.setMessage,
        lastUpdate: ServerActions.setLastUpdate,
        name: ServerActions.setName,
        playerMax: ServerActions.setPlayerMax,
        playerOnline: ServerActions.setPlayerOnline,
        version: ServerActions.setVersion,
      }
    );
  };

  const setStatusSession = (response: StatusState) => {
    const { trader, night, season, config, event } = response;
    updateStoreWithResponse(
      {
        event,
        active: trader.active,
        traderMessage: trader.message,
        traderTime: trader.time,
        nightType: night.type,
        nightMessage: night.message,
        season: season.season,
        temp: season.temp,
        tip: season.tip,
        title: season.title,
        weather: season.weather,
        seasonEnd: config.seasonEnd,
        seasonNumber: config.seasonNumber,
      },
      {
        event: StatusActions.setEvent,
        active: StatusActions.setTraderActive,
        traderMessage: StatusActions.setTraderMessage,
        traderTime: StatusActions.setTraderTime,
        nightType: StatusActions.setNightType,
        nightMessage: StatusActions.setNightMessage,
        season: StatusActions.setSeason,
        temp: StatusActions.setSeasonTemp,
        tip: StatusActions.setSeasonTip,
        title: StatusActions.setSeasonTitle,
        weather: StatusActions.setSeasonWeather,
        seasonEnd: StatusActions.setSeasonEnd,
        seasonNumber: StatusActions.setSeasonNumber,
      }
    );
  };

  const fetchData = async (url: string, onSuccess: (data: any) => void, errorKey: keyof typeof toastShown, errorMessage: string) => {
    try {
      const response = await api.get(url);
      const { success } = response.data;

      if (success) {
        resetErrorState(errorKey);
        onSuccess(response.data);
      } else {
        handleError(errorKey, errorMessage);
      }
    } catch (err) {
      handleError(errorKey, errorMessage);
    }
  };

  const resetErrorState = (errorKey: keyof typeof toastShown) => {
    sessionStorage.setItem(errorKey, 'false');
    setToastShown((prev) => ({ ...prev, [errorKey]: false }));
  };

  const handleError = (errorKey: keyof typeof toastShown, errorMessage: string) => {
    const isErrorAlreadyShown = sessionStorage.getItem(errorKey) === 'true';

    if (!isErrorAlreadyShown && !toastShown[errorKey]) {
      sessionStorage.setItem(errorKey, 'true');
      showToast(errorMessage);
      setToastShown((prev) => ({ ...prev, [errorKey]: true }));
    }
  };

  useEffect(() => {
    fetchData(
      'server',
      (data) => {
        setServerSession(data.server[0]);
        setDataStatus(data.data);
      },
      'server',
      'Não foi possivel obter informações sobre o servidor. Assim que possível iremos atualizar as informações.'
    );

    fetchData(
      'status',
      (data) => setStatusSession(data.status[0]),
      'status',
      'Não foi possivel obter informações sobre o status do servidor. Assim que possível iremos atualizar as informações.'
    );
  }, []);

  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="dark"
      />
    </>
  );
};