import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, FactionSearch, Footer, Header, ServerData } from '../../components';

const FactionPage: React.FC = () => {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  const breadCrumb = [
    { name: "Facção", url: "/faction" }
  ]

  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''}/>
        <div className='faction-page'>
          <Breadcrumb items={breadCrumb} />
          <FactionSearch showText={true}/>
        </div>  
      <Footer />
    </>
  )
}

export default FactionPage