import * as AlertDialog from '@radix-ui/react-alert-dialog'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Api from '../../services/Api'
import { useGetUser } from '../../store/hooks/UserHook'
import { Coin } from '../coins/coin'
import { SelectSource } from '../coins/selectSource'
import { NoData } from '../structure/noData'

export const Bounty: React.FC = () => {
  const user = useGetUser()
  const [isLoading, setIsLoading] = useState(true)
  const [isSender, setIsSender] = useState(false)
  const [options, setOptions] = useState(Object)
  const [bounty, setBounty] = useState(Object)
  const [loadListGamertag, setLoadListGamertag] = useState("Carregando Gamertags")
  const [drbValue, setDrbValue] = useState('5.00')
  const [isCreated, setIsCreated] = useState(false)
  const [isDeleted, setIsDeleted] = useState<IsDeletedState>({})
  const [removedItems, setRemovedItems] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [bountyListType, setBountyListType] = useState('my')
  const perPage = isMobile ? 5 : 10
  const { control, register, handleSubmit, formState: { errors }, setValue, getValues, trigger } = useForm<FormData>();
  const [sourceSelected, setSourceSelected] = useState('coins')
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  type FormData = {
    gamertag: String;
    value: String;
  }

  type IsDeletedState = { [key: string]: boolean }

  const handleDrbValue = (e: any) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      const floatValue = parseFloat(inputValue);
      if (floatValue < 5) {
        // Ajusta o valor apenas se o usuário não estiver digitando um número maior
        if (floatValue > 0) {
          setDrbValue(inputValue);
        } else {
          setDrbValue('5.00');
        }
      } else {
        setDrbValue(inputValue);
      }
    }
  }

  const removeBounty = async (id: number) => {
    const newIsDeleted = { ...isDeleted }
    newIsDeleted[id] = true
    setIsDeleted(newIsDeleted)

    try {
      const config = {
        headers: {
          id: id
        }
      }

      const response = await Api.delete('bounty/delete', config)
      setRemovedItems([...removedItems, id])
      setIsCreated(true)
      newIsDeleted[id] = false

      if(response.data.success) {
        toast.success(
          response.data.message
          , {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {
            setIsCreated(false)
          }
        })
      }

      if(!response.data.success) {
        toast.error(
          response.data.message
          , {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {
            setIsCreated(false)
          }
        })
      }
    } catch (err) {
      setIsCreated(false)
      newIsDeleted[id] = false
      toast.error(
        <div>OPS! Não foi possível cancelar a recompensa.</div>
        , {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }

  const createBounty = async (data: any) => {
    setIsSender(true)
    try {
      const config = {
        headers: {
          gamertag: user.gamertag,
          'gamertag_bounty': data.gamertag,
          coins: data.value,
          source: sourceSelected
        }
      }

      const response = await Api.post('bounty/create', {}, config)
      setIsCreated(true)
      setIsSender(false)

      if(response.data.success) {
        toast.success(
          response.data.message
          , {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {
            setIsCreated(false)
          }
        })
      }

      if(!response.data.success) {
        toast.error(
          response.data.message
          , {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {
            setIsCreated(false)
          }
        })
      }
    } catch (err) {
      setIsSender(false)
      setIsCreated(false)
      toast.error(
        <div>OPS! Não foi possível cadastrar a recompensa.</div>
        , {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }

  useEffect(() => {
    const getGamertags = async () => {
      try {
        const config = {
          headers: {
            'clean_data': true
          }
        }

        const response = await Api.get('whitelist/list', config)
        const updatedArr = response.data
        .map((data: any) => ({ value: data.gamertag, label: data.gamertag }))
        .filter((data: any) => data.value !== user.gamertag)

        setOptions(updatedArr)
        setLoadListGamertag("Selecione a Gamertag")
      } catch (err) {
        toast.error(
          <div>OPS! Não foi possível recuperar as Gamertags.</div>
          , {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    }

    getGamertags()
  }, [isCreated])

  useEffect(() => {
    const getBounty = async () => {
      try {
        let config: any = {
          headers: {
            gamertag: user.gamertag,
          },
        }

        if (bountyListType === 'my') {
          config.headers['is_all_data'] = false;
        } else if (bountyListType === 'all') {
          config.headers['is_all_data'] = true;
        } else if (bountyListType === 'done') {
          config = undefined;
        }

        const response = await Api.get(`bounty/list?page=${currentPage}&per_page=${perPage}`, config)

        if(response.data.bountys.length === 0 && currentPage > 1) {
          setCurrentPage(currentPage - 1)
        }

        setBounty(response.data.bountys)
        setIsLoading(false)
        setTotalPages(response.data.totalPages)
      } catch (err) {
        setIsLoading(false)
        toast.error(
          <div>OPS! Não foi possível recuperar as Gamertags.</div>
          , {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    }

    getBounty()
  }, [isCreated, bountyListType, currentPage])

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  }

  const confirmBounty = (data: any) => {
    createBounty(data);
    setIsDialogOpen(false);
  };

  const handleValidation = async () => {
    const isValid = await trigger();
    if (isValid) {
      setIsDialogOpen(true);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Sobrevivente como alvo</h2>
          <p>Cadastre a recompensa e coloque o sobrevivente como alvo. O sobrevivente que realizar a execução recebe o valor da recompensa.</p>

          <div className="col-lg-3 col-md-5 col-sm-12 offset-lg-9 offset-md-7">
            <Coin reload={isCreated} />
          </div>

          <form>
            <div className='register row'>
              <ul className="error">
                {errors.gamertag && <li><i className="lni lni-warning"></i> O campo gamertag é obrigatório.</li>}
                {errors.value && <li><i className="lni lni-warning"></i> {errors.value.message}</li>}
              </ul>

              <SelectSource setSourceSelected={setSourceSelected} sourceSelected={sourceSelected}/>

              <div className='receiver col-lg-8 col-md-6 col-12'>
                <Select
                  placeholder={loadListGamertag}
                  options={options}
                  {...register('gamertag', { required: true })}
                  noOptionsMessage={() => "Nenhuma Gamertag foi encontrada"}
                  onChange={(event: any) => {
                    setValue('gamertag', event?.value ?? '')
                  }}
                />
              </div>
              <div className='value col-lg-2 col-md-3 col-12'>
              <Controller
                  name="value"
                  control={control}
                  defaultValue={drbValue}
                  rules={{
                    required: 'O campo valor é obrigatório.',
                    validate: value => parseFloat(String(value)) >= 5.00 || 'O valor deve ser maior que DR$5,00.'
                  }}
                  render={({ field }) => (
                    <>
                      <div className='label'>DRB$</div>
                      <input 
                        type={'number'}
                        value={drbValue ?? 5.00}
                        onChange={(e) => {
                          handleDrbValue(e);
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <div className='register col-lg-2 col-md-3 col-12'>
                {isSender ?
                  <button className='btn btn-primary' disabled>
                    <div className="spinner">
                      <span className="loader"></span>
                    </div>
                  </button>
                  :
                  <>
                    <button type="button" className='btn btn-primary' onClick={handleValidation}>Cadastrar</button>
                    <AlertDialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                      <AlertDialog.Portal>
                        <AlertDialog.Overlay className="alert-dialog-overlay" />
                        <AlertDialog.Content className="alert-dialog-content">
                          <AlertDialog.Title className="alert-dialog-title">Você tem certeza disso?</AlertDialog.Title>
                          <AlertDialog.Description className="alert-dialog-description">
                           Esta ação irá criar uma recompoensa no valor de <strong>DRB$ {getValues('value')}</strong> por <strong>{getValues('gamertag')}</strong>. Deseja continuar?
                          </AlertDialog.Description>
                          <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
                            <AlertDialog.Cancel asChild>
                              <button className="alert-cancel-button">Cancelar</button>
                            </AlertDialog.Cancel>
                            <AlertDialog.Action asChild>
                              <button className="alert-action-button success" onClick={handleSubmit(confirmBounty)}>Sim, criar</button>
                            </AlertDialog.Action>
                          </div>
                        </AlertDialog.Content>
                      </AlertDialog.Portal>
                    </AlertDialog.Root>
                  </>
                }
              </div>
            </div>
          </form>

          <ul className='menu-tab'>
            <li 
              className={bountyListType === 'my' ? 'active' : ''}
              onClick={() => {
                if (bountyListType !== 'my') {
                  setBountyListType('my')
                  setIsLoading(true)
                  setCurrentPage(1)
                }
              }}>
                Minhas Recompensas
            </li>
            <li 
              className={bountyListType === 'all' ? 'active' : ''}
              onClick={() => {
                if (bountyListType !== 'all') {
                  setBountyListType('all')
                  setIsLoading(true)
                  setCurrentPage(1)
                }
              }}>
                Todas Recompensas
            </li>
            <li 
              className={bountyListType === 'done' ? 'active' : ''}
              onClick={() => {
                if (bountyListType !== 'done') {
                  setBountyListType('done')
                  setIsLoading(true)
                  setCurrentPage(1)
                }
              }}>
                Todas Concluídas
            </li>
          </ul>

          <div className='my-bounties'>
            {
              bountyListType === 'my' ?
                <h5>Minhas Recompensas <i className="lni lni-target-customer"></i></h5>
              :
              bountyListType === 'all' ?
                <h5>Todas Recompensas <i className="lni lni-target-customer"></i></h5>
              :
              bountyListType === 'done' ?
                <h5>Todas Concluídas <i className="lni lni-target-customer"></i></h5>
              :
              <></>
            }
            
            <div className='bounty-cards'>
              {isLoading ? 
                <div className="spinner page">
                  <span className="loader"></span>
                </div>
                :
                (
                  Object.keys(bounty).length > 0 || totalPages > 0 ?
                    <>
                      {bounty.map((item: any, idx: number) => {
                        const date = new Date(item.expiration_timestamp * 1000)
                        return (
                          <div key={`item-${idx}`} className="bounty-card wow fadeInUp" data-wow-delay="0.5s">
                            <div className='bounty-card-info'>
                              {
                                bountyListType === 'done' ?
                                <span>Concluída em {date.getDate()}/{date.getMonth()}/{date.getFullYear()} às {date.getHours()}:{date.getMinutes()}</span>
                                :
                                <span>Válido até {date.getDate()}/{date.getMonth()}/{date.getFullYear()} às {date.getHours()}:{date.getMinutes()}</span>
                              }
                              {Number(item.isDone) === 1 ? <i className="lni lni-checkmark-circle"></i> : <></>}
                            </div>
                            <div className='bounty-card-target'>
                              {
                                bountyListType === 'my' ?
                                <><strong>Você</strong> </>
                                :
                                <><strong>{item.gamertag}</strong> </>
                              }
                              colocou uma recompensa por: <strong>{item.gamertag_bounty}</strong> 
                            </div>
                            <div className='bounty-card-footer'>
                              <label>DRB$<strong>{Number(item.coins).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</strong></label>
                              <label>{item.gamertag_killer ? <>Conluído por: <strong>{item.gamertag_killer}</strong></> : <>Recompoensa não executada.</>}</label>
                            </div>
                            {isDeleted[item.id] ?
                              <button type='button' className='btn btn-primary' disabled>
                                <div className="spinner">
                                  <span className="loader"></span>
                                </div>
                              </button>
                              :
                              <>
                                {
                                  bountyListType === 'my' ?
                                    <AlertDialog.Root>
                                      <AlertDialog.Trigger asChild>
                                        <button type='button' className='btn btn-primary'>Cancelar Recompensa</button>
                                      </AlertDialog.Trigger>
                                      <AlertDialog.Portal>
                                        <AlertDialog.Overlay className="alert-dialog-overlay" />
                                        <AlertDialog.Content className="alert-dialog-content">
                                          <AlertDialog.Title className="alert-dialog-title">Você tem certeza disso?</AlertDialog.Title>
                                          <AlertDialog.Description className="alert-dialog-description">
                                            Esta ação irá cancelar a recompensa. Deseja continuar?
                                          </AlertDialog.Description>
                                          <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
                                            <AlertDialog.Cancel asChild>
                                              <button className="alert-cancel-button">Cancelar</button>
                                            </AlertDialog.Cancel>
                                            <AlertDialog.Action asChild>
                                              <button className="alert-action-button" onClick={() => removeBounty(item.id)}>Sim, cancelar</button>
                                            </AlertDialog.Action>
                                          </div>
                                        </AlertDialog.Content>
                                      </AlertDialog.Portal>
                                    </AlertDialog.Root>
                                    :
                                    <></>
                                }
                              </>
                              
                            }
                            
                          </div>
                        )
                      })}
                    </>
                    :
                    <NoData 
                      description={
                        bountyListType === 'my' ? 'você ainda não possuí recompensas cadastradas'
                        :
                        bountyListType === 'all' ? 'ainda não existem outras recompensas cadastradas'
                        :
                        bountyListType === 'done' ? 'nenhuma recompoensa foi concluída até o momento'
                        :
                        ''
                      }
                    />
                )
              }
            </div>
            {Object.keys(bounty).length > 0 ? 
              <div className='pagination'>
                <button className='btn btn-primary' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Anterior</button>
                <span>Página {currentPage} de {totalPages}</span>
                <button className='btn btn-primary' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Próxima</button>
              </div> 
              : <></>
            }
          </div>
        </div>
      </div>
    </div>
  )
}