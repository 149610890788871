import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import { useNavigate } from 'react-router';
import Select from 'react-select';
import { toast } from 'react-toastify';
import api from "../../services/Api";
import { useGetStatus } from '../../store/hooks/StatusHook';
import { useGetUser } from '../../store/hooks/UserHook';
import { Coin } from "../coins/coin";
import { SelectSource } from "../coins/selectSource";
import { NoData } from "../structure/noData";

export const BaseAlertBuy: React.FC = () => {
  type FormData = {
    selectOption: String;
  }
  const navigate = useNavigate()
  const user = useGetUser()
  const status = useGetStatus()
  const {register, handleSubmit, formState: { errors }, setValue} = useForm<FormData>()
  const onSubmit = handleSubmit(() => setBaseAlert())
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [botCount, setBotCount] = useState(0)
  const [baseTimeValue, setBaseTimeValue] = useState(String)
  const [isLoading, setIsLoading] = useState(false)
  const [sourceSelected, setSourceSelected] = useState('coins')

  const setBaseAlert = async () => {
    setIsLoading(true)
    const enableFeature = {
      headers: {
        gamertag: `${user.gamertag}`,
        xuid: `${user.xuid}`,
        email: `${user.email}`,
        basetime: baseTimeValue,
        source: sourceSelected
      }
    }

    try {
      const response = await api.post('base-alert/buy', {}, enableFeature)
      if(response.data.success) {
        navigate(0)
        closeModal()
        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      } else {
        setIsLoading(false)
        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
      
    }
    catch (err) {
      setIsLoading(false)
      toast.error(
        <div>
          <strong>{user.gamertag}</strong>, algo deu errado com o seu acesso, entre em contato com a administração!
        </div>
        , {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  const openModal = (event: any) => {
    event.preventDefault()
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  useEffect(() => {
    const getCountBot = async () => {
      try {
        const botCount = await api.get('base-alert/get-access')
        setBotCount(botCount.data.total)
      } catch (err) {
        toast.error('Não foi possível obter dados sobre o BOT. Tente novamente mais tarde', {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    }

    getCountBot()
  }, [])

  const dropDownOptinos = [
    { value: '1semana', label: '1 semana (DRB$ 80,00)' },
    { value: '2semanas', label: '2 semanas (DRB$ 90,00)' },
    { value: '3semanas', label: '3 semanas (DRB$ 100,00)' },
    { value: '1mes', label: '1 mês (DRB$ 150,00)' },
    { value: '2meses', label: '2 meses (DRB$ 200,00)' },
    { value: '3meses', label: '3 meses (DRB$ 250,00)' },
    { value: 'Temp', label: `Toda temporada ${status.config.seasonNumber} (DRB$ 350,00)` },
  ]

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Base Alert + Security</h2>
            <p><strong>Base Alert</strong> é um <strong>BOT</strong> que monitora e protege sua base enquanto você está Off-Line!</p>
            <p>O <strong>BOT</strong> gera logs indicando <strong>quem</strong>, <strong>quando</strong> e a <strong>proximidade</strong> do sobrevivente que passou perto ou infringiu as regras da sua base.</p>

            <div className="content-buy">
              <div className="list">
                <div className="number">
                  <i className="lni lni-keyboard"></i>
                </div>
                <div className="text">
                  Para começar a receber os logs, cadastre as coordenadas da sua base. Você pode obtê-las em <a href="https://dayz.xam.nu/" target="_blank" rel="noreferrer">DayZ.xam.nu</a>. As coordenadas devem conter apenas números e pontos, por exemplo: <strong>X = 1234.56</strong> e <strong>Y = 1234.56</strong>.
                </div>
              </div>

              <div className="list">
                <div className="number">
                  <i className="lni lni-cog"></i>
                </div>
                <div className="text">
                  Se a sua base mudar de lugar, você pode deletar a base atual e cadastrar a nova quantas vezes for necessário. Também é possível visualizar os dados da sua base atual, incluindo coordenadas e posição no mapa. <br />
                  <strong>Observação: Ao deletar uma base, todos os logs associados serão removidos.</strong>
                </div>
              </div>

              <div className="list">
                <div className="number">
                  <i className="lni lni-list"></i>
                </div>
                <div className="text">
                  Quando um sobrevivente é detectado perto da sua base, o <strong>BOT</strong> registra o evento e o disponibiliza no seu perfil. Os logs <strong>não são em tempo real</strong> para evitar o uso indevido das informações de localização dos sobreviventes no mapa. O Base Alert mostra os <strong>30 registros mais recentes</strong> da sua base.
                </div>
              </div>

              <div className="list">
                <div className="number">
                  <i className="lni lni-shield"></i>
                </div>
                <div className="text">
                  Junto ao <strong>Base Alert</strong>, você pode ativar o <strong>Base Security</strong> - o guardião implacável do seu refúgio, pronto para protegê-lo 24 horas por dia, 7 dias por semana. Este fiel guardião tem um propósito claro: manter a sua base segura. Quando o <strong>Base Security</strong> percebe que outro sobrevivente <strong>construiu</strong>, <strong>posicionou</strong> ou <strong>desmontou</strong> algo nas proximidades da sua base, ele entra em ação imediatamente. O infrator será automaticamente <strong>suspenso</strong> do servidor por <strong>30 minutos</strong>, dando-lhe tempo para recuperar a tranquilidade da sua base.
                </div>
              </div>
            </div>

            <p>O <strong>BOT</strong> pode ser alugado e <strong>ficará disponível para você pelo tempo escolhido</strong>. No entanto, o <strong>BOT</strong> está limitado a <strong>50 unidades</strong>; será possível alugar novamente caso existir BOT disponível.</p> <br />
            <div className="bot-count">
              {botCount} / 50
            </div>

            {Number(botCount) === 50 ? 
              <NoData 
                description='Todos os nossos BOTs já estão em uso'
              />
              :
              <div className="button wow fadeInUp" data-wow-delay=".6s">
                <a href="/#" onClick={(event) => openModal(event)} className="btn">ALUGAR BOT</a>
              </div>
            }
          </div>
        </div>
      </div>

      <PureModal
        header="Pagamento"
        scrollable={false}
        className={"dialog pix"}
        isOpen={modalIsOpen}
        onClose={() => closeModal()}
      >
        <div className="form-buy-content">
          {isLoading ?
            <div className='load-cover'>
              <div className="spinner">
                <span className="loader"></span>
              </div>
            </div>
            :
            <></>}
          <p><strong>{user.gamertag}</strong>, você contará com um aliado tecnológico vigilante, capaz de detectar e alertar sobre a aproximação de outros jogadores, proporcionando-lhe uma vantagem estratégica vital.</p><br />

          <form onSubmit={onSubmit}>
            <ul className="error">
              {errors.selectOption && <li><i className="lni lni-warning"></i> O campo tempo Fura-Fila é obrigatório.</li>}
            </ul>
            
            <SelectSource setSourceSelected={setSourceSelected} sourceSelected={sourceSelected}/>
            
            <Select
              placeholder={'Selecione o tempo de Base Alert'}
              options={dropDownOptinos}
              {...register('selectOption', { required: true })}
              noOptionsMessage={() => "Nenhum Base Alert foi encontrado"}
              onChange={(event: any) => {
                setBaseTimeValue(event?.value ?? '1hora')
                setValue('selectOption', event?.value ?? '')
              }}
            />
            <input type="submit" value={"Comprar"} className={isLoading ? 'disabled' : ''} />
            {
              isLoading ?
              <div className='load-cover'>
                <div className="spinner">
                  <span className="loader"></span>
                </div>
              </div>
              :
              <></>
            }
          </form>

          <div className="col-lg-3 col-md-5 col-sm-12 offset-lg-9 offset-md-7">
            <Coin />
          </div>
        </div>
      </PureModal>
    </>
  );
};
