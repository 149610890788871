import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, Footer, Header, RankingKills, ServerData } from '../../components';

const Kill: React.FC = () => {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  const breadCrumb = [
    { name: "Ranking", url: "/ranking" },
    { name: "Kill", url: "/ranking/kill" },
  ]
  
  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''} />
        <div className='ranking'>
          <Breadcrumb items={breadCrumb} />
          <RankingKills />
        </div>
      <Footer />
    </>
  )
}

export default Kill