import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from 'react-select'
import Api from "../../services/Api"

interface SurvivorSearchProps {
  showText: boolean
}

export const SurvivorSearch: React.FC<SurvivorSearchProps> = ({ showText }) => {
  const [loadListGamertag, setLoadListGamertag] = useState("Carregando sobreviventes...")
  const [options, setOptions] = useState(Object)
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
  
        const getData = await Api.get('survivor/search', {})
        if (getData.data) {
          setOptions(getData.data)
          setLoadListGamertag("Selecione o sobrevivente")
        }
      } catch (err) {
        setOptions({})
        setLoadListGamertag("Selecione o sobrevivente")
      }
    }

    getData()
  }, [showText])

  return (
    <>
      {showText ?
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Sobrevivente</h2>
              <p>Entre no território dos sobreviventes mais destemidos do nosso servidor! Explore a jornada de cada jogador, 
                descobrindo suas façanhas, habilidades e confiabilidade. Veja quem domina o campo de batalha, conheça seu tempo de sobrevivência, 
                conquistas e muito mais. Selecione um aventureiro da lista e desvende os segredos por trás de cada nome – porque em Dead Rising Brasil, 
                cada sobrevivente tem uma história para contar.</p>
              <Select
                placeholder={loadListGamertag}
                options={options}
                noOptionsMessage={() => "Nenhum sobrevivente foi encontrado"}
                onChange={(event: any) => {
                  navigate(`/survivor/profile/${event.value}`)
                }}
              />
            </div>
          </div>
        </div>
        :
        <Select
          className="select-survivor"
          placeholder={loadListGamertag}
          options={options}
          noOptionsMessage={() => "Nenhum sobrevivente foi encontrado"}
          onChange={(event: any) => {
            navigate(`/survivor/profile/${event.value}`)
          }}
        />
      }
    </>
  )
}