import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { DiscorWidget, ScrollButton } from './components';
import { InstallApp } from './components/structure/installApp';
import { OfflineNotification } from './components/structure/offlineNotification';
import Router from './components/structure/router';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './services/serviceWorkerRegistration';
import { persistor, store } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <InstallApp>
        <Router>
          <OfflineNotification>
            <DiscorWidget />
            <ScrollButton />
          </OfflineNotification>
        </Router>
      </InstallApp>
    </PersistGate>
  </Provider>
);

serviceWorkerRegistration.register();
reportWebVitals();
