import React, { useEffect, useState } from "react"
import { isMobile } from 'react-device-detect'
import { useForm } from "react-hook-form"
import PureModal from "react-pure-modal"
import "react-pure-modal/dist/react-pure-modal.min.css"
import Select from 'react-select'
import { toast } from "react-toastify"
import Api from '../../services/Api'
import { useGetStatus } from "../../store/hooks/StatusHook"
import { useGetUser } from "../../store/hooks/UserHook"
import { Coin } from "../coins/coin"
import { SelectSource } from "../coins/selectSource"
import { NoData } from "../structure/noData"

export const KillFeed: React.FC = () => {
  const user = useGetUser()
  const status = useGetStatus()
  const [reloadScreen, setReloadScreen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)
  const [filterLoading, setFilterLoading] = useState(false)
  const [feedData, setFeedData] = useState({ feed: [], totalPages: 1, complete: false })
  const [killFeedTimeValue, setkillFeedTimeValue] = useState(String)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [itemDetails, setItemDetails] = useState<{ [key: string]: boolean }>({});
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const {register, handleSubmit, formState: { errors }, setValue} = useForm<FormData>()
  const onSubmit = handleSubmit(() => setKillFeedAccess())
  const [sourceSelected, setSourceSelected] = useState('coins')
  const [loadListGamertag, setLoadListGamertag] = useState("Carregando Gamertags")
  const [gamertagList, setGamertagList] = useState(Object)
  const [isFilter, setIsFilter] = useState('')
  const perPage = isMobile ? 5 : 10

  type FormData = {
    selectOption: String;
  }

  const openModal = (event: any) => {
    event.preventDefault()
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const setKillFeedAccess = async () => {
    setIsPaymentLoading(true)
    const enableFeature = {
      headers: {
        gamertag: `${user.gamertag}`,
        xuid: `${user.xuid}`,
        email: `${user.email}`,
        killfeed: killFeedTimeValue,
        source: sourceSelected
      }
    }

    try {
      const response = await Api.post('feed/kill/buy', {}, enableFeature)
      if(response.data.success) {
        closeModal()
        setReloadScreen(true)
        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      } else {
        setIsPaymentLoading(false)
        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
      
    }
    catch (err) {
      setIsPaymentLoading(false)
      toast.error(
        <div>
          <strong>{user.gamertag}</strong>, algo deu errado com o seu acesso do Kill Feed, entre em contato com a administração!
        </div>
        , {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  useEffect(() => {
    const getKillFeed = async () => {
      try {
        let config: any = {
          headers: {
            gamertag: user.gamertag,
            filter: isFilter,
          },
        }
        const response = await Api.get(`feed/kill/list?page=${currentPage}&per_page=${perPage}`, config)
        if(response.data.success) {
          if(response.data.feed.length === 0 && currentPage > 1) {
            setCurrentPage(currentPage - 1)
          }
        }

        setFeedData(response.data)
        setTotalPages(response.data.totalPages)
        setIsLoading(false)
        setFilterLoading(false)
      } catch (err) {
        setIsLoading(false)
        setFilterLoading(false)
        toast.error('Ops! Não foi possível recuperar o Kill Feed.', {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    }

    getKillFeed()
  }, [currentPage, isFilter, reloadScreen])

  useEffect(() => {
    const getGamertags = async () => {
      try {
        const config = {
          headers: {
            'clean_data': true
          }
        }

        const response = await Api.get('whitelist/list', config)
        const updatedArr = response.data
        .map((data: any) => ({ value: data.gamertag, label: data.gamertag }))
        .filter((data: any) => data.value !== user.gamertag)

        setGamertagList(updatedArr)
        setLoadListGamertag("Selecione a Gamertag")
      } catch (err) {
        toast.error(
          <div>OPS! Não foi possível recuperar as Gamertags.</div>
          , {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    }

    if(feedData.complete || reloadScreen) {
      getGamertags()
    }
  }, [isLoading, reloadScreen])

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  }

  const toggleItemDetails = (itemId: string) => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      [itemId]: !prevDetails[itemId],
    }));
  };

const ListFeed: React.FC<{ item: any; showDetails: boolean; toggleDetails: () => void }> = ({ 
    item, 
    showDetails, 
    toggleDetails 
  }) => {
    return (
      <div className='feed-container' onClick={isMobile || (!isMobile && feedData.complete) ? toggleDetails : undefined}>
        <div className="infos">
          <div className="box">{item.date}</div>
          <div className="box"><i className="lni lni-circle-plus"></i></div>
        </div>
        <div className="kill">
          <div className="box killer">
            <span>Assassino:</span>
            <strong>{item.killer}</strong>
          </div>
          <div className="box target">
            <i className="lni lni-target-customer"></i>
          </div>
          <div className="box victim">
            <span>Vítima:</span>
            <strong>{item.victim}</strong>
          </div>
        </div>

        {feedData.complete ? (
          <div className="more-info" style={{ display: showDetails ? "block" : "none" }}>
            <div className="header">
              <i className="lni lni-circle-minus"></i>
            </div>
            <div className="content">
              <ul className="players">
                <li>Assassino: <strong>{item.killer}</strong></li>
                <li>Vítima: <strong>{item.victim}</strong></li>
              </ul>
              <ul className="status">
                <li>Usou: <strong>{item.weapon}</strong></li>
                <li className="coordinates">Coordenadas: <strong><a href={`https://dayz.xam.nu/#location=${item.x};${item.y};7`} target="_blank" rel="noreferrer">[{item.x} / {item.y}]</a></strong></li>
                <li>Distância: <strong>{item.distance}</strong> metro(s)</li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="more-info" style={{ display: showDetails ? "block" : "none" }}>
            <div className="header">
              <i className="lni lni-circle-minus"></i>
            </div>
            <div className="content">
              <ul className="players">
                <li>Assassino: <strong>{item.killer}</strong></li>
                <li>Vítima: <strong>{item.victim}</strong></li>
              </ul>
            </div>
          </div>
        )}
      </div>
    )
  }

  const dropDownOptinos = [
    { value: '1hora', label: '1 hora (DRB$ 1,00)' },
    { value: '2horas', label: '2 horas (DRB$ 2,00)' },
    { value: '3horas', label: '3 horas (DRB$ 3,00)' },
    { value: '4horas', label: '4 horas (DRB$ 4,00)' },
    { value: '5horas', label: '5 horas (DRB$ 5,00)' },
    { value: '1dia', label: '1 dia (DRB$ 10,00)' },
    { value: '2dias', label: '2 dias (DRB$ 15,00)' },
    { value: '3dias', label: '3 dias (DRB$ 20,00)' },
    { value: '1semana', label: '1 semana (DRB$ 30,00)' },
    { value: '2semanas', label: '2 semanas (DRB$ 35,00)' },
    { value: '1mes', label: '1 mês (DRB$ 45,00)' },
    { value: '2meses', label: '2 meses (DRB$ 50,00)' },
    { value: '3meses', label: '3 meses (DRB$ 55,00)' },
    { value: 'Temp', label: `Toda temporada ${status.config.seasonNumber} (DRB$ 60,00)` },
  ]
  
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Histórico de Kills</h2>
            <p>Descubra quem está dominando o campo de batalha no servidor com nosso histórico de kills.</p>

            {isLoading ? (
              <div className="spinner page">
                <span className="loader"></span>
              </div>
            ) : (
              <>
                {feedData.complete ?
                  <div className="improve-killfeed-alert">
                    <h5>Escolha uma Gamertag</h5>
                    <p>Explore o <strong>Kill Feed</strong> de uma gamertag específica.</p>
                    <Select
                      className="select-gamertag"
                      placeholder={loadListGamertag}
                      options={gamertagList}
                      noOptionsMessage={() => "Nenhuma Gamertag foi encontrada"}
                      isClearable={true}
                      onChange={(event: any) => {
                        setIsFilter(event?.value ?? '')
                        setFilterLoading(true)
                      }}
                    />
                  </div>
                  :
                  <div className="improve-killfeed-alert">
                    <h5>Aprimore suas vantagens agora!</h5>
                    <p>Descubra todos os detalhes com a versão completa do nosso <strong>Kill Feed</strong> utilizando suas DRB$.</p>
                    <button className='btn btn-primary' onClick={(event) => openModal(event)}>Eu quero</button>
                  </div>
                }
                <div className="feed-list">
                  {filterLoading ? 
                    <ul className='skeleton'>
                      <li className="wow">
                        <div className='line'></div>
                        <ul>
                          <li>
                            <div className='line'></div>
                            <div className='line'></div>
                            <div className='line'></div>
                          </li>
                        </ul>
                      </li>
                      <li className="wow">
                        <div className='line'></div>
                        <ul>
                          <li>
                            <div className='line'></div>
                            <div className='line'></div>
                            <div className='line'></div>
                          </li>
                        </ul>
                      </li>
                      <li className="wow">
                        <div className='line'></div>
                        <ul>
                          <li>
                            <div className='line'></div>
                            <div className='line'></div>
                            <div className='line'></div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    :
                    (
                      Object.keys(feedData.feed).length > 0 ? (
                        <ul>
                          {feedData.feed.map((item: any) => {
                            return (
                              <li key={`item-${item.id}`} className={`wow fadeInUp ${!isMobile && !feedData.complete ? 'not-complete' : ''}`} data-wow-delay="0.5s">
                                <ListFeed  
                                  item={item} 
                                  showDetails={itemDetails[item.id] || false}
                                  toggleDetails={() => toggleItemDetails(item.id)}
                                />
                              </li>
                            )
                          })}
                        </ul>
                      ) : (
                        <NoData 
                          description='não encontramos nenhum registro de Kill Feed'
                        />
                      )
                    )
                  }
                </div>
              </>
            )}
            {Object.keys(feedData.feed).length > 0 ? 
              <div className='pagination'>
                <button 
                  className='btn btn-primary' 
                  onClick={() => {
                    handlePageChange(currentPage - 1)}
                  } 
                  disabled={currentPage === 1}
                >
                  Anterior
                </button>
                <span>Página {currentPage} de {totalPages}</span>
                <button 
                  className='btn btn-primary' 
                  onClick={() => {
                    handlePageChange(currentPage + 1)}
                  } 
                  disabled={currentPage === totalPages}
                >
                  Próxima
                </button>
              </div> 
              : <></>
            }
          </div>
        </div>
      </div>

      <PureModal
        header="Pagamento"
        scrollable={false}
        className={"dialog pix"}
        isOpen={modalIsOpen}
        onClose={() => closeModal()}
      >
        <div className="form-buy-content">
          {isPaymentLoading ?
            <div className='load-cover'>
              <div className="spinner">
                <span className="loader"></span>
              </div>
            </div>
            :
            <></>
          }
          <p><strong>{user.gamertag}</strong>, você terá acesso à arma utilizada, coordenadas precisas, distância do alvo e até mesmo a capacidade de filtrar por sobrevivente.</p><br />

          <form onSubmit={onSubmit}>
            <ul className="error">
              {errors.selectOption && <li><i className="lni lni-warning"></i> O campo Tempo de Kill Feed é obrigatório.</li>}
            </ul>
            
            <SelectSource setSourceSelected={setSourceSelected} sourceSelected={sourceSelected}/>
            
            <Select
              placeholder={'Selecione o tempo de Kill Feed'}
              options={dropDownOptinos}
              {...register('selectOption', { required: true })}
              noOptionsMessage={() => "Nenhum Kill Feed foi encontrado"}
              onChange={(event: any) => {
                setkillFeedTimeValue(event?.value ?? '1hora')
                setValue('selectOption', event?.value ?? '')
              }}
            />
            <input type="submit" value={"Comprar"} className={isLoading ? 'disabled' : ''} />
            {
              isLoading ?
              <div className='load-cover'>
                <div className="spinner">
                  <span className="loader"></span>
                </div>
              </div>
              :
              <></>
            }
          </form>

          <div className="col-lg-3 col-md-5 col-sm-12 offset-lg-9 offset-md-7">
            <Coin />
          </div>
        </div>
      </PureModal>
    </>
  )
}