import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserState } from '../../interfaces'

const initialState: UserState = {
  avatar: '',
  gamertag: '',
  xuid: '',
  email: '',
  appKey: '',
  code: ''
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAvatar(state: UserState, action: PayloadAction<string>) {
      state.avatar = action.payload
    },
    setGamertag(state: UserState, action: PayloadAction<string>) {
      state.gamertag = action.payload
    },
    setXuid(state: UserState, action: PayloadAction<string>) {
      state.xuid = action.payload
    },
    setEmail(state: UserState, action: PayloadAction<string>) {
      state.email = action.payload
    },
    setAppKey(state: UserState, action: PayloadAction<string>) {
      state.appKey = action.payload
    },
    setCode(state: UserState, action: PayloadAction<string>) {
      state.code = action.payload
    },
  },
})

export const {
  setAvatar,
  setGamertag,
  setXuid,
  setEmail,
  setAppKey,
  setCode
} = userSlice.actions

export default userSlice.reducer
