import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const DiscorWidget: React.FC = () => {
  const [presence, setPresence] = useState(0)
  const [discordLink, setDiscordLink] = useState('')
  const [isShowDiscord, setIsShowDiscord] = useState(false)
  const [changePostion, setChangePostion] = useState(false)

  const openDiscord = (isShow: boolean) => {
    setIsShowDiscord(isShow)
  }

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop
      if (scrolled > 50) {
        setChangePostion(true)
      } else if (scrolled <= 50) {
        setChangePostion(false)
      }
    }
  
    window.addEventListener("scroll", toggleVisible)

    const fetchDiscord = fetch('https://discord.com/api/guilds/1123628383012659210/widget.json')
      .then((response) => response.json())
      .then((data) => {
        setDiscordLink(data.instant_invite)
        setPresence(data.presence_count)

        data.members.forEach((m: any) => {
          let ul = "<div class='discord-lists'>";
          ['avatar_url'].forEach((av) => {
            ['username'].forEach((md) => {
              ul += `<div class="member-box">
                    <div class="container-img"><img id="members-img" class="avatar-discord-img" src=${m[av]}></div>
                    <div class="container-username">
                    <div>${m[md]}</div>
                    </div>
                    <div class="discord-status" id="discord-status"></div>
                    </div>`;
            });
          });
          ul += '</div>';
          
          var discordMembers = document.querySelector('#discord-members')
          if(discordMembers){
            discordMembers.innerHTML += ul;
          }

          data.members.forEach((m: any) => {
            ['status'].forEach((st) => {
              if (m[st] === 'online') {
                const statusEl = document.getElementById('members-img');
                statusEl?.classList.add('is-online');
              }
            });
          });
        });
      });

      fetchDiscord.catch((error) => {
        toast.error('Não foi possivel obter informações do Discord', {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark",
          });
      });
  }, []);

  return (
    <div className="discord-widget">
      <div className={`chat-popup ${changePostion ? 'change-position': ''}`} style={{display: isShowDiscord ? 'block' : 'none'}}>
          <div className="card discord">
          <button type="button" className="btn cancel" id="close-popup" onClick={() => openDiscord(false)}>
              Fechar <i className="lni lni-close"></i>
          </button>
          <div className="discord-widget">
              <div className="discord-header">
                <div className="discord-logo-white">
                  <img className="logo-discord" src={'/assets/images/logo/discord-logo.png'} alt="discord" />
                </div>
              </div>
              <div className="discord-body">
                <div className="discord-list">
                  <div className="discord-list-status">
                    <div className="discord-list-label" id="online-users">
                      Online: {presence}
                    </div>
                  </div>
                  <div id="discord-members" />
                </div>
                <div className="title-channel">
                    <h3 className="channel-title">
                      Canais <i className="lni lni-thought"></i>
                    </h3>
                </div>
                {/* <div className="discord-list channels" id="discord-channels">
                    {this.discord &&
                    this.discord.channels.map((chann) => {
                        return (
                        <div className="channel-name">
                            <div className="container-img channels">
                            <i className="lni lni-discord"></i>
                            </div>
                            <div className="channel-name">{chann.name}</div>
                        </div>
                        );
                    })}
                </div> */}

                <div className="discord-list channels">
                  <div className="channel-name">
                    <div className="container-img channels">
                      <i className="lni lni-discord"></i>
                    </div>
                    <div className="channel-name">🎮 │ xbox</div>
                  </div>

                  <div className="channel-name">
                    <div className="container-img channels">
                      <i className="lni lni-discord"></i>
                    </div>
                    <div className="channel-name">📢 │ Anuncios</div>
                  </div>

                  <div className="channel-name">
                    <div className="container-img channels">
                      <i className="lni lni-discord"></i>
                    </div>
                    <div className="channel-name">💡 │ Novidades</div>
                  </div>

                  <div className="channel-name">
                    <div className="container-img channels">
                      <i className="lni lni-discord"></i>
                    </div>
                    <div className="channel-name">🥳 │ Boas Vindas</div>
                  </div>

                  <div className="channel-name">
                    <div className="container-img channels">
                      <i className="lni lni-discord"></i>
                    </div>
                    <div className="channel-name">🔖 │ Inscreva-se</div>
                  </div>

                  <div className="channel-name">
                    <div className="container-img channels">
                      <i className="lni lni-discord"></i>
                    </div>
                    <div className="channel-name">📨 │ Convite</div>
                  </div>
                </div>

                <a className="discord-link" target="_blank" rel="nofollow noreferrer" href={discordLink}>
                  <button className="discord-cta">Junte-se a nós</button>
                </a>
              </div>
          </div>
          </div>
      </div>
      <button className={`open-button ${changePostion ? 'change-position': ''}`} onClick={() => openDiscord(true)}>
        <i className="lni lni-discord"></i>
      </button>
    </div>
  );
};