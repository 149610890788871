import React from "react"
import Countdown from "react-countdown"
import { IconType } from "react-icons"
import { BiRightArrowAlt } from "react-icons/bi"
import { BsCloudMoon, BsMoonStars, BsThermometerHigh, BsThermometerLow, BsThermometerSnow, BsThermometerSun } from 'react-icons/bs'
import { WiDayCloudyGusts, WiDaySleetStorm, WiDaySunny } from "react-icons/wi"
import { useGetStatus } from '../../store/hooks/StatusHook'
import { SkeletonLoading } from "../structure/skeletonLoading"

export const Status: React.FC = () => {
  const serverStatus = useGetStatus()

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if(completed) {
      return (
        <span><i className="lni lni-alarm-clock"></i> <i>Fechamos em: Finalizando o expediente!</i></span>
      )
    } else {
      const zeroHours = `0${hours}`
      const zeroMinutes = `0${minutes}`
      const zeroSeconds = `0${seconds}`
      return (
        <span><i className="lni lni-alarm-clock"></i> Fechamos em: {hours > 9 ? hours : zeroHours}:{minutes > 9 ? minutes : zeroMinutes}:{seconds > 9 ? seconds : zeroSeconds}</span>
      )
    }
  }

  const statusTemplate = (
    season: any,
    nightMessage: string,
    WeatherIcon: IconType,
    TempIcon: IconType,
    nightIcon: JSX.Element | undefined,
  ) => {
    return (
      <div className="season wow fadeInLeft" data-wow-delay=".6s">
        <span className="status">Estação: <strong>{season.title}</strong></span>
        <span className="tip">{season.tip}</span>
        <div className="icon-status">
          <ul>
              <li>
                <i><WeatherIcon className="icon size" style={{marginLeft: season.season === 'summer' || season.season === 'spring' ? '-4px' : '0'}}/></i>
                <span>{season.weather}</span>
              </li>
              <li>
                <i><TempIcon className="icon" style={{marginLeft: season.season === 'fall' || season.season === 'spring' ? '-2px' : '0'}}/></i>
                <span>{season.temp}</span>
              </li>
              <li>
                <i>{nightIcon}</i>
                <span>{nightMessage}</span>
              </li>
          </ul>
        </div>
        <a href="https://forum.deadrisingbrasil.com/d/49-seja-bem-vindo-as-4-estacoes">Entenda sobre as estações <BiRightArrowAlt /></a>
      </div>
    )
  }

  const getNightIcon = (type: string) => {
    if(type === 'light') {
      return <BsMoonStars className="icon size min" />
    }
    
    if(type === 'dark') {
      return <BsCloudMoon className="icon" />
    }
  }

  const getSeasonStatus = () => {
    switch(serverStatus.season.season) {
      case 'fall':
        return statusTemplate(
          serverStatus.season,
          serverStatus.night.message, 
          WiDaySleetStorm,
          BsThermometerLow,
          getNightIcon(serverStatus.night.type) 
        )
      case 'winter': 
        return statusTemplate(
          serverStatus.season,
          serverStatus.night.message, 
          WiDayCloudyGusts,
          BsThermometerSnow,
          getNightIcon(serverStatus.night.type) 
        )
      case 'spring':
        return statusTemplate(
          serverStatus.season,
          serverStatus.night.message, 
          WiDaySunny,
          BsThermometerHigh,
          getNightIcon(serverStatus.night.type) 
        )
      case 'summer':
        return statusTemplate(
          serverStatus.season,
          serverStatus.night.message, 
          WiDaySunny,
          BsThermometerSun,
          getNightIcon(serverStatus.night.type) 
        )
        default: { 
          return (
            <div className="season wow fadeInLeft" data-wow-delay=".6s">
              <SkeletonLoading 
                count={3}
                height={20}
                width={220}
                baseColor="#351641"
                customHighlightBackground="linear-gradient(90deg, rgba(71,51,79,0) 0%, rgba(60,23,73,1) 20%, rgba(60,23,73,1) 80%, rgba(71,51,79,0) 100%)"
                style={{marginBottom: '10px'}}
              />
            </div>
          )
       }
    }
  }

  return (
    <>
      {getSeasonStatus()}
      
      {serverStatus.event && serverStatus.event !== "none" ? 
      <div className="event wow fadeInLeft" data-wow-delay=".8s">
          <span>Evento de hoje: <strong>{serverStatus.event}</strong></span>
      </div> : <></>}

      {serverStatus.trader.active ?
        <div className="trader wow fadeInLeft" data-wow-delay=".8s">
          <strong>Loja de Armas</strong>
          <span><i className="lni lni-shopping-basket"></i> {serverStatus.trader.message}</span>
          <Countdown 
            date={`${serverStatus.trader.time}`}
            autoStart={true}
            renderer={renderer}
          />
        </div> : <></>}

      {serverStatus.mec.active ?
        <div className="trader wow fadeInLeft" data-wow-delay=".8s">
          <strong>Auto Mecânica</strong>
          <span><i className="lni lni-car-alt"></i> {serverStatus.mec.message}</span>
          <Countdown 
            date={`${serverStatus.mec.time}`}
            autoStart={true}
            renderer={renderer}
          />
        </div> : <></>}
    </>
  )
}