import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const SliderSection: React.FC = () => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    pauseOnHover: true,
    autoplaySpeed: 5000,
  };

  return (
    <section id="slider" className="wow fadeInUp slider section">
      <Slider {...settings}>
        <div className="item oil-rig">
          <div className="mask">
            <div className="content">
              <h2>Plataforma de Petróleo</h2>
              <p>Uma <strong>Plaforma de Petróleo</strong> próxima a Rify. Um cartão de acesso é necessário para acessar o cofre.</p>
              <div className="button wow fadeInUp"><a href="https://forum.deadrisingbrasil.com/d/116-plataforma-de-petroelo" className="btn">Veja mais</a></div>
            </div>
          </div>
        </div>
        <div className="item bunker">
          <div className="mask">
            <div className="content">
              <h2>Bunker</h2>
              <p><strong>Bunker</strong> esta localizado em Skylist! É necessário um cartão de acesso.</p>
              <div className="button wow fadeInUp"><a href="https://forum.deadrisingbrasil.com/d/112-o-bunker-de-skylist" className="btn">Veja mais</a></div>
            </div>
          </div>
        </div>
        <div className="item armory-store">
          <div className="mask">
            <div className="content">
              <h2>Loja de Armas</h2>
              <p>A <strong>Loja de Armas</strong> esta localizada em: <strong>Green Moutain, Krasno, Tisy e Zenit!</strong> Está aberta todas quintas-feiras às 20hs. Cuidado, área hostil.</p>
              <div className="button wow fadeInUp"><a href="https://forum.deadrisingbrasil.com/d/113-armory-store" className="btn">Veja mais</a></div>
            </div>
          </div>
        </div>
        <div className="item the-vaults">
          <div className="mask">
            <div className="content">
              <h2>The Vaults</h2>
              <p>Existem 5 cofres espalhados pelo mapa, todas as quartas-feiras um deles será aberto!</p>
              <div className="button wow fadeInUp"><a href="https://forum.deadrisingbrasil.com/d/67-the-vaults" className="btn">Veja mais</a></div>
            </div>
          </div>
        </div>
        <div className="item auto-mec">
          <div className="mask">
            <div className="content">
              <h2>Auto Mecânica</h2>
              <p>A <strong>mecânica</strong> em <strong>Vybor</strong> está aberta todas terça-feiras às 20hs. Cuidado, área hostil.</p>
              <div className="button wow fadeInUp"><a href="https://forum.deadrisingbrasil.com/d/94-auto-mecanica-em-vybor" className="btn">Veja mais</a></div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};
