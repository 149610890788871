import React from "react";
import {
  useGetServer
} from '../../store/hooks/ServerHook';

export const Footer: React.FC = () => {
  const serverData = useGetServer()
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="single-footer f-about">
                <div className="logo">
                  <a href="/index">
                    <img src="/assets/images/logo/white-logo.svg" alt="Dead Rising Brasil" />
                  </a>
                </div>
                <p>Versão: {`${serverData.version}`} - Atualização: {`${serverData.lastUpdate}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
