import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ServerState } from '../../interfaces'

const initialState: ServerState = {
  status: {
    message: '',
    color: '',
  },
  name: '',
  version: '',
  lastUpdate: '',
  playerOnline: 0,
  playerMax: 0
}

const serverSlice = createSlice({
  name: 'server',
  initialState,
  reducers: {
    setMessage(state: ServerState, action: PayloadAction<string>) {
      state.status.message = action.payload
    },
    setColor(state: ServerState, action: PayloadAction<string>) {
      state.status.color = action.payload
    },
    setName(state: ServerState, action: PayloadAction<string>) {
      state.name = action.payload
    },
    setVersion(state: ServerState, action: PayloadAction<string>) {
      state.version = action.payload
    },
    setLastUpdate(state: ServerState, action: PayloadAction<string>) {
      state.lastUpdate = action.payload
    },
    setPlayerOnline(state: ServerState, action: PayloadAction<number>) {
      state.playerOnline = action.payload
    },
    setPlayerMax(state: ServerState, action: PayloadAction<number>) {
      state.playerMax = action.payload
    },
  },
})

export const {
  setMessage,
  setColor,
  setName,
  setVersion,
  setLastUpdate,
  setPlayerOnline,
  setPlayerMax,
} = serverSlice.actions

export default serverSlice.reducer
