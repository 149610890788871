import React, { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import { BiRightArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import api from "../../services/Api";

export const Purge: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [purgeData, setPurgeData] = useState<any>({})

  useEffect(() => {
    const getCoin = async () => {

      try {
        const response = await api.get('purge/status')
        if (Object.keys(response.data).length > 0) {
          setPurgeData(response.data.purge)
          setIsLoading(false)
        }

      } catch (error) {
        setIsLoading(false)
        toast.error('Ops! Não foi possivel obter o status do The Purge.', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    }

    getCoin()
  }, []);

  const rendererTime = ({ hours, minutes, seconds, completed }: any) => {
    if(completed) {
      return (
        <p>O amanhecer se aproxima, marcando o fim da noite de delitos. A próxima noite de crimes será definida em instantes.</p>
      )
    } else {
      const zeroHours = `0${hours}`
      const zeroMinutes = `0${minutes}`
      const zeroSeconds = `0${seconds}`
      return (
        <>
          <p>Sobrevivente, o caótico <strong>The Purge</strong> está em curso, anunciando a noite da anarquia, onde crimes são permitidos. Durante esse período, todas as normas são abolidas.</p>
          <p><i className="lni lni-alarm-clock"></i> A noite de crimes encerra em: <strong>{hours > 9 ? hours : zeroHours}:{minutes > 9 ? minutes : zeroMinutes}:{seconds > 9 ? seconds : zeroSeconds}</strong></p>
        </>
      )
    }
  }

  const rendererDate = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return (
        <div className="wow fadeInUp" data-wow-delay=".6s">
          <h3>THE PURGE</h3>
          <Countdown 
            date={`${purgeData.time}`}
            autoStart={true}
            renderer={rendererTime}
          />
        </div>
      )
    } else {
      return (
        <>
          <h3>THE PURGE</h3>
          <p>Sobrevivente, prepare-se! A próxima noite de crimes inicia em:</p>
          <div className="count-purge">
            <div className="wow fadeInUp" data-wow-delay=".6s">
              <h3 className="counter">
                <span>
                  {days}
                </span>
              </h3>
              <p>Dias</p>
            </div>
          
            <div className="wow fadeInUp" data-wow-delay=".6s">
              <h3 className="counter">
                <span>
                  {hours}
                </span>
              </h3>
              <p>horas</p>
            </div>

            <div className="wow fadeInUp" data-wow-delay=".6s">
              <h3 className="counter">
                <span>
                  {minutes}
                </span>
              </h3>
              <p>Minutos</p>
            </div>

            
            <div className="wow fadeInUp" data-wow-delay=".6s">
              <h3 className="counter">
                <span>
                  {seconds}
                </span>
              </h3>
              <p>Segundos</p>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <div className="purge wow fadeInUp" data-wow-delay=".6s">
      {isLoading ? 
        <div className="spinner">
          <span className="loader"></span>
        </div>
        :
        <>
          <Countdown 
            date={`${purgeData.date}`}
            autoStart={true}
            renderer={rendererDate}
          />
          <a href="https://forum.deadrisingbrasil.com/d/110-the-purge-uma-noite-de-crimes">Veja como funciona <BiRightArrowAlt /></a>
        </>
      }
    </div>
  );
};
