import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Breadcrumb, Dashboard, Footer, Header, ServerData } from '../components'
import { useGetUser } from '../store/hooks/UserHook'

const DashboardPage: React.FC = () => {
  const user = useGetUser()
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  useEffect(() => {
    if(!user.appKey) navigate('/')
  })

  const breadCrumb = [
    { name: "Dashboard", url: "/dashboard" },
  ]

  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''} />
        <div className='dashboard'>
          <Breadcrumb items={breadCrumb} />
          <Dashboard />
        </div>
      <Footer />
    </>
  )
}

export default DashboardPage