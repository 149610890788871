import ProgressBar from '@ramonak/react-progress-bar';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { toast } from 'react-toastify';
import { AlertDialog, NoData } from '..';
import Api from "../../services/Api";
import { useGetUser } from '../../store/hooks/UserHook';
import { SurvivorSearch } from './survivorSearch';

interface SurvivorPageParams {
  xuid: string | undefined
}

export const SurvivorPage: React.FC<SurvivorPageParams> = ({ xuid }) => {
  const navigate = useNavigate()
  const user = useGetUser()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [imageType, setImageType] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingCoverImage, setIsLoadingCoverImage] = useState(false);
  const [isLoadingProfileImage, setIsLoadingProfileImage] = useState(false);
  const [gamePlayData, setGamePlayData] = useState<any>([])
  const [profileData, setProfileData] = useState<any>([])
  const [isEditMode, setIsEditMode] = useState(false)
  const [description, setDescription] = useState("")
  const [savingDescription, setSavingDescription] = useState(false)
  const maxLength = 140;

  useEffect(() => {
    setIsLoading(true)
    if(!user.appKey) setIsEditMode(false)
      
    getData();
  }, [user.appKey, xuid])

  const getData = async () => {
    try {
      const config = {
        headers: {
          xuid: `${xuid}`,
        }
      }

      const getData = await Api.get('survivor/profile', config)
      if (getData.data.success) {
        setGamePlayData(getData.data.info.gameplay)
        setProfileData(getData.data.info.player)
        setDescription(getData.data.info.player.description ?? '')

        if (getData.data.info.player.gamertag === user.gamertag) {
          setIsEditMode(true)
        }

        setIsLoadingCoverImage(false);
        setIsLoadingProfileImage(false);
      } else {
        navigate('/404')
      }

      setIsLoading(false)
    } catch (err) {
      toast.error('Ops! Não foi possivel obter os dados do sobrevivente.', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }
  
  const handleUploadClick = (type: string) => {
    setImageType(type)
    
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    try {
      if (imageType === 'cover_image') {
        setIsLoadingCoverImage(true);
      } else if (imageType === 'profile_image') {
        setIsLoadingProfileImage(true);
      }

      const formData = new FormData();
      formData.append(imageType, selectedFile);

      const config = {
        headers: {
          xuid: `${xuid}`,
          image: imageType,
        }
      }

      const response = await Api.post('survivor/upload/image', formData, config);

      if(response.data.success) {
        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      } else {
        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }) 
      }

      getData();
    } catch (error) {      
      toast.error('Algo deu errado, entre em contato com a administração!', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    } finally {
      if (imageType === 'cover_image') {
        setIsLoadingCoverImage(true);
      } else if (imageType === 'profile_image') {
        setIsLoadingProfileImage(true);
      }
    }
  };

  const handleDeleteClick = async (type: string) => {
    try {
      if (type === 'cover_image') {
        setIsLoadingCoverImage(true);
      } else if (type === 'profile_image') {
        setIsLoadingProfileImage(true);
      }
      
      const config = {
        headers: {
          xuid: `${xuid}`,
          image: type,
        }
      }
      const response = await Api.delete('survivor/upload/delete', config);

      if(response.data.success) {
        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }) 
      } else {
        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }) 
      }

      getData()
    } catch (error) {
      toast.error('Algo deu errado, entre em contato com a administração!', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    } finally {
      if (type === 'cover_image') {
        setIsLoadingCoverImage(true);
      } else if (type === 'profile_image') {
        setIsLoadingProfileImage(true);
      }
    }
  };

  const descriptionFilled = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setDescription(value.slice(0, maxLength)); 
  }

  const saveDescription = async () => {
    setSavingDescription(true);

    try {
      const config = {
        headers: {
          xuid: `${xuid}`,
          description: description,
        }
      }

      const response = await Api.post('survivor/description', {}, config);

      if(response.data.success) {
        toast.success(response.data.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      } else {
        toast.error(response.data.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }) 
      }

      getData();
      setSavingDescription(false);
    } catch (error) {
      setSavingDescription(false);
      toast.error('Algo deu errado, entre em contato com a administração!', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }
  
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {isLoading ? 
            <div className="spinner page">
              <span className="loader"></span>
            </div>
            :
            <>
              {profileData.image ?
                <>
                  <h2>{profileData.gamertag}</h2>
                  <p>Aqui você encontrará informações fascinantes 
                    sobre as proezas e aventuras desse destemido guerreiro da sobrevivência. Explore e descubra os segredos por trás das façanhas deste(a) corajoso(a) aventureiro(a).</p>

                  <SurvivorSearch showText={false} />

                  {gamePlayData.new || Object.keys(gamePlayData).length === 0 ?
                    <div className='alert-warning'>
                      <strong>{profileData.gamertag}</strong> é um(a) novo(a) sobrevivente! Pode ser que as informações ainda não estejam completas, mais detalhes 
                      serão adicionados em breve conforme o(a) sobrevivente progride em sua jornada.
                    </div>
                    :
                    <></>
                  }
                  <Helmet key="profileHelmet">
                    <meta property="og:title" content={`Dead Rising Brasil - ${profileData.gamertag}`} />
                    <meta property="og:description" content={profileData.description} />
                    <meta property="og:image" content={`https://deadrisingbrasil.com${profileData.image.cover.url}`} />
                    <meta property="og:url" content={`https://deadrisingbrasil.com/survivor/profile/${xuid}`} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={`Dead Rising Brasil - ${profileData.gamertag}`} />
                    <meta name="twitter:description" content={profileData.description} />
                    <meta name="twitter:image" content={`https://deadrisingbrasil.com${profileData.image.cover.url}`} />
                  </Helmet>
                  
                  <div className="share-page wow fadeInUp">
                    <ul>
                      <li>
                        <FacebookShareButton url={`https://deadrisingbrasil.com/survivor/profile/${xuid}`} >
                          <FacebookIcon size={32} borderRadius={10} />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TelegramShareButton url={`https://deadrisingbrasil.com/survivor/profile/${xuid}`} >
                          <TelegramIcon size={32} borderRadius={10} />
                        </TelegramShareButton>
                      </li>
                      <li>
                        <TwitterShareButton url={`https://deadrisingbrasil.com/survivor/profile/${xuid}`} >
                          <TwitterIcon size={32} borderRadius={10} />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <WhatsappShareButton url={`https://deadrisingbrasil.com/survivor/profile/${xuid}`} >
                          <WhatsappIcon size={32} borderRadius={10} />
                        </WhatsappShareButton>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className='col-lg-5 col-md-12 col-sm-12 wow fadeInUp'>
                      <div className='card'>
                        <div className='cover-image' style={{backgroundImage: `url(${profileData.image.cover.url ?? ''})`}}>
                          {isLoadingCoverImage ?
                            <div className="spinner">
                              <span className="loader"></span>
                            </div>
                          :
                            isEditMode ?
                              profileData.image.cover.isDefault ?
                                <div className={isMobile ? 'upload-image-mobile' : 'upload-image'} onClick={() => handleUploadClick('cover_image')}>
                                  {isMobile ? <div className='upload-label'><i className="lni lni-cloud-upload"></i> Enviar imagem</div> : <i className="lni lni-cloud-upload"></i>}
                                </div>
                                :
                                <AlertDialog 
                                  title='Você tem certeza disso?'
                                  description='Esta ação irá remover a imagem de capa atual do seu perfil e substituir pela imagem padrão. Deseja continuar?'
                                  element={
                                    <div className={isMobile ? 'delete-image-mobile' : 'delete-image'}>
                                      {isMobile ? <div className='upload-label'><i className="lni lni-trash"></i> Deletar imagem</div> : <i className="lni lni-trash"></i>}
                                    </div>
                                  }
                                  action={() => handleDeleteClick('cover_image')}
                                />
                              :
                              <></>
                          }
                        </div>
                        <div className='content'>
                          <div className='profile-image' style={{backgroundImage: `url(${profileData.image.profile.url ?? ''})`}}>
                            {isLoadingProfileImage ?
                              <div className="spinner">
                                <span className="loader"></span>
                              </div>
                              :
                              isEditMode ? 
                                profileData.image.profile.isDefault ?
                                  <div className={isMobile ? 'upload-image-mobile' : 'upload-image'} onClick={() => handleUploadClick('profile_image')}>
                                    {isMobile ? <div className='upload-label'><i className="lni lni-cloud-upload"></i> Enviar imagem</div> : <i className="lni lni-cloud-upload"></i>}
                                  </div>
                                  :
                                  <AlertDialog 
                                    title='Você tem certeza disso?'
                                    description='Esta ação irá remover a imagem de perfil atual e substituir pela imagem padrão. Deseja continuar?'
                                    element={
                                      <div className={isMobile ? 'delete-image-mobile' : 'delete-image'}>
                                        {isMobile ? <div className='upload-label'><i className="lni lni-trash"></i> Deletar imagem</div> : <i className="lni lni-trash"></i>}
                                      </div>
                                    }
                                    action={() => handleDeleteClick('profile_image')}
                                  />
                                :
                                <></>
                            }
                          </div>
                          <div className='status'>
                            <ul>
                              <li>
                                {gamePlayData.is_online ? 
                                  <><div className='player-status' style={{backgroundColor: '#00A63A', boxShadow: '#00A63A'}}></div>Online</>
                                  : 
                                  <><div className='player-status' style={{backgroundColor: '#A60000', boxShadow: '#A60000'}}></div>Offline</>
                                }
                              </li>
                              <li>Visto pela última vez em: <strong>{gamePlayData.last_seen}</strong></li>
                            </ul>
                          </div>
                          <div className='infos'>
                            <h3>{profileData.gamertag} </h3>
                              {gamePlayData.new || Object.keys(gamePlayData).length === 0 || gamePlayData.playtime <= 30?
                                <div className='trust-level'>
                                  Nível de confiabilidade: <strong>Não calculado</strong>
                                  <ProgressBar
                                    height="10px"
                                    isLabelVisible={false}
                                    completed={0}
                                    labelColor="#ffffff"
                                    maxCompleted={14}
                                    animateOnRender
                                  />
                                </div>
                                :
                                <div className='trust-level'>
                                  Nível de confiabilidade: <strong>{profileData.trustLevel.label}</strong>
                                  <ProgressBar
                                    height="10px"
                                    isLabelVisible={false}
                                    completed={profileData.trustLevel.level}
                                    bgColor={profileData.trustLevel.color}
                                    labelColor="#ffffff"
                                    maxCompleted={14}
                                    animateOnRender
                                  />
                                </div>
                              }
                              {isEditMode ? 
                                <>
                                  <strong>Sobre o sobrevivente:</strong>
                                  <div className='count-character'>Caracteres restantes: {maxLength - description.length}</div>
                                  <textarea
                                    value={description}
                                    onChange={descriptionFilled}
                                    placeholder={''}
                                    className='description-input'
                                  />
                                  {savingDescription ?
                                      <button className="btn-save-description" disabled>
                                        <span className="loader"></span>
                                      </button>
                                    :
                                      <button className="btn-save-description" onClick={() => saveDescription()}>Salvar</button>
                                  }
                                </>
                                :
                                  description.length > 0 &&
                                  <>
                                    <strong>Sobre o sobrevivente:</strong>
                                    <p>{description}</p>
                                  </>
                              }
                          </div>
                          <div className='footer'>
                            <div className='box'>
                              <span>Kills</span>
                              <strong>{gamePlayData.kills ?? '-'}</strong>
                            </div>
                            <div className='box'>
                              <span>Mortes</span>
                              <strong>{gamePlayData.deaths_by_player ?? '-'}</strong>
                            </div>
                            <div className='box'>
                              <span>K/D</span>
                              <strong>{gamePlayData.kd ?? '-'}</strong>
                            </div>
                          </div>
                        </div>

                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>

                    <div className='col-lg-7 col-md-12 col-sm-12 wow fadeInUp'>
                      <div className="player-status">
                        <div className='block'>
                          <span className="status-title">Tempo de jogo</span>
                          <div className="status-value">{gamePlayData.playtime ?? '-'}</div>
                          <span className="status-label">minuto(s)</span>
                        </div>
                        <div className='block no-label'>
                          <span className="status-title">Connexões</span>
                          <div className="status-value">{gamePlayData.connections ?? '-'}</div>
                        </div>
                        <div className='block no-label'>
                          <span className="status-title">Respawns</span>
                          <div className="status-value">{gamePlayData.respawns ?? '-'}</div>
                        </div>
                        <div className='block no-label'>
                          <span className="status-title">Inconsciente</span>
                          <div className="status-value">{gamePlayData.unconscious ?? '-'}</div>
                        </div>
                        <div className='block no-label'>
                          <span className="status-title">Construção</span>
                          <div className="status-value">{gamePlayData.builds ?? '-'}</div>
                        </div>
                        <div className='block'>
                          <span className="status-title">Ataques</span>
                          <div className="status-value">{gamePlayData.hits_by_zombie ?? '-'}</div>
                          <span className="status-label">hits por zumbi</span>
                        </div>
                        <div className='block no-label'>
                          <span className="status-title">Reparos</span>
                          <div className="status-value">{gamePlayData.repairs ?? '-'}</div>
                        </div>
                        <div className='block no-label'>
                          <span className="status-title">Instalações</span>
                          <div className="status-value">{gamePlayData.placements ?? '-'}</div>
                        </div>
                        <div className='block no-label'>
                          <span className="status-title">Desmontes</span>
                          <div className="status-value">{gamePlayData.dismantles ?? '-'}</div>
                        </div>
                        <div className='block'>
                          <span className="status-title">Mortes</span>
                          <div className="status-value">{gamePlayData.deaths ?? '-'}</div>
                          <span className="status-label">mortes totais</span>
                        </div>
                        <div className='block no-label'>
                          <span className="status-title">Suicídios</span>
                          <div className="status-value">{gamePlayData.deaths_by_suicide ?? '-'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <NoData 
                  showGamerTag={false}
                  description='Perfil que você está tentando acessar ainda não possui informações necessárias'
                />
              }
            </>
          }
        </div>
      </div>
    </div>
  )
}