import React from 'react'

const Error503: React.FC = () => {
  return (
    <div className='wow fadeInUp error-page'>
      <h1>OPS!</h1>
      <p>Estamos enfrentando alguns problemas, por favor tente novamente mais tarde!</p>
      <div className="button wow fadeInUp"><a href="/" className="btn">Ir para o início</a></div>
    </div>
  )
}

export default Error503