import React from 'react';
import { Crown } from '../../icons/crown.svg';
import { Member } from '../../icons/member.svg';
import { Tenant } from '../../icons/tenent.svg';
import { NoData } from '../../structure/noData';

interface FactionPlayersModuleParams {
  data: any[];
  removePlayer?: boolean;
}

export const FactionPlayersModule: React.FC<FactionPlayersModuleParams> = ({ data, removePlayer }) => {

  const renderRoleComponent = (role: string) => {
    switch (role) {
      case 'admin':
        return <Crown />;
      case 'moder':
        return <Tenant />;
      case 'player':
        return <Member />;
      default:
        return <Member />;
    }
  };

  const getLinkClassByRole = (role: string) => {
    switch (role) {
      case 'admin':
        return 'admin';
      case 'moder':
        return 'moder';
      case 'player':
        return 'player';
      default:
        return 'player';
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="header">
          <h4 className="title"><i className="lni lni-users"></i> Integrantes</h4>
        </div>
        <div className='description'>
          <p>Cada cor representa um posto dentro da Facção: <span className='admin'>Líderes</span>, <span className='moder'>Tenentes</span> e <span className='player'>Soldados</span>.</p>
        </div>
        <div className="body">
          {Array.isArray(data) && data.length > 0 && (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Sobrevivente</th>
                    <th style={{textAlign: 'right'}}>Cargo</th>
                    {removePlayer && <th style={{textAlign: 'right'}}>Ações</th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((faction, index) => (
                    <tr key={index}>
                      <td>
                        <img src={faction.profile_image} className='profile-image' alt="Profile" />
                        <a 
                          href={`/survivor/profile/${faction.xuid}`} 
                          title={faction.allied_name}
                          className={getLinkClassByRole(faction.role)}
                        >
                          {faction.gamertag}
                        </a>
                      </td>
                      <td style={{textAlign: 'right'}}>{renderRoleComponent(faction.role)}</td>
                      {removePlayer && (
                        <td style={{textAlign: 'right'}}>
                          <button className="btn btn-danger">Cancelar Aliança</button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {Array.isArray(data) && data.length === 0 && (
            <NoData 
              showGamerTag={false}
              description='esta facção ainda não possui players'
            />
          )}
        </div>
      </div>
    </div>
  );
};