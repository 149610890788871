import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Api from "../../services/Api";
import { setUser } from "../../store/ducks/DataDuck";
import {
  setAppKey,
  setAvatar,
  setCode,
  setEmail,
  setGamertag,
  setXuid
} from "../../store/ducks/UserDuck";
import { useGetUser } from "../../store/hooks/UserHook";

export const RemoveAccount: React.FC = () => {
  const user = useGetUser()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  
  const handleRemoveAccount = async () => {
    setIsLoading(true)
    try {
      const config = {
        headers: {
          gamertag: `${user.gamertag}`,
        }
      }
      await Api.delete('whitelist/delete', config)

      dispatch(setUser(false))
      dispatch(setAvatar(""));
      dispatch(setGamertag(""))
      dispatch(setXuid(""))
      dispatch(setCode(""))
      dispatch(setEmail(""))
      dispatch(setAppKey(""))

      setIsLoading(false)
      window.location.href = 'https://account.live.com/consent/Edit?client_id=000000004CC51437'
    } catch (err) {
      setIsLoading(false)
      toast.error('Ops! Algo deu errado, tente novamente mais tarde.', {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <section className="section wow fadeInUp">
            <div className='container'>
              <div className="row">
                <div className="section-title">
                  <h3>Account</h3>
                  <h2>Remover Conta</h2>
                  <p>Remova a sua conta Xbox Live associada ao Dead Rising Brasil.</p>
                </div>

                <div className="content">
                    <p>Se você deseja remover a sua conta <strong>Xbox Live</strong> associada ao <strong>Dead Rising Brasil</strong>, é necessário revogar o acesso do nosso aplicativo aos dados da sua conta.</p>
                    <p>Ao realizar essa remoção, sua <strong>GamerTag</strong> será retirada da whitelist, mas o seu progresso será mantido até o final da temporada. Você poderá fazer o login novamente sem problemas, seja com a mesma conta ou até mesmo com uma outra.</p>
                    <p>Para efetuar a remoção, acesse a página de gerenciamento de aplicativos da sua conta Microsoft clicando no botão abaixo e em seguida clique em <strong>"Remover Permissões"</strong> após ser redirecionado.</p>
                    <p>Se não for redirecionado diretamente para a página do <strong>Aplicativo Dead Rising Brasil</strong>, procure o aplicativo <strong>Dead Rising Brasil</strong> na lista de todos os aplicativos permitidos em sua conta.</p>
                    {
                      isLoading ?
                      (
                        <>
                          <div className="button">
                            <button className="btn" disabled>
                              <span className="loader"></span>
                            </button>
                          </div>
                        </>
                      )
                      :
                      (
                      <>
                        <div className="button">
                          <button className="btn" onClick={handleRemoveAccount}>
                            Revogar acesso
                          </button>
                        </div>
                      </>
                      )
                    }
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
