import * as AlertDialog from '@radix-ui/react-alert-dialog';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../services/Api";
import { useGetUser } from "../../store/hooks/UserHook";

export const StartPoint: React.FC = () => {
  const user = useGetUser()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCard, setSelectedCard] = useState(null)
  const [voteSent, setVoteSent] = useState(false)
  const [cards, setCards] = useState([])
  const [voted, setVoted] = useState(false)
  const [votedCard, setVotedCard] = useState(0)
  const [blockButton, setBlockButton] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        if (isLoading) {
          const getOptions = await Api.get('sp/options')
          if (getOptions.data && isLoading) {
            setCards(getOptions.data)
          }

          const config = {
            headers: {
              gamertag: `${user.gamertag}`
            }
          }
          const getVoted = await Api.get('sp/voted', config)
          if (getVoted.data && isLoading) {
            if(getVoted.data[0].gamertag === user.gamertag) {
              setVoted(true)
              setVotedCard(getVoted.data[0].id_sp)
            }
          }

          setIsLoading(false)
        }
      } catch (err) {
        setIsLoading(false)
      }
    }
    getData()
  }, [voted])

  const handleCardClick = (cardIndex: any) => {
    setSelectedCard(cardIndex)
    setVoteSent(false)
  }

  const handleVoteClick = async () => {
    setBlockButton(true)

    if (selectedCard !== null) {
      const config = {
        headers: {
          gamertag: `${user.gamertag}`,
          id: `${selectedCard}`
        }
      }
      await Api.post('sp/vote', {}, config)
      const getOptions = await Api.get('sp/options')
      if (getOptions.data && !voteSent) {
        setCards(getOptions.data)
      }

      toast.success('Voto computado com sucesso!', {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
      
      setVoted(true)
      setVoteSent(true)
    } else {
      setBlockButton(false)
      setVoted(false)
      setVoteSent(false)
      toast.error('Ops! Você deve selecionar uma opção.', {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  if (isLoading) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className='wow fadeInUp'>
              <h2>Faça sua escolha</h2>
              <p>Faça sua voz ecoar no futuro: Eleja o próximo Start Point! O SP com o maior número de votos abrirá as portas para a próxima estação.</p>
              <div className='wow fadeInRight'>
                <div className="spinner">
                  <span className="loader"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Faça sua escolha</h2>
          <p>Faça sua voz ecoar no futuro: Eleja o próximo Start Point! O SP com o maior número de votos abrirá as portas para a próxima estação.</p>
          {voted ?
            <>
              <div className="card-container voted">
                {cards.map((card: any, index) => (
                  <div
                    key={index}
                    className={`card ${(selectedCard || votedCard) === card.id ? 'selected' : ''}`}
                  >
                    <div className="votes">
                      <i className="lni lni-thumbs-up"></i>
                      {card.votes}
                    </div>
                    <div className="cover" style={{backgroundImage: `url(${card.image})`}}></div>
                    <div className="name">{card.name}</div>
                    <p>{card.description}</p>
                    <div className="coordinates">
                      <i className="lni lni-timer"></i>
                      <strong>Tempo:</strong>{card.time} 

                      <i className="lni lni-target-customer"></i>
                      <strong>Mortes:</strong>{card.deaths}
                    </div>
                  </div>
                ))}
              </div>
            </>
            :
            <>
              <div className="card-container">
                {cards.map((card: any, index) => (
                  <div
                    key={index}
                    className={`card ${selectedCard === card.id ? 'selected' : ''}`}
                    onClick={() => handleCardClick(card.id)}
                  >
                    <div className="votes">
                      <i className="lni lni-thumbs-up"></i>
                      {card.votes}
                    </div>
                    <div className="cover" style={{backgroundImage: `url(${card.image})`}}></div>
                    <div className="name">{card.name}</div>
                    <p>{card.description}</p>
                    <div className="coordinates">
                      <i className="lni lni-timer"></i>
                      <strong>Tempo:</strong>{card.time} 
                      
                      <i className="lni lni-target-customer"></i>
                      <strong>Mortes:</strong>{card.deaths}
                    </div>
                    <input
                      type="radio"
                      name="selectedCard"
                      value={card.id}
                      style={{ display: 'none' }}
                      checked={selectedCard === card.id}
                      onChange={() => handleCardClick(card.id)}
                    />
                  </div>
                ))}
              </div>
              {
                blockButton ?
                (
                  <>
                    <div className="button">
                      <button className="btn" disabled>
                        <span className="loader"></span>
                      </button>
                    </div>
                  </>
                )
                :
                (
                <AlertDialog.Root>
                  <AlertDialog.Trigger asChild>
                    <div className="button">
                      <button className="btn">
                        Votar
                      </button>
                    </div>
                  </AlertDialog.Trigger>
                  <AlertDialog.Portal>
                    <AlertDialog.Overlay className="alert-dialog-overlay" />
                    <AlertDialog.Content className="alert-dialog-content">
                      <AlertDialog.Title className="alert-dialog-title">Você tem certeza disso?</AlertDialog.Title>
                      <AlertDialog.Description className="alert-dialog-description">
                        Esta ação irá votar no SP selecionado. Deseja continuar?
                      </AlertDialog.Description>
                      <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
                        <AlertDialog.Cancel asChild>
                          <button className="alert-cancel-button">Cancelar</button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Action asChild>
                          <button className="alert-action-button success" onClick={handleVoteClick}>Sim, votar</button>
                        </AlertDialog.Action>
                      </div>
                    </AlertDialog.Content>
                  </AlertDialog.Portal>
                </AlertDialog.Root>
                )
              }
              
            </>
          }
        </div>
      </div>
    </div>
  )
}