import React, { useEffect, useState } from "react";

export const ScrollButton: React.FC = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop
      if (scrolled > 50) {
        setVisible(true)
      } else if (scrolled <= 50) {
        setVisible(false)
      }
    }
  
    window.addEventListener("scroll", toggleVisible)
  });

  const handleScroll = (event: any) => {
    event.preventDefault();
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  return (
    <a
      onClick={(event) => handleScroll(event)}
      href="/"
      style={{ display: visible ? "flex" : "none" }}
      className="scroll-top"
    >
      <i className="lni lni-chevron-up"></i>
    </a>
  );
};
