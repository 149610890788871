import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactPlayer from 'react-player/lazy'
import { toast } from 'react-toastify'
import Api from '../../services/Api'
import { useGetUser } from '../../store/hooks/UserHook'
import { NoData } from '../structure/noData'

export const Gameclips: React.FC = () => {
  const user = useGetUser()
  const [isLoading, setIsLoading] = useState(true)
  const [imagesData, setImagesData] = useState(Object)
  const [isError, setIsError] = useState(false)

  const buildData = (item: any) => ({
    urlDownload: item.contentLocators[0].uri,
    likes: item.likeCount,
  })
  
  useEffect(() => {
    const getData = async () => {
      try {
        const config = {
          headers: {
            token: user.appKey
          }
        }
        const response = await Api.get('xbox-live/gameclips', config)
        if(Object.keys(imagesData).length === 0 && isLoading) {
          const responseFormated = response.data.values.filter((item: { titleId: number }) => {
            return item.titleId === 777107394
          })
          .map((itemFiltered: any) => {
            return {
              ...buildData(itemFiltered)
            }
          })
          setIsLoading(false)
          setImagesData([...responseFormated])
        }
      } catch (err) {
        setIsLoading(false)
        setIsError(true)
      }
    }
    getData()
  }, [imagesData, isError, isLoading, user.appKey])

  if (isLoading) {
    return (
      <div className='wow fadeInUp'>
        <section className="section">
          <div className='container'>
            <div className="row">
              <h2>Gameclips</h2>
              <p>Faça o download de seus gameclips ou use-os diretamente em nossa comunidade.</p>
              <div className='wow fadeInRight'>
                <div className="spinner">
                  <span className="loader"></span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  if(isError) {
    return (
      <div className='wow fadeInUp'>
        <section className="section">
          <div className='container'>
            <div className="row">
              <h2>Gameclips</h2>
              <NoData 
                description='tivemos problemas na hora de obter seus gameclips, tente novamente mais tarde'
              />
            </div>
          </div>
        </section>
      </div>
    )
  }

  return (
    <section className="section">
      <div className='container'>
        <div className="row">
          <h2>Gameclips</h2>
          <p>Faça o download de seus gameclips ou use-os diretamente em nossa comunidade.</p>
          {Object.keys(imagesData).length > 0 ?
            imagesData.map((item: any, index: any) => {
              return (
                <div className="col-lg-4 col-md-6 col-12" key={`col-${index}`}>
                  <div className='card wow fadeInUp' key={`card-${index}`}>
                    <div className='likes'>
                      <i className="lni lni-heart-filled"></i>
                      {item.likes} Likes
                    </div>
                    <ReactPlayer 
                      url={item.urlDownload}
                      controls={true}
                      width={'100%'}
                      height={'auto'}
                    />
                    <div className="content">
                      <div className="button">
                        <a className='btn' href={item.urlDownload} target="_blank" rel="noopener noreferrer">Download</a>
                      </div>
                      <div className="button">
                        <CopyToClipboard 
                          text={`[embed-video id="video-${index}" url="${item.urlDownload}" type="normal" live="false" qualities=""]`} 
                          onCopy={() => {
                            toast.success(
                              <div>
                                O <strong>embed-video code</strong> do gameclip foi copiado com sucesso! <br /><br />
                                Você já esta pronto para usar o gameclip no fórum.
                              </div>
                              , {
                              position: "top-left",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "dark",
                            })
                          }}
                        >
                          <a 
                            className='btn' 
                            href="/"
                            onClick={(event) => event.preventDefault()}
                          >Copiar Código</a>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          : 
            <NoData 
              description='nenhum gameclip encontrado. Lembrando que apenas gameclips do DayZ serão listados'
            />
          }
        </div>
      </div>
    </section>
  )
}