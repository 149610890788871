import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion'
import CopyToClipboard from 'react-copy-to-clipboard'
import Countdown from 'react-countdown'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Coin, NoData } from '../'
import Api from '../../services/Api'
import { useGetUser } from '../../store/hooks/UserHook'

export const InvoicesPage: React.FC = () => {
  const user = useGetUser()
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get('invoiceId');
  const [isLoading, setIsLoading] = useState(true)
  const [reloadCoins, setReloadCoins] = useState(false)
  const [isConnected, setIsConnected] = useState(true)
  const [invoices, setInvoices] = useState<any>({
    created: [],
    pending: [],
    completed: [],
    cancelled: []
  })

  const invoiceRefs = useRef<any>({})

  const getInvoices = async () => {
    try {
      const config = {
        headers: {
          gamertag: `${user.gamertag}`,
        }
      }

      const response = await Api.get('invoices/list', config)
      const { success, message, invoices } = response.data
      if (success) {
        setInvoices(invoices)
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      toast.error('Algo deu errado, entre em contato com a administração!', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }

  useLayoutEffect(() => {
    getInvoices()
  }, [])

  useEffect(() => {
    if (!isLoading && invoiceId && invoiceRefs.current[invoiceId]) {
      invoiceRefs.current[invoiceId].querySelector('.accordion__button')?.click()
      setTimeout(() => {
        invoiceRefs.current[invoiceId].scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 100);
    }
  }, [isLoading, invoiceId])

  useEffect(() => {
    const ws = new WebSocket('wss://deadrisingbrasil.com/ws/');
    ws.onmessage = async function (event) {
      const data = JSON.parse(event.data);
      toast.success(<><strong>#{data.orderId}</strong>: {data.message}</>, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      
      await getInvoices()
      setReloadCoins(!reloadCoins)
    }

    ws.onopen = function () {
      setIsConnected(true)
    }

    ws.onclose = function () {
      setIsConnected(false)
    }
  }, [])

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if(completed) {
      return (
        <span><i className="lni lni-alarm-clock"></i> A fatura está <strong>cancelada</strong>.</span>
      )
    } else {
      const zeroHours = `0${hours}`
      const zeroMinutes = `0${minutes}`
      const zeroSeconds = `0${seconds}`
      return (
        <span><i className="lni lni-alarm-clock"></i> Pagamento expira em: <strong>{hours > 9 ? hours : zeroHours}:{minutes > 9 ? minutes : zeroMinutes}:{seconds > 9 ? seconds : zeroSeconds}</strong></span>
      )
    }
  }

  const renderInvoicesContent = (invoice: any) => {
    if (invoice.status === 'created') {
      return (
        <p>Seu pedido foi recebido com sucesso e a fatura <strong>#{invoice.orderId}</strong> já está em processamento! 
        Em breve, ela estará disponível como <strong>"pendente"</strong>, com todas as informações necessárias para realizar o pagamento de forma fácil e rápida.</p> 
      )
    }

    if (invoice.status === 'cancelled') {
      return (
        <div className='detail'>
          <div className='left'>
            <ul>
              <li>ID: <strong>{invoice.orderId}</strong></li>
              <li>Total de moedas: <strong>DRB${invoice.coin}</strong></li>
              <li>Data: <strong>{invoice.date}</strong></li>
              <li>Valor: <strong>{invoice.total}</strong></li>
            </ul>
          </div>
          <div className='right'>
            <p>A fatura <strong>#{invoice.orderId}</strong> foi cancelada! 
            Se você precisar de mais informações, entre em contato com a administração.</p>
          </div>
        </div>
      )
    }

    if (invoice.status === 'completed') {
      return (
        <div className='detail'>
          <div className='left'>
            <ul>
              <li>ID: <strong>{invoice.orderId}</strong></li>
              <li>Total de moedas: <strong>DRB${invoice.coin}</strong></li>
              <li>Data: <strong>{invoice.date}</strong></li>
              <li>Valor: <strong>{invoice.total}</strong></li>
            </ul>
          </div>
          <div className='right'>
            <p>A fatura <strong>#{invoice.orderId}</strong> foi concluída com sucesso! 
            As <strong>DRB$</strong> foram depositadas na sua conta bancária.</p>
          </div>
        </div>
      )
    }

    if (invoice.status === 'pending') {
      return (
        <div className='detail'>
          <div className='left'>
            <ul>
              <li>ID: <strong>{invoice.orderId}</strong></li>
              <li>Total de moedas: <strong>DRB${invoice.coin}</strong></li>
              <li>Data: <strong>{invoice.date}</strong></li>
              <li>Valor: <strong>{invoice.total}</strong></li>
            </ul>
          </div>
          <div className='right'>
            <p>A sua fatura <strong>#{invoice.orderId}</strong> está pronta e aguardando o pagamento! 
            Para facilitar, você pode escanear o QR Code abaixo:</p>
            <div className='timer'>
              <Countdown 
                date={`${invoice.qr_code_lifetime}`}
                autoStart={true}
                renderer={renderer}
              />
            </div>
            <div className='qr-code-image'>
              <img src={`data:image/png;base64,${invoice.qr_code_base64}`} alt={invoice.orderId} />
            </div>
            <p>ou, se preferir, copiar o código e realizar o pagamento manualmente.</p>
            <div className="qr-code">
              {invoice.qr_code}
              <CopyToClipboard 
                text={`${invoice.qr_code}`} 
                onCopy={() => {
                  toast.success(
                    <>O código PIX foi copiado com sucesso!</>
                    , {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  })
                }}
              >
                <a 
                  className='btn' 
                  href="/"
                  onClick={(event) => event.preventDefault()}
                ><i className="lni lni-files"></i></a>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderInvoices = (title: string, description: string, invoices: any[]) => {
    return (
      <div className="card">
        <div className='card-title'><i className="lni lni-coin"></i> {title}</div>
        <p>{description}</p>
        <hr />
        <ul>
          {invoices.map((invoice, index) => (
            <Accordion allowZeroExpanded className='accordion' key={index}>
              <div ref={(el) => invoiceRefs.current[invoice.orderId] = el} className='teste'>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className={`accordion__button ${invoice.status}`}>
                      <strong><i className="lni lni-empty-file"></i> #{invoice.orderId}</strong>
                      <AccordionItemState>
                        {({ expanded }) => (expanded ? <i className="lni lni-minus"></i> : <i className="lni lni-plus"></i>)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {renderInvoicesContent(invoice)}
                  </AccordionItemPanel>
                  <div className='clear'></div>
                </AccordionItem>
              </div>
            </Accordion>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Faturas</h2>
          <p>Acompanhe aqui todas as suas faturas pagas, 
            canceladas e as que ainda estão aguardando pagamento. Quando o pagamento for concluído, 
            suas moedas serão automaticamente depositadas na sua conta bancária.</p>

            {
              isConnected ?
                <div className='alert-success'>
                  <i className="lni lni-link"></i>
                  <strong>{user.gamertag}</strong>, você foi conectado com sucesso ao túnel. Enquanto estiver conectado, receberá notificações em tempo real sobre o status de suas faturas.
                </div>
                :
                <div className='alert-danger'>
                  <i className="lni lni-unlink"></i>
                  <strong>{user.gamertag}</strong>, sua conexão com o túnel foi encerrada. As notificações em tempo real não serão mais enviadas, mas o processamento das suas faturas continuará normalmente. Para reconectar, atualize a página.
                </div>
            }

            <div className="col-lg-3 col-md-5 col-sm-12 offset-lg-9 offset-md-7">
              <Coin reload={reloadCoins} />
            </div>

            {isLoading ? 
              <div className="spinner page">
                <span className="loader"></span>
              </div>
              :
              <div>
                {invoices.created?.length > 0 && renderInvoices("Criadas", "As faturas estão em processamento e os dados para pagamento serão disponibilizados assim que o processamento for concluído.", invoices.created)}
                {invoices.cancelled?.length > 0 && renderInvoices("Canceladas", "As faturas canceladas não estão mais disponíveis para pagamento, pois o prazo para pagamento expirou. Caso precise de mais informações ou assistência, estamos à disposição.", invoices.cancelled)}
                {invoices.completed?.length > 0 && renderInvoices("Concluídas", "As faturas concluídas tiveram o pagamento realizado com sucesso, e as DRB$ foram creditados na sua conta bancária.", invoices.completed)}
                {invoices.pending?.length > 0 && renderInvoices("Pendentes", "Aqui estão suas faturas em aberto, aguardando pagamento. Nos detalhes, você encontrará o QR Code ou o código para realizar o pagamento diretamente no aplicativo do seu banco.", invoices.pending)}
              
                {(invoices.created?.length === 0 && invoices.cancelled?.length === 0 && invoices.completed?.length === 0 && invoices.pending?.length === 0) ? 
                  <NoData 
                    description='você ainda não possuí nenhuma fatura'
                  />
                  : 
                  null
                }
              </div>
            }
        </div>
      </div>
    </div>
  )
}