import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Login } from '../sections/login';

export interface HeaderProps {
  headerStyle: boolean,
  menuActive: string
}

export const Header: React.FC<HeaderProps> = ({headerStyle, menuActive}) => {
  const location = useLocation()
  const [style, setStyle] = useState(headerStyle)
  const [activeMenu, setActiveMenu] = useState(menuActive)
  const [menuState, setMenuState] = useState({
    menuMobile: { style: false, shown: false },
    userMenuMobile: { style: false, shown: false },
  });

  const toggleMenu = (menu: 'menuMobile' | 'userMenuMobile') => {
    setMenuState(prevState => ({
      menuMobile: {
        style: menu === 'menuMobile' ? !prevState.menuMobile.style : false,
        shown: menu === 'menuMobile' ? !prevState.menuMobile.shown : false,
      },
      userMenuMobile: {
        style: menu === 'userMenuMobile' ? !prevState.userMenuMobile.style : false,
        shown: menu === 'userMenuMobile' ? !prevState.userMenuMobile.shown : false,
      }
    }));
  };
  
  const setLinkActive = (section: string) => {
    setActiveMenu(section);
    setMenuState({
      menuMobile: { style: false, shown: false },
      userMenuMobile: { style: false, shown: false },
    });
  };

  useEffect(() => {
    const onScroll = () => {
      let sections = document.querySelectorAll('.page-scroll')
      let scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  
      for (let i = 0; i < sections.length; i++) {
        let currLink = sections[i]
        let val = currLink.getAttribute('data-ref')
        let refElement = document.querySelector(`#${val}`) as HTMLElement | null
        let scrollTopMinus = scrollPos + 110
        if(refElement !== null) {
          if (refElement?.offsetTop <= scrollTopMinus && (refElement?.offsetTop + refElement?.offsetHeight > scrollTopMinus)) {
            let pageScroll = document.querySelector('.page-scroll') as HTMLElement | null
            pageScroll?.classList.remove('active')
            currLink.classList.add('active')
          } else {
            currLink.classList.remove('active')
          }
        }
      }
    }

    const setHeaderStyle = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 20) {
        setStyle(true);
      } else if (scrolled <= 50) {
        setStyle(false);
      }
    };

    if(location.pathname === '/' || location.pathname === '/index') {
      window.addEventListener("scroll", setHeaderStyle)
    }
    window.document.addEventListener('scroll', onScroll)

    if(location.hash) {
      setTimeout(() => {
        setLinkActive(location.hash.replace('#', ''))
        const element = document.getElementById(location.hash.replace('#', ''))
        if(element) {
          element.scrollIntoView()
        }
      }, 500)
    }
  }, [location.hash, location.pathname])

  return (
    <>
      <header className={`header navbar-area ${style ? "sticky" : ""}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="nav-inner">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="/">
                    <img
                      src={`${
                        style
                          ? "/assets/images/logo/logo.svg"
                          : "/assets/images/logo/white-logo.svg"
                      }`}
                      alt="Logo"
                    />
                  </a>
                  <div className="navbar-buttons-container">
                    <button
                      onClick={() => toggleMenu('userMenuMobile')}
                      className={`navbar-toggler mobile-menu-btn user ${
                        menuState.userMenuMobile.style ? "active" : ""
                      }`}
                      type="button"
                    >
                      {menuState.userMenuMobile.style ? <i className="lni lni-close"></i> : <i className="lni lni-user"></i>}
                    </button>
                    <button
                      onClick={() => toggleMenu('menuMobile')}
                      className={`navbar-toggler mobile-menu-btn ${
                        menuState.menuMobile.style ? "active" : ""
                      }`}
                      type="button"
                    >
                      {menuState.menuMobile.style ? <i className="lni lni-close"></i> : <i className="lni lni-menu"></i>}
                    </button>
                  </div>
                  <div
                    className={`collapse navbar-collapse sub-menu-bar ${menuState.menuMobile.shown ? 'show': ''}`}
                    >
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a
                          data-ref="home"
                          href={`${location.pathname !== '/' ? '/#home' : '#home'}`}
                          onClick={() => { setLinkActive('home')  }}
                          className={`page-scroll ${activeMenu === 'home' ? 'active' : ''}`}
                          aria-label="Toggle navigation"
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          data-ref="servidor"
                          href={`${location.pathname !== '/' ? '/#servidor' : '#servidor'}`}
                          onClick={() => { setLinkActive('servidor') }}
                          className={`page-scroll ${activeMenu === 'servidor' ? 'active' : ''}`}
                          aria-label="Toggle navigation"
                        >
                          Servidor
                        </a>
                        <ul className="sub-menu">
                          <li className="nav-item">
                            <a
                              href='/survivor'
                              onClick={() => { setLinkActive('survivor') }}
                              className={`${location.pathname.indexOf('survivor') >= 0 ? 'active' : ''}`}
                            >
                              Sobrevivente
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <a
                          data-ref="temporada"
                          href={`${location.pathname !== '/' ? '/#temporada' : '#temporada'}`}
                          onClick={() => { setLinkActive('temporada') }}
                          className={`page-scroll ${activeMenu === 'temporada' ? 'active' : ''}`}
                          aria-label="Toggle navigation"
                        >
                          Temporada
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          data-ref="faq"
                          href={`${location.pathname !== '/' ? '/#faq' : '#faq'}`}
                          onClick={() => { setLinkActive('faq') }}
                          className={`page-scroll ${activeMenu === 'faq' ? 'active' : ''}`}
                          aria-label="Toggle navigation"
                        >
                          FAQ
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          data-ref="comunidade"
                          href={`${location.pathname !== '/' ? '/#comunidade' : '#comunidade'}`}
                          onClick={() => { setLinkActive('comunidade') }}
                          className={`page-scroll ${activeMenu === 'comunidade' ? 'active' : ''}`}
                          aria-label="Toggle navigation"
                        >
                          Comunidade
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href='/ranking'
                          onClick={() => { setLinkActive('comunidade') }}
                          className={`${location.pathname.indexOf('ranking') >= 0 ? 'active' : ''}`}
                        >
                          Ranking
                        </a>
                        <ul className="sub-menu">
                          <li className="nav-item">
                            <a 
                              href="/ranking/playtime"
                              className={`${location.pathname.indexOf('playtime') >= 0 ? 'active' : ''}`}
                            >
                              Tempo de Jogo
                            </a>
                          </li>
                          <li className="nav-item">
                            <a 
                              href="/ranking/kill"
                              className={`${location.pathname.indexOf('kill') >= 0 ? 'active' : ''}`}
                            >
                              Kill
                            </a>
                          </li>
                          <li className="nav-item">
                            <a 
                              href="/ranking/builds"
                              className={`${location.pathname.indexOf('builds') >= 0 ? 'active' : ''}`}
                            >
                              Builds
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={`collapse navbar-collapse sub-menu-bar ${menuState.userMenuMobile.shown ? 'show': ''}`}
                  >
                    <div className="mobile-login">
                      <Login buttonStatus={() => setMenuState({...menuState, menuMobile: { style: false, shown: false }})} menuStatus={() => setMenuState({...menuState, menuMobile: { style: false, shown: false }})} />
                    </div>
                  </div>
                  <Login buttonStatus={() => setMenuState({...menuState, menuMobile: { style: false, shown: false }})} menuStatus={() => setMenuState({...menuState, menuMobile: { style: false, shown: false }})} />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
