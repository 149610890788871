import Table from 'rc-table';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../../services/Api';
import { NoData } from '../structure/noData';

export const RankingKills: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [rankingData, setRankingData] = useState(Object)
  const navigate = useNavigate();

  const goToProfile = (xuid: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    navigate(`/survivor/profile/${xuid}`)
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'Sobrevivente',
      dataIndex: 'gamertag',
      key: 'gamertag',
      render: (text: string, record: any) => (
        <div className="profile">
          <div className='image'>
            <div className="status">
              {record.is_online ? 
                <div className='dot' style={{backgroundColor: '#00A63A', boxShadow: '#00A63A'}}></div> : 
                <div className='dot' style={{backgroundColor: '#A60000', boxShadow: '#A60000'}}></div>
              }
            </div>
            <img src={record.profile_image} alt="Profile" />
          </div>
          <a onClick={(event) => goToProfile(record.xuid, event)} href="/#" style={{color: '#7b4397'}}>
            {record.gamertag}
          </a>
        </div>
      ),
      width: 180,
    },
    {
      title: 'Kills',
      dataIndex: 'kills',
      key: 'kills',
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await Api.get('ranking/kills')
        if(Object.keys(rankingData).length === 0 && isLoading) {
          setIsLoading(false)
          setRankingData(response.data.ranking)
        }
      } catch (err) {
        setIsLoading(false)
      }
    }
    getData()
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Kills</h2>
          <p>TOP 15 players com maior numero de Kills. <i>O ranking é atualizado de hora em hora</i>.</p>
          {isLoading ?
            <div className='wow fadeInRight'>
              <div className="spinner">
                <span className="loader"></span>
              </div>
            </div>
            :
            <>
              {Object.keys(rankingData).length > 0 ?
                <Table
                  rowKey={data => data.id}
                  columns={columns} 
                  data={rankingData}
                  tableLayout={'fixed'}
                />
              : 
                <NoData 
                  showGamerTag={false}
                  description='O ranking por kill ainda está vazio! Aguarde até a próxima atualização'
                />
              }
            </>
          }
        </div>
      </div>
    </div>
  )
};
