import React, { useState } from "react";
import {
  useGetServer
} from '../../store/hooks/ServerHook';

export const About: React.FC = () => {
  const serverData = useGetServer()
  const [isShowMoreRules, setIsShowMoreRules] = useState(true);
  const [isShowMoreSeason, setIsShowMoreOutro] = useState(true);

  const showMore = (event: any, dataType: string) => {
    const element = document.querySelector<HTMLElement>(`[data-type="${dataType}"]`);
    if (element) {
      const computedStyle = window.getComputedStyle(element);
  
      const isShow = computedStyle.display === 'none' ? 'block' : 'none';
      element.style.display = isShow;
  
      if (dataType === 'rules') {
        setIsShowMoreRules(!isShowMoreRules);
      } else if (dataType === 'season') {
        setIsShowMoreOutro(!isShowMoreSeason);
      }
    }
  }

  return (
    <section id="servidor" className="features section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h3 className="wow zoomIn" data-wow-delay=".2s">
                Servidor
              </h3>
              <h2 className="wow fadeInUp" data-wow-delay=".4s">
                Sobre Dead Rising Brasil
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Dead Rising Brasil é um servidor de DayZ para <strong>Xbox One</strong> e <strong>Xbox Series</strong>, 
                servidor esta hospedado em <strong>São Paulo</strong>, melhorando o PING para nós jogadores Brasileiros. <br />
                Atualmente o servidor tem capacidade para <strong>{`${serverData.playerMax}`}</strong> sobreviventes.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12 box">
            <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
              <i className="lni lni-game icons"></i>
              <h3>Como jogar?</h3>
              <p>Deseja embarcar nessa aventura zumbificada?</p>
              <p>Dirija-se à guia <strong>"Comunidade"</strong> no menu e, sob o filtro <strong>"Região"</strong>, selecione <strong>"Todos"</strong>.</p>
              <p>Agora, é só desbravar a lista de servidores e procurar o exclusivo servidor <strong>{`${serverData.name}`}</strong>.</p>
              <p>Prepare-se para mergulhar de cabeça no mundo de <strong>Dead Rising Brasil</strong>, onde a sobrevivência é fundamental e os mortos-vivos são apenas parte do desafio.</p>
              <p>Prepare-se para uma jornada emocionante com a galera brasileira apaixonada por esse jogo!</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 box">
            <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
              <i className="lni lni-reload icons"></i>
              <h3>O que é Season?</h3>
              <p>A cada temporada de meio ano, uma emocionante jornada se desenrola no mundo implacável do <strong>Dead Rising Brasil</strong>.</p>
              <p>Com a passagem do tempo, surge a inevitável reviravolta: o servidor, um reino forjado por sobreviventes tenazes, é completamente reiniciado a cada nova temporada, o que acarreta no tão esperado <strong>"wipe"</strong>.</p>
              <div data-type="season" style={{display: 'none'}}>
                <p>Nesse momento, destinos se apagam, bases cedem à natureza e histórias de coragem e traição se transformam em lendas passageiras. Personagens, uma vez tão familiarizados com as terras pós-apocalípticas, são rejuvenescidos, enquanto artefatos preciosos e recursos valiosos retornam à obscuridade, aguardando serem redescobertos.</p>
                <p>Assim, a cada <strong>6 meses</strong>, o <strong>Dead Rising Brasil</strong> oferece a todos uma oportunidade renovada de conquistar os ermos desafiadores, em uma dança contínua entre a efemeridade e a eternidade.</p>
              </div>
              <button className="show-more-button-text" onClick={(event) => showMore(event, 'season')}>
                {isShowMoreSeason ? 'Leia mais' : 'Fechar'}
                {isShowMoreSeason ? <i className="lni lni-plus"></i> : <i className="lni lni-minus"></i>}
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 box">
            <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
              <i className="lni lni-protection icons"></i>
              <h3>Regras</h3>
              <p>Adotamos a emocionante abordagem do <strong>RAID Estratégico</strong>. Isso significa que a invasão de bases <strong>só é válida</strong> quando os responsáveis pela base estão <strong>online</strong> no servidor.</p>
              <p>A segurança das bases é nossa principal prioridade, e, para promover um ambiente construtivo, desabilitamos qualquer dano às estruturas. Isso possibilita que você construa e mantenha a tranquilidade mesmo quando estiver desconectado.</p>
              <div data-type="rules" style={{display: 'none'}}>
                <p>No entanto, a ação não cessa aqui. Encorajamos o envolvimento estratégico em conflitos para proteger ou capturar bases, desde que seja feito de maneira inteligente e vigilante durante as ausências dos proprietários.</p>
                <p>No espírito do <strong>RAID Estratégico</strong>, incentivamos os jogadores a analisar e estudar as bases que desejam conquistar. Conhecer os horários em que os donos estão online, entender a rotina e planejar cuidadosamente são elementos-chave para o sucesso nesse tipo de incursão.</p>
                <p>Quando os responsáveis pela base <strong>não estão online</strong>, nossa missão é garantir que sua fortaleza permaneça <strong>impenetrável</strong>.</p>
                <p>No entanto, nosso servidor apresenta um evento específico dedicado às RAID, conhecido como The Purge! Este evento tem uma duração de 4 horas e ocorre de forma imprevisível entre 15 a 25 dias após a conclusão do último evento. Durante essa experiência intensa, os danos à construção são desencadeados, permitindo que as bases sejam alvos de RAID. Prepare-se para a ação!</p>
              </div>
              <button className="show-more-button-text" onClick={(event) => showMore(event, 'rules')}>
                {isShowMoreRules ? 'Leia mais' : 'Fechar'}
                {isShowMoreRules ? <i className="lni lni-plus"></i> : <i className="lni lni-minus"></i>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};









