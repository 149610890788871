export function Crown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" x="0" y="0" viewBox="0 0 512.001 512.001" xmlSpace="preserve" enableBackground="new 0 0 512 512">
      <g>
        <path fill="#ffde66" d="m256.001 357.072-221.571-15L.292 172.871a15 15 0 0 1 23.029-15.423l93.054 62.036L245.394 90.465c5.857-5.858 15.355-5.858 21.213 0l129.019 129.019 93.054-62.036a15 15 0 0 1 23.029 15.423l-34.137 169.201z" opacity="1" data-original="#ffde66"></path>
        <path fill="#ffbd44" d="M505.347 157.466a15.002 15.002 0 0 0-16.667-.018l-93.054 62.036L266.607 90.465a14.944 14.944 0 0 0-10.698-4.389v270.99l.092.006 221.571-15 34.137-169.201a15 15 0 0 0-6.362-15.405z" opacity="1" data-original="#ffbd44"></path>
        <path fill="#ffa400" d="M34.429 410.929c0 8.284 6.716 15 15 15h206.479L346.5 384 256 342.071H34.429z" opacity="1" data-original="#ffa400"></path>
        <path fill="#fd7e3d" d="M255.909 425.929h206.663c8.284 0 15-6.716 15-15v-68.857H255.909z" opacity="1" data-original="#fd7e3d"></path>
      </g>
    </svg>
  )
}