import * as AlertDialogLib from '@radix-ui/react-alert-dialog';
import React from 'react';

interface AlertDialogParams {
  title: string,
  description: string,
  element: JSX.Element,
  action: () => void,
  buttonCancel?: string,
  buttonAction?: string
  type?: 'danger' | 'success' | 'warning'
}

export const AlertDialog: React.FC<AlertDialogParams> = ({ 
  title, 
  description, 
  element, 
  action,
  buttonCancel,
  buttonAction,
  type = 'danger'
}) => {  
  return (
    <AlertDialogLib.Root>
      <AlertDialogLib.Trigger asChild>
        { element }
      </AlertDialogLib.Trigger>
      <AlertDialogLib.Portal>
        <AlertDialogLib.Overlay className="alert-dialog-overlay" />
        <AlertDialogLib.Content className="alert-dialog-content">
          <AlertDialogLib.Title className="alert-dialog-title">{ title }</AlertDialogLib.Title>
          <AlertDialogLib.Description className="alert-dialog-description">
            { description }
          </AlertDialogLib.Description>
          <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
            <AlertDialogLib.Cancel asChild>
              <button className="alert-cancel-button">{buttonCancel ?? 'Cancelar'}</button>
            </AlertDialogLib.Cancel>
            <AlertDialogLib.Action asChild>
              <button className={`alert-action-button ${type}`} onClick={() => action()}>{buttonAction ?? 'Sim, remover'}</button>
            </AlertDialogLib.Action>
          </div>
        </AlertDialogLib.Content>
      </AlertDialogLib.Portal>
    </AlertDialogLib.Root>
  )
}