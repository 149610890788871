import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, Footer, Header, ServerData, SurvivorSearch } from '../../components';

const SurvivorPage: React.FC = () => {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  const breadCrumb = [
    { name: "Sobrevivente", url: "/survivor" }
  ]

  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''}/>
        <div className='survivor-page'>
          <Breadcrumb items={breadCrumb} />
          <SurvivorSearch showText={true}/>
        </div>
      <Footer />
    </>
  )
}

export default SurvivorPage