import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Breadcrumb, Footer, Gameclips, Header, Screenshots, ServerData } from '../../components'
import { useGetUser } from '../../store/hooks/UserHook'

const Dvr: React.FC = () => {
  const user = useGetUser()
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  useEffect(() => {
    if(!user.appKey) navigate('/index')
  })

  const breadCrumb = [
    { name: "Dashboard", url: "/dashboard" },
    { name: "Capturas", url: "/dashboard/capturas" },
  ]

  return (
    <>
      <ServerData />
      <Header headerStyle={true} menuActive={''}/>
        <div className='dvr'>
          <Breadcrumb items={breadCrumb} />
          <Screenshots />
          <Gameclips />
        </div>
      <Footer />
    </>
  )
}

export default Dvr