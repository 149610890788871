import React from "react"
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import App from '../../App'
import Error404 from '../../page/404'
import Error503 from '../../page/503'
import DashboardPage from '../../page/dashboard'
import FactionPage from "../../page/faction/faction"
import FactionProfilePage from "../../page/faction/profile"
import Airdrop from "../../page/features/airdrop"
import BaseAlert from '../../page/features/baseAlert'
import Bounty from "../../page/features/bounty"
import Dvr from "../../page/features/dvr"
import HeatMap from '../../page/features/heatMap'
import Invoices from "../../page/features/invoices"
import KillFeedPage from "../../page/features/killfeed"
import MyBank from "../../page/features/myBank"
import StartPointPage from "../../page/features/startPoint"
import NoInternet from "../../page/no-internet"
import Builds from '../../page/ranking/builds'
import Kill from '../../page/ranking/kill'
import PlayTime from "../../page/ranking/playTime"
import RemoverAccount from "../../page/remove-account"
import SurvivorProfilePage from "../../page/survivor/profile"
import SurvivorPage from "../../page/survivor/survivor"
import UserHandler from "../../providers/UserHandler"

const Router: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return(
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route index element={<App />} />
          <Route path='/login' element={<UserHandler />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/dashboard/capturas" element={<Dvr />} />
          <Route path="/dashboard/invoices" element={<Invoices />} />
          <Route path="/ranking" element={<Navigate to='/ranking/playtime' />} />
          <Route path="/ranking/playtime" element={<PlayTime />} />
          <Route path="/ranking/kill" element={<Kill />} />
          <Route path="/ranking/builds" element={<Builds />} />
          <Route path="/dashboard/base-alert" element={<BaseAlert />} />
          <Route path="/dashboard/heat-map" element={<HeatMap />} />
          <Route path="/dashboard/start-point" element={<StartPointPage />} />
          <Route path="/dashboard/remove-account" element={<RemoverAccount />} />
          <Route path="/dashboard/my-bank" element={<MyBank />} />
          <Route path="/dashboard/bounty" element={<Bounty />} />
          <Route path="/dashboard/airdrop" element={<Airdrop />} />
          <Route path="/dashboard/killfeed" element={<KillFeedPage />} />
          <Route path="/survivor" element={<SurvivorPage />} />
          <Route path="/survivor/profile" element={<Navigate to='/survivor' />} />
          <Route path="/survivor/profile/:xuid" element={<SurvivorProfilePage />} />
          <Route path="/faction" element={<FactionPage />} />
          <Route path="/faction/profile/:id" element={<FactionProfilePage />} />
          <Route path='/503' element={<Error503 />} />
          <Route path='/no-internet' element={<NoInternet />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        {children}
      </BrowserRouter>
    </>
  )
}

export default Router