import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const OfflineNotification: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isOffline, setIsOffline] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleOfflineNotification = () => {
      setIsOffline(true);
      navigate('/no-internet');
    }
    window.addEventListener('offlineNotification', handleOfflineNotification)
    return () => {
      window.removeEventListener('offlineNotification', handleOfflineNotification)
    }
  }, [])

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data?.type === 'REDIRECT_503_PAGE') {
          navigate('/503');
        }
      });
    }
  }, [navigate])

  if (!isOffline) return <>{children}</>
  return <></>
}